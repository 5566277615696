import { useContext, useState } from "react";
import vectorImg from "../Assets/loginVector.png";
import { useForm, FormProvider } from "react-hook-form";
import { chakra } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../Components/Input";
import CustomButton from "../Components/Button";
import { UpdatePasswordAPI } from "../Utils/AUTH";
import HideIcon from "../Assets/Icons/HideIcon";
import PasswordCheck from "../Components/PasswordCheck";
import toast from "react-hot-toast";
import { UserContext } from "../Context/UserContext";

export default function CreatePassword() {
  const navigate = useNavigate();
  const methods = useForm();

  let password: string = methods.watch("newPassword", "");

  const { user, setUser, setVerified } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [okPass, setOkPass] = useState(false);

  const onSubmit = async (data: any) => {
    console.log(data);
    if (data.oldPassword === data.newPassword) {
      methods.setError("newPassword", {
        type: "manual",
        message: "New password must be different.",
      });
    } else if (data.newPassword !== data.confirmPassword) {
      methods.setError("newPassword", {
        type: "manual",
        message: "Passwords do not match.",
      });
      methods.setError("confirmPassword", {
        type: "manual",
        message: "Passwords do not match.",
      });
    } else {
      setLoading(true);
      try {
        const response = await UpdatePasswordAPI({
          ...data,
          confirmPassword: undefined,
        });
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          setUser({
            name: response.user?.firstName + " " + response?.user?.lastName,
            email: response?.user?.email!,
            phone: response?.user?.phoneNo!,
            company:
              response?.user?.companyType ?? response?.user?.authorityLevel!,
            authority: response?.user?.authorityLevel!,
            companyName: response?.user?.companyName!,
            companyId: response?.user?.companyId!,
          });
          localStorage.setItem("userId", response?.user?._id!);
          setVerified(response?.user?.phoneNoVerified!);
          localStorage.setItem(
            "verified",
            response?.user?.phoneNoVerified ? "true" : "false"
          );
          const company =
            response?.user?.companyType ?? response?.user?.authorityLevel!;
          switch (company) {
            case "Owner":
              navigate("/management-companies");
              break;
            case "Management":
              navigate("/users");
              break;
            case "Individual":
              navigate("/users");
              break;
            case "Maintenance":
              navigate("/users");
              break;
          }
        } else {
          setLoading(false);
          toast(response.message);
          console.error(response.message);
          methods.setError("newPassword", {
            type: "manual",
            message: "Please try again.",
          });
          methods.setError("confirmPassword", {
            type: "manual",
            message: "Please try again.",
          });
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message || "Something went wrong.");
        setLoading(false);
        console.error(error);
      }
    }
  };

  return (
    <div className="w-full h-[100vh] font-inter bg-white relative flex flex-row items-center justify-center lg:justify-end lg:pr-[15%]">
      <img
        src={vectorImg}
        className="absolute hidden lg:flex -bottom-0 left-0 w-[60%] xl:w-[50%]"
        alt="Welcome"
      />

      <div className="flex flex-col w-[20rem] sm:w-[24rem] items-center">
        <span className="text-green-primary w-full text-center mb-10 text-[2.6rem] font-bold font-inter">
          Create Password
        </span>
        <FormProvider {...methods}>
          <chakra.form
            className="flex flex-col w-full gap-5"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <CustomInput
              type="password"
              name="oldPassword"
              label="Temporary Password"
              placeholder="Password"
              isRequired={true}
              Icon={HideIcon}
              themeColor="green"
            />

            <CustomInput
              type="password"
              name="newPassword"
              label="New Password"
              placeholder="Password"
              isRequired={true}
              Icon={HideIcon}
              themeColor="green"
            />

            <CustomInput
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Password"
              isRequired={true}
              Icon={HideIcon}
              themeColor="green"
            />

            <PasswordCheck setOkPass={setOkPass} password={password} />

            <CustomButton disabled={!okPass} loading={loading} text="Login" />
          </chakra.form>
        </FormProvider>
      </div>
    </div>
  );
}
