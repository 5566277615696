import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
} from "@chakra-ui/react";
import CustomButton from "../Button";
import { useContext } from "react";
import { UserContext } from "../../Context/UserContext";

interface ModalProps {
  isOpen: any;
  onClose: any;
  title: string;
  text: string;
}

export default function ConfirmModal({
  isOpen,
  onClose,
  title,
  text,
}: ModalProps) {
  const { confirm, setConfirm } = useContext(UserContext);
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent shadow={"none"} bg={"transparent"}>
        <div className="w-72 mx-auto sm:w-full relative bg-white shadow-lg">
          <CloseButton
            position={"absolute"}
            top={5}
            left={5}
            onClick={onClose}
          />
          <ModalHeader
            display="flex"
            justifyContent={"center"}
            className="border-b border-transparent shadow-[0px_0px_2px_0px_#0a285e66]"
          >
            <span className="text-gray-dark text-lg sm:text-2xl text-medium text-center">
              {title}
            </span>
          </ModalHeader>
          <ModalBody py={6}>
            <span className="text-gray-dark text-sm sm:text-base">{text}</span>
          </ModalBody>

          <ModalFooter bg={"#F3F4F4"}>
            <div className="w-full flex justify-center items-center gap-3 sm:gap-5">
              <div
                onClick={() => {
                  setConfirm(false);
                  onClose();
                }}
              >
                <CustomButton
                  w="w-24 sm:w-36"
                  colorScheme="white"
                  loading={false}
                  text="Cancel"
                />
              </div>
              <div
                onClick={() => {
                  setConfirm(true);
                  onClose();
                }}
              >
                <CustomButton
                  w="w-24 sm:w-36"
                  colorScheme="blue"
                  loading={false}
                  text="Continue"
                />
              </div>
            </div>
          </ModalFooter>
        </div>
      </ModalContent>
    </Modal>
  );
}
