import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import AddManagementCompany from "../../Components/Forms/AddManagementCompany";
import IndividualManager from "../../Components/Forms/IndividualManager";
import PrimaryAdmin from "../../Components/Forms/PrimaryAdmin";
import AddUser from "../../Components/Forms/AddUser";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../../Context/UserContext";
import VerifyPhoneModal from "../../Components/Modals/VerifyPhoneModal";

import { chakra, useDisclosure } from "@chakra-ui/react";
import { GetUser } from "../../Utils/AUTH";
import Assignments from "../Assignment/ContractorAssignments";
import Communities from "./Communities";
import Reports from "../ManagementDashboard/Reports";

export default function CommunityUserDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const { addUser, verified, user, setUser, openSidebar } =
    useContext(UserContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (
      !(localStorage.getItem("verified") === "true" ? true : false) &&
      user.phone.length > 0
    )
      onOpen();
    console.log(user);
  }, [verified, user]);

  useEffect(() => {
    if (location.pathname.includes("dashboard")) navigate("/community");
  }, [location.pathname]);

  return (
    <div
      className={`${
        openSidebar ? "lg:pl-[280px] 2xl:pl-[20%]" : "pl-0"
      } w-full h-[100vh] flex flex-row font-inter bg-[#FCFCFC] relative`}
    >
      <Navbar />
      <Sidebar />
      {location.pathname === "/reports" && <Reports />}
      {location.pathname === "/community" && <Communities />}
      {location.pathname.includes("community") &&
        location.pathname.split("/").length === 3 && <Assignments />}
      {/* <VerifyPhoneModal isOpen={isOpen} onClose={onClose} phone={user.phone} /> */}
    </div>
  );
}
