import { useNavigate } from "react-router-dom";

export default function BaseSidebar({
  open,
  setOpenSidebar,
}: {
  open: boolean;
  setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  return (
    <div
      className={`w-[17rem] min-h-[calc(100%-100px)] bg-[#0A285E] z-50 flex md:hidden flex-col absolute p-5 gap-5 transition-all bottom-0 ${
        open ? "left-0" : "-left-[17rem]"
      }`}
    >
      <span
        onClick={() => {
          setOpenSidebar(false);
        }}
        className={`text-white text-xl font-medium cursor-pointer transition-all`}
      >
        Home
      </span>
      <span
        onClick={() => {
          setOpenSidebar(false);
        }}
        className={`text-white text-xl font-medium cursor-pointer transition-all`}
      >
        Queries
      </span>
      <span
        onClick={() => {
          setOpenSidebar(false);
        }}
        className={`text-white text-xl font-medium cursor-pointer transition-all`}
      >
        Pricing
      </span>
      <span
        onClick={() => {
          setOpenSidebar(false);
        }}
        className={`text-white text-xl font-medium cursor-pointer transition-all`}
      >
        About Us
      </span>
      <button
        onClick={() => {
          setOpenSidebar(false);
          navigate("/create-maintenance-contractor");
        }}
        className="px-0.5 py-1.5 bg-[#ACE3DF] text-md text-[#1D1D1D] rounded-lg transition-all"
      >
        Signup as Maintenance Contractor
      </button>
      <button
        onClick={() => {
          setOpenSidebar(false);
          navigate("/login");
        }}
        className="px-7 py-1.5 border border-green-primary bg-transparent hover:bg-green-primary text-white text-xl hover:text-[#0A285E] rounded-lg transition-all"
      >
        Login
      </button>
    </div>
  );
}
