import PdfIcon from "../../Assets/Icons/Pdf";
import { ImageObj } from "./Photos";

export default function Attachments({
  attachments,
}: {
  attachments: ImageObj[];
}) {
  return (
    <div className="w-full flex flex-col gap-2">
      <span className="text-base text-gray-medium">Attachments</span>
      <div className="w-full flex flex-row gap-5">
        {attachments.map((attachment, index) => (
          <div
            key={index}
            className="cursor-pointer"
            onClick={() => {
              window.open(attachment.url, "_blank");
            }}
          >
            <PdfIcon />
          </div>
        ))}
      </div>
    </div>
  );
}
