import React, { useState, useEffect } from "react";
import ArrowDown from "../../../../Assets/Icons/ArrowDown";
import CloseIcon from "../../../../Assets/Icons/CloseIcon";

interface Option {
  label: string;
  value: string | number;
}

interface MultiSelectDropdownProps {
  options: Option[];
  onChange: (values: any[]) => void;
  label?: string;
  defaultValues?: any[];
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  options,
  onChange,
  label,
  defaultValues,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  useEffect(() => {
    // Check if defaultValues are provided and they are valid options
    if (
      defaultValues &&
      defaultValues?.length > 0 &&
      selectedOptions.length === 0
    ) {
      console.log("running 28", defaultValues);
      const defaultOptions = options.filter((option) =>
        defaultValues.includes(option.value)
      );
      setSelectedOptions(defaultOptions);
      console.log("running 32");
      onChange(defaultOptions.map((option) => option.value));
    }
  }, [options]);

  const toggleOption = (option: Option) => {
    const isSelected = selectedOptions.some(
      (selectedOption) => selectedOption.value === option.value
    );
    let updatedOptions;
    if (isSelected) {
      updatedOptions = selectedOptions.filter(
        (selectedOption) => selectedOption.value !== option.value
      );
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
    console.log("running 50");
    onChange(updatedOptions.map((option) => option.value));
  };

  return (
    <div className="relative">
      <div
        className={`bg-transparent border-[1px] border-[#73B8FF33] rounded-md py-2 px-4 cursor-pointer flex justify-between items-center ${
          selectedOptions.length === 0 ? "text-slate-400" : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="whitespace-nowrap truncate flex">
          {selectedOptions.length > 0
            ? selectedOptions.slice(0,2).map((option) => 
            <div className="relative">
              <div className="w-[8rem] truncate bg-gray-100 mx-1 p-1 pr-4 border-[#73B8FF33] border-[1px] rounded-2xl text-sm">{option.label} </div>
        
              <div  onClick={() => toggleOption(option)} className="absolute cursor p-1 top-[6px] border-gray-600 border-[.5px] bg-gray-200 right-[8px] rounded-full"><CloseIcon className="w-2 h-2" /></div>

            </div>
              
            )
            : label}
            {(selectedOptions.length >2)?(<div className="w-[1.8rem] h-[1.6rem] mt-[0.15rem]  ml-[10px] bg-gray-100 border-[#73B8FF33] border-[1px] flex pl-[2px] items-center rounded-full text-sm">+{selectedOptions.length - 2}</div>):('')}
        </span>
        <ArrowDown
          id="multi-dropdown-arrow"
          className="text-gray-dark w-3 h-3"
        />
      </div>
      {isOpen && (
        <div className="absolute z-50 mt-1 w-full bg-white border rounded-md shadow-lg">
          {options.map((option, index) => (
            <div
              key={index}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                selectedOptions.some(
                  (selectedOption) => selectedOption.value === option.value
                )
                  ? "bg-gray-100"
                  : ""
              }`}
              onClick={() => toggleOption(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
