import { useDisclosure } from "@chakra-ui/react";
import PhotosModal from "./PhotosModal";
import { AssignmentData } from "../AssignmentCard";
import { Carousel } from "react-responsive-carousel";

export interface ImageObj {
  filename: string;
  url: string;
  _id: string;
}

export default function Photos({ assignment }: { assignment: AssignmentData }) {
  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();
  console.log("Photos",assignment);
  return (
    <div className="w-full flex flex-col gap-2">
      <span className="text-base text-gray-medium">Photos</span>
      <div className="w-full h-60 bg-[#0000002c] border hover:border-[#55A8FD] cursor-pointer">
        <Carousel autoPlay showThumbs={false} infiniteLoop  >
          {assignment.images?.map((img,i)=>(
             <div
             className="w-full h-60 relative"
             onClick={() => {
               onOpenAddModal();
             }}
           >
             <img
               src={img.url}
               alt=""
               key={i}
               className="object-cover w-full h-full"
             />
           </div>
          ))}
           

        </Carousel>
      </div>
      {/* <div className="w-full flex flex-row mt-2 gap-2">
        {assignment.images?.slice(1, 5).map((img, index) => (
          <div
            onClick={() => {
              onOpenAddModal();
            }}
            className={`flex flex-1 h-20 ${
              index === 4 ? "bg-[#00000080]" : "bg-[#0000002c]"
            } text-white text-lg border hover:border-[#55A8FD] cursor-pointer items-center justify-center`}
          >
            {index === 4 ? (
              "+5"
            ) : (
              <div className="w-full h-20 relative">
                <img
                  src={img.url}
                  alt=""
                  className="object-cover w-full h-full"
                />
              </div>
            )}
          </div>
        ))}
      </div> */}
      <PhotosModal
        assignment={assignment}
        isOpen={isOpenAddModal}
        onClose={onCloseAddModal}
      />
    </div>
  );
}
