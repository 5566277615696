import React, { useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Button,
} from "@chakra-ui/react";
import AddNote from "../Assets/Icons/AddNote";

interface NotesModel {
  setNotes?: any;
  value?: string;
}

export default function NotesBox({ setNotes, value }: NotesModel) {
  return (
    <div className="test">
      <Popover>
        <PopoverTrigger>
          <Button variant={"unstyled"} className="p-0 w-[28px] h-[28px] ">
            <AddNote />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-60 right-8 md:w-72 md:right-16 lg:w-80  lg:right-28 ">
          <PopoverArrow className="left-6 md:left-[57px] lg:left-[104px]" />
          {/* <PopoverCloseButton /> */}
          {/* <PopoverHeader>Confirmation!</PopoverHeader> */}
          <PopoverBody>
            <div className="w-54 md:68  sm:h-[180px] p-4 ">
              <p className="md:text-xl font-medium text-lg">Notes</p>
              <textarea
                placeholder="Your text..."
                className="mt-2 md:w-full h-32 "
                onChange={(e: any) => {
                  setNotes(e.target.value || "");
                }}
                value={value}
              />
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
}
