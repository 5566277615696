import React from "react";
import logo from "../Assets/logo.png";
import footerBg from "../Assets/footerBg.png";
import { HStack, Image, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Linkedin from "../Assets/Icons/Linkedin";
import Facebook from "../Assets/Icons/Facebook";
import Instagram from "../Assets/Icons/Instagram";
import LocationArrow from "../Assets/Icons/LocationArrow";
import MailIcon from "../Assets/Icons/MailIcon";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col overflow-hidden">
      {/* <Image objectFit="contain" alt="footerBg" src={footerBg} /> */}
      <div
        className="w-full h-full top-0 z-100 px-10 lg:px-20 flex flex-col items-start justify-center text-base text-white py-4 sm:py-10 lg:py-20 space-y-4 bg-cover"
        style={{ backgroundImage: `url(${footerBg})` }}
      >
        <img
          onClick={() => {
            navigate("/");
          }}
          src={logo}
          alt="ProCam Tasks"
          className="cursor-pointer w-40 mx-auto sm:mx-0"
        />
        <div className="w-full flex flex-col sm:flex-row items-center sm:items-end justify-between space-y-4 space-x-4 lg-space-x-0">
          <span className="sm:w-60 text-justify">
            HOARulesTemplates.com is a new way to create, update and maintain a
            community associations governing rules and architectural guidelines.
          </span>
          <div className="hidden sm:flex flex-col gap-1 items-start ">
            <Link to="/">Pricing</Link>
            <Link to="/">About us</Link>
            <Link to="/">Contact Us</Link>
          </div>
          <div className="hidden sm:flex flex-col gap-1 items-start ">
            <Link to="/">FAQs</Link>
            <Link to="/">Terms & Conditions</Link>
            <Link to="/">Privacy Policy</Link>
          </div>
          <div className="flex justify-center space-x-6">
            <div className="sm:hidden flex flex-col gap-1 items-center text-center">
              <Link to="/">Pricing</Link>
              <Link to="/">About us</Link>
              <Link to="/">Contact Us</Link>
            </div>
            <div className="sm:hidden flex flex-col gap-1 items-center text-center">
              <Link to="/">FAQs</Link>
              <Link to="/">Terms & Conditions</Link>
              <Link to="/">Privacy Policy</Link>
            </div>
          </div>
          <div className="flex flex-col gap-1 items-start min-w-[220px]">
            <span className="text-2xl font-bold text-right">ProCam Tasks</span>
            <span className="flex flex-row items-baseline gap-2">
              Address line 1<LocationArrow />
            </span>
            <span className="flex flex-row items-baseline gap-2">
              Address line 1<LocationArrow />
            </span>
            <span className="flex flex-row items-center gap-2">
              Email: someth@amth.com
              <MailIcon />
            </span>
          </div>
        </div>
        <div className="w-full flex flex-col sm:flex-row items-center justify-between mt-4 lg:mt-7 space-y-4">
          <div className="flex sm:flex-col gap-1 items-start">
            <span className="sm:text-xl text-center">Call Us Today!</span>
            <span className="sm:text-xl text-center text-[#0A285E]">
              +91 9876543210
            </span>
          </div>
          <VStack gap={3}>
            <span className="bg-[#0A285E] rounded-md text-lg px-5 py-2">
              Follow Us
            </span>
            <HStack gap={4}>
              <Facebook />
              <Instagram />
              <Linkedin />
            </HStack>
          </VStack>
        </div>
      </div>
      <div className="w-full h-16 flex flex-col gap-1 bg-[#0A285E] text-xs text-white items-center justify-center">
        <span>2023 All Rights Reserved</span>
        <span>Terms of Use | Privacy Policy</span>
      </div>
    </div>
  );
}
