import axios from "axios";

const BASE_URL =
  "https://api.procamtasks.com/";
  // "http://localhost:5000";

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});
axiosInstance.interceptors.request.use((req) => {
  return req;
});
axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    console.log(error);
    //const status = error.response ? error.response.status : 500;
    // if (status && status === 500) {
    //   localStorage.clear();
    // }
    return Promise.reject(error);
  }
);
export default axiosInstance;
