export default function FilterIcon({ className }: { className?: string }) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        fill="none"
        viewBox="0 0 18 19"
        className={className ?? "w-6 h-6 text-[#55A8FD]"}
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M16.5 2.75h-15l6 7.095v4.905l3 1.5V9.845l6-7.095z"
        ></path>
      </svg>
    );
  }
  