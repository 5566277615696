export default function MailIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      fill="none"
      viewBox="0 0 14 10"
      className={className ?? "w-6 h-6 text-[#B3B2B9]"}
    >
      <path
        fill="currentColor"
        d="M13.08 3.302a.157.157 0 01.253.122V8.75c0 .69-.56 1.25-1.25 1.25H1.25C.56 10 0 9.44 0 8.75V3.427c0-.13.148-.203.253-.122.583.453 1.356 1.028 4.012 2.958.55.401 1.477 1.245 2.401 1.24.93.007 1.875-.855 2.404-1.24 2.656-1.93 3.427-2.508 4.01-2.96zM6.666 6.667c.605.01 1.474-.76 1.912-1.078 3.455-2.508 3.718-2.727 4.515-3.352.151-.117.24-.3.24-.492V1.25c0-.69-.56-1.25-1.25-1.25H1.25C.56 0 0 .56 0 1.25v.495c0 .192.089.372.24.492.796.622 1.06.844 4.515 3.352.438.317 1.307 1.088 1.911 1.078z"
      ></path>
    </svg>
  );
}
