import CreateAssignmentForm from "./sections/CreateAssignmentForm";

export default function CreateAssignment({ editState = false }) {
  return (
    <div className="flex flex-1 flex-col p-4 md:p-10 items-center w-full gap-6 mt-16 h-[calc(100%-50px)] bg-[#F8FEFC]">
      <div
        className="flex w-full flex-1 flex-col max-h-[calc(100%-0px)] overflow-y-auto p-4 md:p-8 gap-6
         bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]"
      >
        <CreateAssignmentForm editState={editState} />
      </div>
    </div>
  );
}
