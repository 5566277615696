export default function SortIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="19"
      fill="none"
      viewBox="0 0 25 19"
      className={className ?? "w-6 h-6 text-[#55A8FD]"}
    >
      <g fill="currentColor" clipPath="url(#clip0_1359_3669)">
        <path d="M5.47 16.03a.75.75 0 001.06 0l4.773-4.773a.75.75 0 00-1.06-1.06L6 14.439l-4.243-4.242a.75.75 0 10-1.06 1.06L5.47 16.03zM5.25 1.5v14h1.5v-14h-1.5zM19.53.97a.75.75 0 00-1.06 0l-4.773 4.773a.75.75 0 101.06 1.06L19 2.561l4.243 4.242a.75.75 0 001.06-1.06L19.53.97zm.22 14.53v-14h-1.5v14h1.5z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1359_3669">
          <path
            fill="#fff"
            d="M0 0H24V18H0z"
            transform="translate(.5 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
