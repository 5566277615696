import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
} from "@chakra-ui/react";
import CustomButton from "../../../../Components/Button";


interface ModalProps {
  isOpen: any;
  onClose: any;
  title: string;
  text: string;
  onConfirm: any;
}

export default function SubmitModal({
  isOpen,
  onClose,
  title,
  text,
  onConfirm,
}: ModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent shadow={"none"} bg={"transparent"}>
        <div className="w-full relative bg-white ml-[10vw] shadow-lg">
          <CloseButton
            position={"absolute"}
            top={5}
            left={5}
            onClick={onClose}
          />
          <ModalHeader
            display="flex"
            justifyContent={"center"}
            className="border-b border-transparent shadow-[0px_0px_2px_0px_#0a285e66]"
          >
            <span className="text-gray-dark text-2xl text-medium">{title}</span>
          </ModalHeader>
          <ModalBody py={6}>
            <span className="text-gray-dark text-base">{text}</span>
          </ModalBody>

          <ModalFooter bg={"#F3F4F4"}>
            <div className="w-full flex justify-center items-center gap-5">
              <div
                onClick={() => {
                  onClose();
                }}
              >
                <CustomButton
                  w="w-36"
                  colorScheme="white"
                  loading={false}
                  text="Cancel"
                />
              </div>
              <div
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
              >
                <CustomButton
                  w="w-36"
                  colorScheme="blue"
                  loading={false}
                  text="Continue"
                />
              </div>
            </div>
          </ModalFooter>
        </div>
      </ModalContent>
    </Modal>
  );
}
