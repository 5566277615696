import React, { useEffect, useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { chakra, useDisclosure } from "@chakra-ui/react";
import {
  CustomInput,
  DropdownInput,
  MultiSelectInput,
} from "../../../../Components/Input";
import { AssignmentData } from "../../../../Components/AssignmentCard";
import { Loader } from "../../../../Components/Loader";
import {
  GetAssignmentData,
  GetAssignmentFormAPI,
  GetCompanyUserList,
} from "../../../../Utils/GET";
import toast from "react-hot-toast";
import SubmitModal from "../partials/SubmitModal";
import { useLocation, useParams } from "react-router-dom";
import { MultiDropDown } from "../partials/MultiDropDown";

interface AssignmentFormModel {
  createAssignment: (data: any) => void;
  editAssignment: (data: any) => void;
  setImages: React.Dispatch<React.SetStateAction<any[]>>;
  setAttachments: React.Dispatch<React.SetStateAction<any[]>>;
  setLocations: React.Dispatch<React.SetStateAction<any[]>>;
  setNotes: any;
}

const AssignmentForm = ({
  createAssignment,
  editAssignment,
  setImages,
  setAttachments,
  setLocations,
  setNotes,
}: AssignmentFormModel) => {
  const methods = useForm();
  const [assignment, setAssignment] = useState<AssignmentData>();
  const [loading, setLoading] = useState(false);
  const [contractorTypes, setContractorTypes] = useState<string[]>([]);
  const [employeePreferenceData, setEmployeePreferenceData] = useState<any[]>(
    []
  );
  const [maintenanceCompanies, setMaintenanceCompanies] = useState<any[]>([]);
  const [managementUsers, setManagementUsers] = useState<{
    names: [];
    ids: [];
  }>({} as { names: []; ids: [] });
  const [communities, setCommunities] = useState<{
    names: [];
    ids: [];
  }>({} as { names: []; ids: [] });
  const [employees, setEmployees] = useState<{
    names: string[];
    ids: string[];
  }>({} as { names: string[]; ids: string[] });
  const [isContractorLinked, setIsContractorLinked] = useState(false);
  const [prefLoading, setPrefLoading] = useState(false);
  // let contractor: string = methods.watch("maintenanceCompany", "");
  let contractor: string = useWatch({
    control: methods.control,
    name: "maintenanceCompany",
    defaultValue: "",
  });

  const onSubmit = async (data: any) => {
    setAssignment({
      _id: assignment?._id ? assignment._id : undefined,
      ...data,
    });
    onOpenAddModal();
  };

  console.log("formData", assignment);
  const location = useLocation();
  const getFormData = async () => {
    setLoading(true);
    try {
      const response = await GetAssignmentFormAPI();
      if (response.status === 200) {
        setContractorTypes(response.data.data.contractorTypes);
        setMaintenanceCompanies(response.data.data.maintenanceCompanies);
        setManagementUsers({
          names: response.data.data.managementUsers.map(
            (user: any) => user.firstName + " " + user.lastName
          ),
          ids: response.data.data.managementUsers.map((user: any) => user._id),
        });
        setCommunities({
          names: response.data.data.communities.map(
            (community: any) => community.communityName
          ),
          ids: response.data.data.communities.map(
            (community: any) => community._id
          ),
        });
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getContractorUsers = async () => {
    setPrefLoading(true);
    if (contractor && contractor !== "") {
      let info = {
        companyType: "Maintenance",
        companyId: contractor,
        page: 1,
        pageSize: 10,
      };
      console.log(info);
      try {
        const response = await GetCompanyUserList(info);
        if (response.status === 200) {
          console.log("contractor users", response.data.data);
          console.log({
            names: response.data.data.map(
              (user: any) => user.firstName + " " + user.lastName
            ),
            ids: response.data.data.map((user: any) => user._id),
          });
          setEmployees({
            names: response.data.data.map(
              (user: any) => user.firstName + " " + user.lastName
            ),
            ids: response.data.data.map((user: any) => user._id),
          });

          //if we refetch this data then check if the selected users are  in new data else clear the selected values

          for (let i = 0; i < employeePreferenceData.length; i++) {
            let found = false;
            for (let j = 0; j < response.data.data.length; j++) {
              if (employeePreferenceData[i]._id === response.data.data[j]._id) {
                found = true;
                break;
              }
            }
            if (!found) {
              setEmployeePreferenceData([]);
              break;
            }
          }
        } else {
          toast.error(response.message ?? "Something went wrong");
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setPrefLoading(false);
      }
    }
  };

  const id = useParams().id;

  const getAssignmentData = async (id: string) => {
    // await getFormData();
    setLoading(true);
    try {
      const response = await GetAssignmentData({
        id: id,
      });

      if (response.status === 200) {
        console.log(response.data.data);
        // contractor =
        //   response.data.data.assignment.maintenanceCompanyData[0]._id;

        if (response.data?.data?.assignment?.notes) {
          setNotes(response.data?.data?.assignment?.notes);
        }

        methods.setValue(
          "maintenanceCompany",
          response.data.data.assignment.maintenanceCompanyData[0]._id
        );

        methods.setValue(
          "employeePreference",
          response.data.data.assignment.employeePreference
        );

        setAssignment({
          ...response.data.data.assignment,
          assignedBy: response.data.data.assignment.assignedByUser[0]._id,
          // maintenanceCompany:
          //   response.data.data.assignment.maintenanceCompanyData[0]._id,
        });

        console.log("assignmentData", response.data.data);

        setEmployeePreferenceData([
          ...response.data.data.assignment?.employeePreferenceData,
        ]);

        console.log("assignmentData", response.data.data, assignment);
        setImages(response.data.data.assignment.images);
        const locations = response.data?.data?.assignment?.images?.map(
          (image: any) => {
            return {
              lat: Number(image.geoLocation.lat),
              lng: Number(image.geoLocation.lng),
            };
          }
        );
        setAttachments(response.data?.data?.assignment?.attachments);
        setLocations(locations);
        // await getContractorUsers();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getFormData();
    if (id && id !== "") {
      getAssignmentData(id);
    }
  }, [id]);

  useEffect(() => {
    console.log("isLinked", maintenanceCompanies, contractor);
    if (
      maintenanceCompanies &&
      maintenanceCompanies.length > 0 &&
      contractor &&
      contractor !== ""
    ) {
      console.log("inside useEffect", contractor);
      let isLinked = maintenanceCompanies.filter(
        (company) => company._id === contractor
      )[0].isLinked;
      console.log("isLinked: ", isLinked);
      isLinked ? setIsContractorLinked(true) : setIsContractorLinked(false);
      getContractorUsers();
    }
  }, [contractor, maintenanceCompanies]);

  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();

  console.log("assignmentData2", assignment);
  // console.log(assignment);

  return (
    <>
      <FormProvider {...methods}>
        <chakra.form
          autoComplete="off"
          className="w-full flex flex-col items-center justify-center gap-9 max-w-full"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {loading ? (
            <div className="min-w-full h-full flex items-center justify-center max-w-full">
              <Loader className="w-40 h-40" type="square" />
            </div>
          ) : (
            <div className="md:w-[600px] md:px-9 pb-2  md:gap-y-0.5  max-w-full">
              <CustomInput
                type="text"
                name="subject"
                label="Subject"
                value={assignment?.subject ?? ""}
                placeholder=""
                isRequired={true}
              />
              <CustomInput
                type="text"
                name="description"
                label="Description"
                value={assignment?.description ?? ""}
                placeholder=""
                isRequired={true}
              />
              <DropdownInput
                options={communities.names}
                map={communities.ids}
                label="Client Community"
                name="communityId"
                value={
                  assignment?.communityDetails
                    ? assignment?.communityDetails?.communityName
                    : null
                }
                mapValue={
                  assignment?.communityDetails
                    ? assignment?.communityDetails?._id
                    : null
                }
                placeholder="- Select -"
                isRequired={false}
              />
              <DropdownInput
                options={managementUsers?.names}
                map={managementUsers?.ids}
                label="Assigned By"
                name="assignedBy"
                value={
                  assignment?.assignedByUser
                    ? assignment?.assignedByUser[0]?.firstName +
                      " " +
                      assignment?.assignedByUser[0]?.lastName
                    : null
                }
                mapValue={
                  assignment?.assignedByUser
                    ? assignment?.assignedByUser[0]?._id
                    : null
                }
                placeholder="- Select -"
                isRequired={true}
              />
              <MultiSelectInput
                options={contractorTypes}
                values={assignment?.contractorType ?? null}
                label="Contractor Type"
                name="contractorType"
                placeholder="- Select -"
                // value={assignment?.contractorType ?? null}
                isRequired={true}
              />
              <DropdownInput
                options={maintenanceCompanies.map(
                  (company: any) => company.companyName
                )}
                map={maintenanceCompanies.map((company: any) => company._id)}
                label="Contractor Name"
                name="maintenanceCompany"
                value={
                  assignment?.maintenanceCompanyData
                    ? assignment.maintenanceCompanyData![0].companyName
                    : null
                }
                mapValue={
                  assignment?.maintenanceCompanyData
                    ? assignment.maintenanceCompanyData![0]._id
                    : null
                }
                placeholder="- Select -"
                isRequired={true}
              />
              {isContractorLinked ? (
                !prefLoading ? (
                  <MultiDropDown
                    options={employees.names}
                    map={employees.ids}
                    mapValues={employeePreferenceData.map(
                      (employee: any) => employee._id
                    )}
                    values={employeePreferenceData.map(
                      (employee: any) =>
                        employee.firstName + " " + employee.lastName
                    )}
                    label="Employee Preference"
                    name="employeePreference"
                    placeholder="- Select -"
                    isRequired={false}
                  />
                ) : (
                  // "loading"
                  <div className="min-w-full h-full flex items-center justify-center max-w-full">
                    <Loader className="w-20 h-20" type="square" />
                  </div>
                )
              ) : null}
              <div className="w-full flex justify-end col-span-2">
                <button
                  type="submit"
                  className="h-[40px] w-[141px] bg-[#55A8FD] text-white  rounded"
                  // onClick={handleClick}
                >
                  {id ? "Update" : "Create"}
                </button>
              </div>
            </div>
          )}
        </chakra.form>
      </FormProvider>
      <SubmitModal
        isOpen={isOpenAddModal}
        onClose={onCloseAddModal}
        title={
          assignment?._id || location.pathname.includes("edit-assignment")
            ? "Edit Assignment"
            : "Add New Assignment"
        }
        text={`Click on continue to ${
          assignment?._id || location.pathname.includes("edit-assignment")
            ? "edit this"
            : "create new"
        } Assignment.`}
        onConfirm={() => {
          console.log("confirm", assignment);
          if (id) {
            editAssignment(assignment);
          } else {
            createAssignment(assignment);
          }
        }}
      />
    </>
  );
};

export default AssignmentForm;
