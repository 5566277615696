import { UserInfo } from "os";
import axios from "../Helpers/Axios";

/* 
  Add Management Company API
  body - AddManagementCompanyProps
  response AddManagementCompanyResponse
*/

export interface AddManagementCompanyProps {
  companyName?: string;
  companyPhoneNo?: number;
  masterAdminFirstName: string;
  // masterAdminMiddleName: string;
  masterAdminLastName: string;
  masterAdminEmail: string;
  masterAdminPhoneNo: number;
  masterAdminTitle: string;
  masterAdminNotificationPref: string;
}

interface AddManagementCompanyResponse {
  status: number;
  message: string;
}

export const AddManagementCompanyAPI = async (
  credentials: AddManagementCompanyProps
): Promise<AddManagementCompanyResponse> => {
  let responseData: AddManagementCompanyResponse;

  try {
    const response = await axios.post(
      `/api/account/management-signup`,
      credentials
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
Add Maintenance Company API
body - AddMaintenanceCompanyProps
response - AddMaintenanceCompanyResponse
*/

export interface AddMaintenanceCompanyProps {
  companyName: string;
  companyPhoneNo: number;
  masterAdminFirstName: string;
  // masterAdminMiddleName: string;
  masterAdminLastName: string;
  masterAdminEmail: string;
  masterAdminPhoneNo: number;
  masterAdminTitle: string;
  masterAdminNotificationPref: string;
}

interface AddMaintenanceCompanyResponse {
  status: number;
  message: string;
}

export const AddMaintenanceCompanyAPI = async (
  credentials: AddMaintenanceCompanyProps
): Promise<AddMaintenanceCompanyResponse> => {
  let responseData: AddMaintenanceCompanyResponse;

  try {
    const response = await axios.post(
      `/api/account/maintenance-signup`,
      credentials
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
Add User API
body - AddUserProps
response - AddUserResponse
*/

export interface AddUserProps {
  firstName: string;
  // middleName: string;
  lastName: string;
  phoneNo: number;
  countryCode: string;
  title: string;
  authorityLevel: string;
  email: string;
  notificationPref: string;
  company?:string;
  companyType?:string;
}

interface AddUserResponse {
  status: number;
  message: string;
}

export const AddUserAPI = async (
  credentials: AddUserProps
): Promise<AddUserResponse> => {
  let responseData: AddUserResponse;

  try {
    const response = await axios.post(`/api/account/invite-user`, credentials);

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Reset Password API
  body - AddIndividualManagerProps
  response - AddIndividualManagerResponse
*/

export interface AddIndividualManagerProps {
  firstName: string;
  // middleName: string;
  lastName: string;
  phoneNo: number;
  countryCode: string;
  title: string;
  authorityLevel: string;
  email: string;
  notificationPref: string;
}

interface AddIndividualManagerResponse {
  status: number;
  message: string;
}

export const AddIndividualManagerAPI = async (
  credentials: AddIndividualManagerProps
): Promise<AddIndividualManagerResponse> => {
  let responseData: AddIndividualManagerResponse;

  try {
    const response = await axios.post(
      `/api/account/individual-owner-signup`,
      credentials
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Add Maintenance Global API
  body - AddMaintenanceGlobalProps
  response - AddMaintenanceGlobalResponse
*/

interface AddMaintenanceGlobalProps {
  newPassword: string;
}

interface AddMaintenanceGlobalResponse {
  status: number;
  message: string;
}

export const AddMaintenanceGlobalAPI = async (
  credentials: AddMaintenanceGlobalProps
): Promise<AddMaintenanceGlobalResponse> => {
  let responseData: AddMaintenanceGlobalResponse;

  try {
    const response = await axios.post(
      `/api/account/global-maintenance-signup`,
      credentials
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Add Community API
  body - AddMaintenanceGlobalProps
  response - AddMaintenanceGlobalResponse
*/

export interface AddCommunityProps {
  communityName: string;
  communityPhoneNo: string;
  // address: string;
  state:string;
  city:string;
}

export interface AddCommunityResponse {
  status: number;
  message: string;
}

export const AddCommunityAPI = async (
  credentials: AddCommunityProps
): Promise<AddCommunityResponse> => {
  let responseData: AddCommunityResponse;

  try {
    const response = await axios.post(
      `/api/community/create-community`,
      credentials
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Add Community API
  body - AddMaintenanceGlobalProps
  response - AddMaintenanceGlobalResponse
*/

export interface AddCommunityUserProps {
  firstName: string;
  // middleName: string;
  lastName: string;
  phoneNo: number;
  email: string;
  notificationPref: string;
  communityId: string;
}

export interface AddCommunityUserResponse {
  status: number;
  message: string;
}

export const AddCommunityUserAPI = async (
  credentials: AddCommunityUserProps
): Promise<AddCommunityUserResponse> => {
  let responseData: AddCommunityUserResponse;

  try {
    const response = await axios.post(
      `/api/community/add-user`,
      credentials
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Delete Community API
*/

export interface DeleteCommunityProps {
  ids: string[]
}

interface DeleteCommunityResponse {
  status: number;
  message: string;
}

export const DeleteCommunityAPI = async (
  credentials: DeleteCommunityProps
): Promise<DeleteCommunityResponse> => {
  let responseData: DeleteCommunityResponse;

  try {
    const response = await axios.post(
      `/api/community/remove-community`,
      { communityIds: credentials.ids }
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Add Assignments API
  body - AddMaintenanceGlobalProps
  response - AddMaintenanceGlobalResponse
*/

export interface AddAssignmentsProps {
  data: any
}

export interface AddAssignmentsResponse {
  status: number;
  message: string;
}

export const AddAssignmentsAPI = async (
  data: AddAssignmentsProps
): Promise<AddAssignmentsResponse> => {
  let responseData: AddAssignmentsResponse;

  try {
    const response = await axios.post(
      `/api/assignment`,
      data.data
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Edit Assignments API
  body - AddMaintenanceGlobalProps
  response - AddMaintenanceGlobalResponse
*/

export interface EditAssignmentsProps {
  id:string
  data: any
}

export interface EditAssignmentsResponse {
  status: number;
  message: string;
}

export const EditAssignmentsAPI = async (
  data: EditAssignmentsProps
): Promise<EditAssignmentsResponse> => {
  let responseData: EditAssignmentsResponse;

  try {
    const response = await axios.patch(
      `/api/assignment/update?id=${data.id}`,
      data.data
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Update Assignment API
*/

export interface UpdateAssignmentProps {
  description: string;
  assignmentId: string;
}

interface UpdateAssignmentResponse {
  status: number;
  message: string;
}

export const UpdateAssignment = async (
  credentials: UpdateAssignmentProps
): Promise<UpdateAssignmentResponse> => {
  let responseData: UpdateAssignmentResponse;

  try {
    const response = await axios.patch(
      `/api/assignment/${credentials.assignmentId}`, { description: credentials.description });

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Accept Assignment API
*/

export interface AcceptAssignmentProps {
  assignedUsers: string[];
  assignmentId: string;
}

interface AcceptAssignmentResponse {
  status: number;
  message: string;
}

export const AcceptAssignment = async (
  credentials: AcceptAssignmentProps
): Promise<AcceptAssignmentResponse> => {
  let responseData: AcceptAssignmentResponse;

  try {
    const response = await axios.post(
      `/api/assignment/accept`, credentials);

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Decline Assignment API
*/

export interface DeclineAssignmentProps {
  assignmentId: string;
  declineReason:string;
}

interface DeclineAssignmentResponse {
  status: number;
  message: string;
}

export const DeclineAssignment = async (
  credentials: DeclineAssignmentProps
): Promise<DeclineAssignmentResponse> => {
  let responseData: DeclineAssignmentResponse;

  try {
    const response = await axios.post(
      `/api/assignment/decline?assignmentId=${credentials.assignmentId}`,{declineReason:credentials.declineReason});

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Delete Company API
*/

export interface DeleteCompanyProps {
  companyType: string
  ids: string[]
}

interface DeleteCompanyResponse {
  status: number;
  message: string;
}

export const DeleteCompanyAPI = async (
  credentials: DeleteCompanyProps
): Promise<DeleteCompanyResponse> => {
  let responseData: DeleteCompanyResponse;

  try {
    const response = await axios.post(
      `/api/account/remove-${credentials.companyType === "Maintenance" ? "maintenance" : "management"}-company`,
      { ids: credentials.ids }
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Update User API
*/
export const UpdateUserAPI=async(userId?:string,data?:any)=>{
  try {
    const response=await axios.patch(`/api/account/user?userId=${userId}`,data);
    const responseData={
      status:response?.status,
      message:response?.data?.message
    }
    return responseData;
  } catch (error:any) {
    console.log(error);
    const responseData={
      status:error?.response?.status??500,
      message:error?.response?.data?.message??'Please try again.'
    }
    return responseData
  }
}



/* 
  Delete User API
*/

export interface DeleteUserProps {
  ids: string[]
  authority: string
}

interface DeleteUserResponse {
  status: number;
  message: string;
}

export const DeleteUserAPI = async (
  credentials: DeleteUserProps
): Promise<DeleteUserResponse> => {
  let responseData: DeleteUserResponse;

  try {
    const response = await axios.post(
      `/api/account/${credentials.authority}/delete-users`,
      { ids: credentials.ids }
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Close Assignment API
*/

export interface CloseAssignmentProps {
  assignmentId: string;
  closingNotes: string;
}

interface CloseAssignmentResponse {
  status: number;
  message: string;
}

export const CloseAssignment = async (
  credentials: CloseAssignmentProps
): Promise<CloseAssignmentResponse> => {
  let responseData: CloseAssignmentResponse;

  try {
    const response = await axios.post(
      `/api/assignment/close-assignment`, credentials);

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Reopen Assignment API
*/

export interface ReOpenAssignmentProps {
  assignmentId: string;
}

interface ReOpenAssignmentResponse {
  status: number;
  message: string;
}

export const ReOpenAssignment = async (
  credentials: ReOpenAssignmentProps
): Promise<ReOpenAssignmentResponse> => {
  let responseData: ReOpenAssignmentResponse;

  try {
    const response = await axios.get(
      `/api/assignment/assignment-reopen?assignmentId=${credentials.assignmentId}`);

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Add Comment API
*/

export interface AddCommentProps {
  assignmentId: string;
  comment: string;
  commentDate: string;
}

export interface AddCommentResponse {
  status: number;
  message: string;
}

export const AddCommentAPI = async (
  data: AddCommentProps
): Promise<AddCommentResponse> => {
  let responseData: AddCommentResponse;

  try {
    console.log(data)
    const response = await axios.post(
      `/api/assignment/add-comment`,
      data
    );

    responseData = {
      status: response?.status,
      message: response?.data?.message,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Create Diary API
*/

export interface CreateDiaryProps {
  assignmentId: string;
  diaryNote: string;
  diaryDate: string;
}

export interface CreateDiaryResponse {
  status: number;
  message: any;
}

export const CreateDiaryAPI = async (
  data: CreateDiaryProps
): Promise<CreateDiaryResponse> => {
  let responseData: CreateDiaryResponse;

  try {
    const response = await axios.post(
      `/api/assignment/diary/create`,
      data
    );

    responseData = {
      status: response?.status,
      message: response?.data,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Update Diary API
*/

export interface UpdateDiaryProps {
  diaryId: string;
  diaryDate: string;
  diaryNote: string;
}

export interface UpdateDiaryResponse {
  status: number;
  message: any;
}

export const UpdateDiaryAPI = async (
  data: UpdateDiaryProps
): Promise<UpdateDiaryResponse> => {
  let responseData: UpdateDiaryResponse;

  try {
    const response = await axios.post(
      `/api/assignment/diary/update`,
      data
    );

      // console.log("diary response",response)

    responseData = {
      status: response?.status,
      message: response?.data,
    };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};
