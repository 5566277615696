export default function TrashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M2.9 5.6h16.2M17.3 5.6v12.6a1.8 1.8 0 01-1.8 1.8h-9a1.8 1.8 0 01-1.8-1.8V5.6m2.7 0V3.8A1.8 1.8 0 019.2 2h3.6a1.8 1.8 0 011.8 1.8v1.8M9.2 10.1v5.4M12.8 10.1v5.4"
      ></path>
    </svg>
  );
}
