import { useState, useMemo, useRef, useEffect, memo, useContext } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { UserContext } from "../Context/UserContext";
import ConfirmModal from "./Modals/ConfirmModal";
import { Spinner, useDisclosure } from "@chakra-ui/react";
import { AddAssignmentsAPI, EditAssignmentsAPI } from "../Utils/ADD";
import { useNavigate } from "react-router-dom";

export interface MapLocationProps {
  imageData?:
    | {
        index: number;
        fileName: string;
        location: { lat: string | number; lng: string | number };
      }
    | null
    | undefined;
}

interface MapProps {
  imageData: {
    index: number;
    fileName: string;
    location: { lat: string | number; lng: string | number };
  };
}

export default function MapLocation({ imageData = null }: MapLocationProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCUm21Oy_HE_ctO80Hc2cwkjtB9xTElxLE",
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Map
      imageData={
        imageData
          ? imageData
          : { index: -1, fileName: "", location: { lat: 43.45, lng: -80.49 } }
      }
    />
  );
}

//Map Component
function Map({ imageData }: MapProps) {
  const { assignment, setAssignment, confirm, location, setLocation } =
    useContext(UserContext);
  console.log("DefaultLocation", imageData);
  const navigate = useNavigate();

  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();

  const [selected, setSelected] = useState({
    lat: Number(imageData.location.lat),
    lng: Number(imageData.location.lng),
  });
  console.log(imageData.location);

  //

  const [locationName, setLocationName] = useState<string | null>(null);
  const searchBarRef = useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (imageData && imageData?.index !== -1) {
      //set values if location is being edited
      console.log("Inside useEffect", imageData, assignment);
      let newSelectedValue = {
        lat: Number(imageData.location.lat),
        lng: Number(imageData.location.lng),
      };

      setSelected(newSelectedValue);
    } else {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition((location) => {
          console.log(location.coords);
          setSelected({
            lat: location.coords.latitude,
            lng: location.coords.longitude,
          });
        });
      }
    }
  }, []);

  useEffect(() => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: selected }, (results, status) => {
      if (status === "OK" && results && results[0]) {
        setLocationName(results[0].formatted_address);
      }
    });

    setLocation(
      imageData && imageData.index !== -1
        ? {
            index: imageData.index,
            location: selected,
          }
        : selected
    );
  }, [selected]);

  useEffect(() => {
    if (searchBarRef.current) {
      searchBarRef.current.value = locationName || "";
      const event = new Event("input", { bubbles: true });
      searchBarRef.current.dispatchEvent(event);
    }
  }, [locationName]);

  const handleMarkerDragEnd = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      let { lat, lng } = e.latLng.toJSON();
      console.log(lat, lng);
      lat = parseFloat(lat.toFixed(2));
      lng = parseFloat(lng.toFixed(2));
      setSelected({ lat, lng });
    }
  };

  return (
    <div className="flex flex-1 flex-col items-center w-full gap-6 md:h-[60%] bg-[#F8FEFC] max-h-94">
      <div
        className="flex w-full flex-1 flex-col overflow-hidden p-4 gap-3
         bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]"
      >
        <>
          <p className="md:text-2xl text-md font-medium">Select Location</p>
          <div className="places-container">
            <PlacesAutocomplete
              setSelected={setSelected}
              searchBarRef={searchBarRef}
            />
          </div>

          <div className="w-full flex justify-end gap-x-2">
            <p className="text-xs md:text-sm font-normal">
              Latitude, Longitude = {selected?.lat}, {selected?.lng}{" "}
            </p>
          </div>

          <GoogleMap
            zoom={10}
            center={selected}
            mapContainerStyle={{ height: "400px", width: "100%" }}
            options={{
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
          >
            {selected && (
              <MarkerF
                position={selected}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
          </GoogleMap>
          {/* <div className="w-full flex justify-end mt-2">
            <button
              onClick={() => {
                onOpenAddModal();
              }}
              className="h-[40px] w-[141px] bg-[#55A8FD] text-white  rounded"
            >
              <div className="flex flex-row items-center justify-center gap-2">
                {assignment?._id ? "Edit" : "Create"}
                {loading && (
                  <div className="w-6 h-6">
                    <Spinner />
                  </div>
                )}
              </div>
            </button>
          </div> */}
        </>
      </div>
    </div>
  );
}

//PlacesAutocomplete Component

interface PlacesAutocompleteProps {
  setSelected: (selected: { lat: number; lng: number }) => void;
  searchBarRef: React.RefObject<HTMLInputElement>;
}

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = memo(
  ({ setSelected, searchBarRef }) => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address: string) => {
      setValue(address, false);
      clearSuggestions();

      const results = await getGeocode({ address });
      let { lat, lng } = getLatLng(results[0]);
      lat = parseFloat(lat.toFixed(2));
      lng = parseFloat(lng.toFixed(2));
      setSelected({ lat, lng });
    };

    return (
      <div className="">
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          placeholder="Search.."
          className="h-[32px] md:w-[400px] border !border-solid border-[#ACACAC] rounded py-1 px-2 w-[80%]"
          ref={searchBarRef}
        />
        <div className="absolute z-10 bg-white">
          <ul className="border !border-solid md:w-[400px] w-full">
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <li
                  key={place_id}
                  onClick={() => handleSelect(description)}
                  className="hover:bg-[#ACACAC] px-1 py-1 focus:bg-[#ACACAC] active:bg-[#ACACAC]"
                >
                  {description}
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
);
