export default function NoDataIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="93"
      height="110"
      fill="none"
      viewBox="0 0 93 110"
    >
      <g opacity="0.8">
        <path
          stroke="#55A8FD"
          strokeWidth="2.4"
          d="M39.279 24.959l-2.56 16.96L52.5 34m7.58-19.281l8.32 8.96m-8.32 0l8.32-8.96"
        ></path>
        <path
          stroke="#55A8FD"
          strokeLinejoin="round"
          strokeWidth="2.4"
          d="M84.174 90.819l-4.096 14.08c-.427.938-2.304 2.867-6.4 3.072H7.887c-.797-.199-2.026-1.018-1.956-2.817m80.548-24.319l3.072-11.52c.615-2.048-1.28-2.56-2.304-2.56H19.92c-2.048-.205-3.414 1.45-3.84 2.303l-9.73 34.305c-.273.683-.399 1.277-.42 1.791M34 83.139h5.12m23.04 0h5.375m-20.991 12.8c1.365-1.877 4.915-4.505 8.192 0M75.47 58.563v-5.632c0-.939-.716-2.765-3.584-2.56H29.392l-6.144-6.912c-.341-.426-1.536-1.229-3.584-1.024H3.536C3.024 42.52 2 43.048 2 44.481l3.93 60.672"
        ></path>
        <path
          stroke="#55A8FD"
          strokeWidth="2.4"
          d="M90.881 19.202c0 4.303-2.762 8.375-7.595 11.43-4.819 3.047-11.549 4.97-19.045 4.97-7.497 0-14.227-1.923-19.045-4.97-4.834-3.055-7.596-7.127-7.596-11.43 0-4.304 2.762-8.376 7.596-11.431C50.014 4.725 56.744 2.8 64.24 2.8c7.496 0 14.226 1.924 19.045 4.97 4.833 3.055 7.595 7.127 7.595 11.43z"
        ></path>
        <path fill="#fff" d="M68.401 0H76.401V7.2H68.401z"></path>
        <path fill="#fff" d="M38 39.96L40.56 23l13.221 9.041L38 39.961z"></path>
      </g>
    </svg>
  );
}
