import { useContext, useState } from "react";
import vectorImg from "../Assets/loginVector.png";
import { useForm, FormProvider } from "react-hook-form";
import { chakra } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../Components/Input";
import CustomButton from "../Components/Button";
import { LogInAPI } from "../Utils/AUTH";
import UserIcon from "../Assets/Icons/UserIcon";
import MailIcon from "../Assets/Icons/MailIcon";
import HideIcon from "../Assets/Icons/HideIcon";
import { UserContext } from "../Context/UserContext";
import toast from "react-hot-toast";

export default function Login() {
  const navigate = useNavigate();
  const methods = useForm();

  const [loading, setLoading] = useState(false);

  const { user, setUser, setVerified } = useContext(UserContext);

  const onSubmit = async (data: any) => {
    console.log(data);
    setLoading(true);
    try {
      const response = await LogInAPI(data);
      if (response.status === 200) {
        console.log(response);
        setLoading(false);
        if (!response?.data?.inviteAccepted) {
          navigate("/create-password");
        } else {
          setUser({
            name: response?.data!.firstName + " " + response?.data!.lastName,
            email: response.data?.email!,
            phone: response.data?.phoneNo!,
            company:
              response.data?.companyType ?? response.data?.authorityLevel!,
            authority: response.data?.authorityLevel!,
            companyName: response.data?.companyName,
            companyId: response.data?.companyId,
          });
          localStorage.setItem("userId", response.data?._id!);
          setVerified(response.data?.phoneNoVerified!);
          localStorage.setItem(
            "verified",
            response.data?.phoneNoVerified ? "true" : "false"
          );
          const company =
            response.data?.companyType ?? response.data?.authorityLevel!;
          switch (company) {
            case "Owner":
              navigate("/management-companies");
              break;
            case "Management":
              navigate("/users");
              break;
            case "Individual":
              navigate("/maintenance-contractors");
              break;
            case "Maintenance":
              navigate("/users");
              break;
          }
        }
      } else {
        setLoading(false);
        toast.error(response.message);
        console.error(response);
        if (response.status === 404) {
          methods.setError("email", {
            type: "manual",
            message: response.message,
          });
        } else if (response.status === 401) {
          methods.setError("password", {
            type: "manual",
            message: response.message,
          });
        } else {
          console.error(response.message);
        }
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "Something went wrong.");
      console.error(error);
    }
  };

  return (
    <div className="w-full h-[100vh] font-inter bg-white relative flex flex-row items-center justify-center lg:justify-end lg:pr-[15%]">
      <img
        src={vectorImg}
        className="absolute hidden lg:flex -bottom-0 left-0 w-[60%] xl:w-[50%]"
        alt="Welcome"
      />

      <div className="flex flex-col w-full mx-4 sm:mx-0 sm:w-[24rem] items-center">
        <span className="text-green-primary w-full text-center mb-10 text-3xl sm:text-[2.5rem] font-bold font-inter">
          Let's get you there!
        </span>
        <FormProvider {...methods}>
          <chakra.form
            className="flex flex-col w-full gap-5"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <CustomInput
              type="email"
              name="email"
              label="Email address"
              placeholder="Email"
              isRequired={true}
              Icon={MailIcon}
              themeColor="green"
            />

            <CustomInput
              type="password"
              name="password"
              label="Password"
              placeholder="Password"
              isRequired={true}
              Icon={HideIcon}
              themeColor="green"
            />

            {/* <FormControl mt="-5" mb="8">
              <Checkbox
                size="lg"
                colorScheme="green"
                borderColor="#ACACAC"
              >
                <span className="text-gray-dark px-1">Stay signed in</span>
              </Checkbox>
            </FormControl> */}

            <CustomButton loading={loading} text="Login" />
          </chakra.form>
        </FormProvider>
        <div
          onClick={() => {
            navigate("/reset-password");
          }}
          className="flex flex-row w-full justify-center text-md gap-1 mt-4 cursor-pointer"
        >
          <span className="text-gray-dark">Forgot</span>
          <span className="text-green-primary underline font-semibold">
            Password?
          </span>
        </div>
      </div>
    </div>
  );
}
