export default function ContractorIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      className={className}
    >
      <path
        fill="currentColor"
        d="M12.104 5.54a.364.364 0 00.367-.36V1.94c0-.79-.674-1.44-1.47-1.44-.812 0-1.47.645-1.47 1.44v3.24c0 .199.165.36.368.36h2.205zM5.12 9.53a.84.84 0 010-1.68h11.76a.84.84 0 110 1.68H5.121z"
      ></path>
      <path
        fill="currentColor"
        d="M13.308 5.18c0 .679-.557 1.2-1.207 1.2H9.896c-.65 0-1.208-.521-1.208-1.2V1.94c0-.113.008-.223.024-.33A5.671 5.671 0 005.328 6.8v.21h11.34V6.8a5.67 5.67 0 00-3.384-5.19c.016.107.024.217.024.33v3.24zm-7.14 6.03v-.84h9.66v.84a4.824 4.824 0 01-4.83 4.83 4.824 4.824 0 01-4.83-4.83z"
      ></path>
      <path
        fill="currentColor"
        d="M14.566 15.619a5.7 5.7 0 001.145-1.252c.402.02.793.078 1.17.171V21.5H5.121v-6.962a6.087 6.087 0 011.17-.17c.317.471.703.893 1.145 1.25a3.781 3.781 0 007.13.001z"
      ></path>
      <path
        fill="currentColor"
        d="M11 16.88a5.657 5.657 0 01-1.996-.362 2.929 2.929 0 001.996.781c.77 0 1.472-.296 1.996-.781a5.659 5.659 0 01-1.996.361zm10.16 4.62h-3.44v-6.688a6.086 6.086 0 013.78 5.637v.71c0 .184-.156.34-.34.34zm-16.88 0H.84a.34.34 0 01-.34-.34v-.71a6.087 6.087 0 012.918-5.2l.022-.014c.269-.163.55-.304.84-.424V21.5z"
      ></path>
    </svg>
  );
}
