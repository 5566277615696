import React, { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteProps {
  userId: string | null;
  redirectPath: string;
  children?: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  userId,
  redirectPath,
  children,
}) => {
  if (!userId) return <Navigate to={redirectPath} replace />;

  return children ? (children as React.ReactElement) : <Outlet />;
};

export default ProtectedRoute;
