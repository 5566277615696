export default function CloseIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      fill="none"
      viewBox="0 0 8 8"
      className={className ?? "w-3 h-3 text-black"}
    >
      <path stroke="currentColor" strokeWidth="0.8" d="M1 7l6-6M1 1l6 6"></path>
    </svg>
  );
}
