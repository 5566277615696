import { useState } from "react";
import logo from "../Assets/logo.png";
import {
  CustomInput,
  DropdownInput,
  CustomPhoneInput,
  MultiSelectInput,
} from "../Components/Input";
import CustomButton from "../Components/Button";
import { useForm, FormProvider } from "react-hook-form";
import { chakra } from "@chakra-ui/react";
import { AddMaintenanceGlobalAPI } from "../Utils/ADD";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

export default function CreateAccount() {
  const navigate = useNavigate();
  const methods = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    console.log(data);
    let info = {
      ...data,
      companyPhoneNo: "+" + data.companyPhoneNo,
      masterAdminPhoneNo: "+" + data.masterAdminPhoneNo,
      masterAdminNotificationPref:
        data.masterAdminNotificationPref.toLowerCase(),
    };
    console.log(info);
    // onOpen();
    setLoading(true);
    try {
      const response = await AddMaintenanceGlobalAPI(info);
      if (response.status === 201) {
        console.log(response);
        toast.success(
          response.message + " An invite has been sent to your email."
        );
        navigate("/login");
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "Something went wrong.");
      console.error(error);
    }
  };

  return (
    <div className="w-full min-h-[100vh] py-6 overflow-y-auto flex items-center justify-center font-inter bg-[#F9F9F9] relative">
      <div className="w-[30%] h-full bg-green-primary absolute top-0 left-0" />
      <FormProvider {...methods}>
        <chakra.form
          autoComplete="off"
          className="flex flex-col w-[90%] items-end gap-6"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="w-full z-10 flex items-center justify-between">
            <Link to="/">
              <img
                src={logo}
                alt="ProCam Tasks"
                className="cursor-pointer w-[25%] sm:w-40"
              />
            </Link>
            <Link to="/login">
              <CustomButton
                w={"w-20 sm:w-40"}
                loading={loading}
                text="Login"
                type="button"
              />
            </Link>
          </div>
          <div className="z-10 py-6 sm:py-10 px-6 sm:px-20 w-full bg-white rounded-lg flex flex-col items-end">
            <span className="text-[#555555] text-xl sm:text-2xl lg:text-[2.5rem] w-full text-left font-bold">
              Welcome, Create your Account
            </span>
            <div className="w-full mt-8 pb-2 grid grid-rows-1 lg:grid-cols-3 gap-x-12 gap-y-0.5">
              <CustomInput
                type="text"
                name="companyName"
                label="Company Name"
                placeholder="Company name"
                isRequired={true}
              />
              <CustomPhoneInput
                type="number"
                name="companyPhoneNo"
                label="Company Phone Number"
                placeholder="Phone Number"
                isRequired={true}
              />
            </div>
            <span className="text-[#555555] mt-4  sm:text-lg lg:text-[1.75rem] w-full text-left font-medium">
              Primary Administrative Contact Details
            </span>
            <div className="w-full mt-8 pb-2 grid grid-rows-1 lg:grid-cols-3 gap-x-12 gap-y-0.5">
              <CustomInput
                type="text"
                name="masterAdminFirstName"
                label="First Name"
                placeholder="Admin First name"
                isRequired={true}
                themeColor="green"
              />
              {/* <CustomInput
                type="text"
                name="masterAdminMiddleName"
                label="Middle Name"
                placeholder="Admin Middle name"
                isRequired={false}
                themeColor="green"
              /> */}
              <CustomInput
                type="text"
                name="masterAdminLastName"
                label="Last Name"
                placeholder="Admin Last name"
                isRequired={true}
                themeColor="green"
              />

              <CustomPhoneInput
                type="number"
                name="masterAdminPhoneNo"
                label="Admin Phone Number"
                placeholder="Phone Number"
                isRequired={true}
                themeColor="green"
              />
              <CustomInput
                type="email"
                name="masterAdminEmail"
                label="Admin Email Address"
                placeholder="Admin Email Address"
                isRequired={true}
                themeColor="green"
              />
              <DropdownInput
                options={["Email", "Phone", "Both"]}
                type="text"
                name="masterAdminNotificationPref"
                label="Notification preference"
                placeholder="-Select-"
                isRequired={true}
              />

              <CustomInput
                type="text"
                name="masterAdminTitle"
                label="Designation"
                placeholder="Eg. CEO, Manager, etc"
                isRequired={false}
                themeColor="green"
              />
            </div>
            <CustomButton w={"w-20 sm:w-40"} loading={loading} text="Next" />
          </div>
        </chakra.form>
      </FormProvider>
    </div>
  );
}
