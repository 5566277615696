export default function CommunitiesIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      className={className ?? "w-6 h-6 text-green-primary"}
    >
      <path
        fill="currentColor"
        d="M10.5 0a.75.75 0 00-.488.182L2.137 6.929a.75.75 0 00.977 1.142l.261-.227v3.535a2.267 2.267 0 00-1.5 2.12c0 .444.133.86.36 1.212A4.14 4.14 0 000 18.377a.75.75 0 00.75.75h3.855c-.07.37-.106.746-.105 1.123a.75.75 0 00.75.75h10.5a.75.75 0 00.75-.75c0-.377-.035-.753-.105-1.123h3.855a.75.75 0 00.75-.75 4.14 4.14 0 00-2.234-3.666c.234-.361.359-.782.359-1.213 0-.973-.631-1.809-1.502-2.12V7.845l.263.227a.749.749 0 001.057-.084.75.75 0 00-.078-1.058L10.988.182A.75.75 0 0010.5 0zm.002 1.74l5.625 4.82v4.821a2.26 2.26 0 00-1.502 2.117c0 .445.133.861.361 1.21a4.175 4.175 0 00-.96.69 6.074 6.074 0 00-1.131-.65c.627-.63.98-1.483.98-2.373A3.384 3.384 0 0010.502 9a3.39 3.39 0 00-3.379 3.375 3.354 3.354 0 00.985 2.373c-.4.175-.78.393-1.132.65a4.207 4.207 0 00-.96-.69c.226-.349.36-.764.36-1.21 0-.968-.632-1.804-1.499-2.117V6.56l5.625-4.819z"
      ></path>
    </svg>
  );
}
