import Assignees from "../../Components/Diary/Assignees";
import CustomButton from "../../Components/Button";
import DiaryNotes from "../../Components/Diary/DiaryNotes";
import JobNotes from "../../Components/Diary/JobNotes";
import Photos, { ImageObj } from "../../Components/Diary/Photos";
import Attachments from "../../Components/Diary/Attachments";
import Comments from "../../Components/Diary/Comments";
import ActiveDate from "../../Components/Diary/ActiveDate ";
import AddComment from "../../Components/Diary/AddComments";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import { AssignmentData } from "../../Components/AssignmentCard";
import { GetAssignmentData, GetComments } from "../../Utils/GET";
import toast from "react-hot-toast";
import { CloseAssignment } from "../../Utils/ADD";
import { Loader } from "../../Components/Loader";
import { useDisclosure } from "@chakra-ui/react";
import CloseModal from "../../Components/Diary/CloseModal";
import EditIcon from "../../Assets/Icons/EditIcon";
import DownloadIcon from "../../Assets/Icons/DownloadIcon";
import { formatDate } from "../../Components/AssignmentRequestCard";
import TimeLine from "../../Components/Timeline/TimeLine";

export default function Diary() {
  const location = useLocation();
  const navigate = useNavigate();

  const { user, assignment, setAssignment } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  // const [assignment, setAssignment] = useState<AssignmentData>(
  //   {} as AssignmentData
  // );

  const [comments, setComments] = useState<any[]>([]);

  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();

  const getAssignmentData = async (id: string) => {
    setAssignment({} as AssignmentData);
    setLoading(true);
    try {
      const response = await GetAssignmentData({
        id: location.pathname.split("/")[2],
      });
      if (response.status === 200) {
        console.log(response.data.data);
        setAssignment(response.data.data.assignment);
        setLoading(false);
      } else {
        setLoading(false);
        setAssignment({} as AssignmentData);
        toast.error(response);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  function timeAgo(timestamp: any) {
    const currentDate: any = new Date();
    const inputDate: any = new Date(timestamp);

    const timeDifference = currentDate - inputDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    if (months > 0) {
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
    } else {
      return "Just now";
    }
  }

  const getComments = async (id: string) => {
    setLoading(true);
    try {
      const response = await GetComments({
        id: location.pathname.split("/")[2],
      });
      if (response.status === 200) {
        console.log(response.data.data);
        setComments(response.data.data.comments);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      location.pathname.includes("assignment") &&
      location.pathname.split("/").length === 3
    ) {
      getAssignmentData(location.pathname.split("/")[2]);
      getComments(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  useEffect(() => {
    console.log("Assignment: ", assignment);
  }, [assignment]);

  return (
    <div className="flex flex-1 flex-col p-4 md:p-10 items-center overflow-hidden w-full gap-6 mt-16 h-[calc(100%-50px)] bg-[#FCFCFC]">
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loader className="w-40 h-40" type="square" />
        </div>
      ) : (
        assignment && (
          <div className="flex w-full flex-1 flex-col max-h-[calc(100%-0px)] overflow-y-auto scrollbar py-2 sm:py-4 px-3 sm:px-6 items-center bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]">
            <span className="font-medium text-xl md:text-4xl mt-2 text-[#001124] w-full text-left">
              {assignment?.subject}
            </span>

            <div className="flex lg:flex-row lg:gap-0 gap-2 flex-col-reverse items-center justify-between w-full mt-2">
              <div className="flex lg:flex-row flex-col-reverse  lg:items-center items-start justify-start gap-2 w-full">
                <div className="rounded-full bg-[#FEE2DF] px-3 py-0.5 text-black">
                  {assignment?.status === "Open"
                    ? "Pending"
                    : assignment?.status}
                </div>
                <span className="text-black">
                  {user.company !== "Maintenance" && (
                    <b>
                      {assignment?.assigner
                        ? assignment?.assigner?.firstName +
                          " " +
                          assignment?.assigner?.lastName
                        : ""}
                    </b>
                  )}{" "}
                  {user.company !== "Maintenance" ? "a" : "A"}ssigned{" "}
                  {assignment?.assignmentDates?.openingDate &&
                    timeAgo(assignment?.assignmentDates?.openingDate)}
                </span>
              </div>
              <div className="flex flex-row lg:items-center justify-start gap-4 items-start lg:w-fit w-full ">
                {user.company !== "Maintenance" && (
                  <div className="flex flex-col-reverse p-2 w-full md:flex-row items-center justify-start gap-4">
                    <div
                      onClick={() => {
                        setShowHistory(!showHistory);
                      }}
                      className={`h-9 min-w-[11rem] px-2 flex flex-row border bg-[#55A8FD] text-base text-white rounded w-full justify-center items-center gap-2 cursor-pointer`}
                    >
                      {showHistory ? "View Comments" : "View History"}
                    </div>
                    <div className="flex w-full flex-row items-center justify-center gap-2">
                      <div
                        onClick={() => {
                          onOpenAddModal();
                        }}
                        className={`h-9 min-w-[11rem] px-2 flex flex-row border bg-[#55A8FD] text-base text-white rounded justify-center items-center gap-2 cursor-pointer w-full`}
                      >
                        {assignment?.status === "Completed"
                          ? "Reopen Assignment"
                          : "Close Assignment"}
                      </div>
                      <div
                        onClick={() => {
                          navigate(`/edit-assignment/${assignment._id}`);
                        }}
                      >
                        <EditIcon />
                      </div>
                    </div>
                  </div>
                )}
                {/* <DownloadIcon /> */}
              </div>
            </div>

            <span className="text-black w-full text-start">
              Created on{" "}
              {assignment?.assignmentDates?.openingDate
                ? formatDate(
                    new Date(
                      +assignment?.assignmentDates?.openingDate
                    ).toISOString()
                  )
                : "-"}
            </span>

            <div className="w-full min-h-[1px] bg-[#DBDBDB] mt-5 mb-10" />

            <div className="w-full flex-1 flex flex-col-reverse lg:flex-row gap-10">
              <div className="flex flex-1 flex-col lg:max-w-[70%] w-[100%]">
                <JobNotes assignment={assignment} />
                {!showHistory ? (
                  <>
                    {comments.length > 0 && <Comments comments={comments} />}
                    <AddComment
                      comments={comments}
                      setComments={setComments}
                      assignmentId={location.pathname.split("/")[2]}
                    />
                  </>
                ) : (
                  <>
                    <TimeLine timeLineData={assignment?.audit} />
                  </>
                )}
              </div>

              <div className="flex flex-1 flex-col lg:max-w-[30%] lg:max-h-full lg:overflow-y-auto scrollbar w-[100%]">
                <span className="text-sm text-gray-medium">
                  {user.company === "Management"
                    ? "Maintenance Company"
                    : "Management Company"}
                </span>
                <span className="text-xs lg:text-base text-black">
                  {user.company === "Management"
                    ? assignment?.maintenanceCompanyData
                      ? assignment?.maintenanceCompanyData[0].companyName
                      : "-"
                    : assignment?.assignedByUser
                    ? assignment.assignedByUser[0]?.firstName +
                      " " +
                      assignment.assignedByUser[0]?.lastName
                    : "-"}
                </span>
                {assignment.status?.toUpperCase() === "DECLINED" && (
                  <>
                    <span className="mt-4 text-sm text-gray-medium">
                      Decline Note
                    </span>
                    <span className="text-xs lg:text-base text-black">
                      {assignment?.declineReason
                        ? assignment?.declineReason
                        : "-"}
                    </span>
                  </>
                )}
                <div className="w-full h-[1px] bg-[#DBDBDB] my-5" />
                <ActiveDate
                  assignmentId={location.pathname.split("/")[2]}
                  assignment={assignment}
                  setAssignment={setAssignment}
                />
                {/* <div className="w-full h-[1px] bg-[#DBDBDB] mt-2 mb-5" />
              <Assignees assignees={assignment.assignees} /> */}
                <div className="w-full h-[1px] bg-[#DBDBDB] mt-2 mb-5" />
                <DiaryNotes assignment={assignment} />
                <div className="w-full h-[1px] bg-[#DBDBDB] mt-2 mb-5" />
                {assignment?.images && assignment.images.length > 0 && (
                  <Photos assignment={assignment} />
                )}
                <div className="w-full h-[1px] bg-[#DBDBDB] mt-2 mb-5" />
                {assignment?.attachments && (
                  <Attachments
                    attachments={assignment.attachments as ImageObj[]}
                  />
                )}
              </div>
            </div>
          </div>
        )
      )}
      <CloseModal
        isOpen={isOpenAddModal}
        onClose={onCloseAddModal}
        title={
          assignment?.status === "Completed"
            ? "Reopen Assignment"
            : "Close Assignment"
        }
        text=""
        id={location.pathname.split("/")[2]}
      />
    </div>
  );
}
