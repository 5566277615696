export default function ResendIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="32"
      fill="none"
      viewBox="0 0 27 32"
      className={className ?? "w-6 h-6 text-[#888]"}
    >
      <path
        fill="currentColor"
        d="M6.747 19.675v-7.998c0-.009.014-.097.04-.263l4.59 4.026-4.576 4.513a1.204 1.204 0 01-.054-.277zm.61-8.817a.57.57 0 01.23-.042h12.36c.081 0 .162.014.243.042l-4.602 4.04-.61.5-1.204 1.014-1.205-1.013-.61-.5-4.602-4.04zm.013 9.637l4.616-4.54 1.787 1.485 1.787-1.486 4.617 4.54a.635.635 0 01-.23.042H7.587a.599.599 0 01-.217-.041zm8.8-5.055l4.575-4.026c.028.083.041.17.041.263v7.998c0 .084-.013.176-.04.278L16.17 15.44z"
      ></path>
      <path
        fill="currentColor"
        d="M19.298 5.874a.54.54 0 00.25-.722L17.418.783a.54.54 0 00-.97.473L18.34 5.14 14.456 7.03a.54.54 0 10.473.971l4.37-2.128zm-14.97 16.95c-3.019-3.017-3.204-8.533-.666-12.7C4.92 8.06 6.831 6.36 9.357 5.494c2.525-.866 5.707-.913 9.529.405l.352-1.02c-4.008-1.383-7.441-1.363-10.231-.407-2.789.956-4.893 2.834-6.267 5.09-2.724 4.473-2.637 10.566.824 14.027l.764-.764zM7.206 26.171a.54.54 0 00-.275.713l1.971 4.442a.54.54 0 10.987-.438l-1.752-3.949 3.949-1.752a.54.54 0 00-.438-.987l-4.442 1.971zM22.77 9.765c2.909 3.123 2.897 8.642.212 12.716-1.33 2.018-3.3 3.65-5.856 4.425-2.554.775-5.736.708-9.508-.745l-.388 1.007c3.956 1.525 7.387 1.628 10.21.771 2.82-.856 4.99-2.657 6.444-4.863 2.882-4.373 3.012-10.466-.324-14.047l-.79.736z"
      ></path>
    </svg>
  );
}
