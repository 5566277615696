import React, { useState, useContext, useEffect } from "react";
import { chakra, FormControl } from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { useForm, FormProvider } from "react-hook-form";
import PlusIcon from "../../Assets/Icons/PlusIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import FilterIcon from "../../Assets/Icons/FilterIcon";
import { UserContext } from "../../Context/UserContext";
import TrashIcon from "../../Assets/Icons/TrashIcon";
import { useNavigate } from "react-router-dom";
import FilterButton from "../../Components/FilterButton";
import CustomButton from "../../Components/Button";
import AssignmentRequestCard from "../../Components/AssignmentRequestCard";
import { AssignmentData } from "../../Components/AssignmentCard";
import {
  GetAssignmentRequests,
  GetAssignments,
  GetAssignmentsProps,
} from "../../Utils/GET";
import toast from "react-hot-toast";
import { Loader } from "../../Components/Loader";
import NoData from "../../Components/NoData";

export default function ContractorAssignmentRequests() {
  // const [assignments,setAssignments]=useState<Assignment[]|null>(null);
  const methods = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [assignments, setAssignments] = useState<AssignmentData[]>([]);

  const getAssignments = async () => {
    setLoading(true);
    let info: GetAssignmentsProps = {
      status: "Open",
    };
    console.log(info);
    try {
      const response = await GetAssignmentRequests(info);
      if (response.status === 200) {
        console.log(response.data.data);
        setAssignments(response.data.data.assignments);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const [activeFilter, setActiveFilter] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");

  const onSubmit = async (data: any) => {
    console.log(data);
    setSearchTerm(data.searchQuery);
  };

  useEffect(() => {
    getAssignments();
  }, [activeFilter, searchTerm]);
  return (
    <div className="flex flex-1 flex-col p-4 md:p-10 items-center overflow-hidden w-full gap-6 mt-16 h-[calc(100%-50px)] bg-[#FCFCFC]">
      <div className="flex w-full flex-1 flex-col max-h-[calc(100%-0px)] overflow-hidden p-6 gap-6 bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]">
        <FormProvider {...methods}>
          <chakra.form
            className="w-full flex flex-col items-start"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {/* header  */}
            <div className="w-full flex md:flex-row flex-col justify-between">
              <span className="font-medium text-2xl md:text-4xl mt-2 text-[#001124]">
                Assignments Requests
              </span>
              <div className="flex flex-row items-center w-fit gap-5">
                <FormControl isRequired={false}>
                  <div
                    className={`group w-full h-8 sm:h-11 pl-3 pr-0.5 bg-transparent border-[1px] border-[#73B8FF33] rounded flex flex-row items-center`}
                  >
                    <input
                      title="Search"
                      {...methods.register("searchQuery")}
                      type={"text"}
                      placeholder="Search"
                      className="flex flex-1 w-[80%] md:w-[100%] text-sm placeholder:text-sm bg-transparent text-black"
                    />
                    <SearchIcon className="w-8 h-8  sm:w-11 sm:h-11" />
                  </div>
                </FormControl>
              </div>
            </div>

            {/* <div className="w-full flex flex-row items-center justify-between mt-4">
                <div className="filter-list gap-x-4 flex justify-between">
                  <FilterButton
                    buttonText="All"
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                  />
                  <FilterButton
                    buttonText="New"
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                  />
                  <FilterButton
                    buttonText="Assigned"
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                  />
                  <FilterButton
                    buttonText="Completed"
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                  />
                </div>
                <div className="h-9 w-[6.5rem] flex flex-row bg-white border border-[#55A8FD] rounded justify-center items-center gap-2 cursor-pointer">
                  <FilterIcon />
                  <span className="text-base text-[#55A8FD] font-medium">
                    Filters
                  </span>
                </div>
                <CustomButton
                  colorScheme="blue"
                  w={"w-[8rem]"}
                  // loading={loading}
                  text="Requests"
                />
              </div> */}
          </chakra.form>
        </FormProvider>

        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader className="w-40 h-40" type="square" />
          </div>
        ) : assignments.length > 0 ? (
          <div className=" overflow-y-auto scrollbar py-4 flex flex-col gap-5 px-1">
            {assignments.map((assignment, index) => (
              <div
                className="w-full h-fit shadow-[2px_2px_40px_1px_rgba(237,237,237,0.90)] py-2"
                key={index}
              >
                <AssignmentRequestCard {...assignment} />
              </div>
            ))}{" "}
          </div>
        ) : (
          <div className="w-full h-fit flex pl-[4.5rem] justify-start">
            <NoData route="" text="Assignments" />
          </div>
        )}
      </div>
    </div>
  );
}
