import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Tab, chakra, FormControl, useDisclosure } from "@chakra-ui/react";

import { createColumnHelper } from "@tanstack/react-table";
import { Table, Checkbox } from "../../Components/Table";
import MenuDots from "../../Assets/Icons/MenuDots";
import PlusIcon from "../../Assets/Icons/PlusIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import FilterIcon from "../../Assets/Icons/FilterIcon";
import { UserContext } from "../../Context/UserContext";
import TrashIcon from "../../Assets/Icons/TrashIcon";
import { useNavigate } from "react-router-dom";
import { GetAllUsers } from "../../Utils/GET";
import { DeleteCompanyAPI, DeleteUserAPI } from "../../Utils/ADD";
import { Loader } from "../../Components/Loader";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import toast from "react-hot-toast";
import ResendIcon from "../../Assets/Icons/ResendIcon";
import { InviteAPI } from "../../Utils/AUTH";
import NoData from "../../Components/NoData";
import { DropdownInput } from "../../Components/Input";

type Person = {
  _id: string;
  firstName: string;
  email: string;
  phoneNo: string;
  inviteAccepted: string;
  companyName: string;
  authorityLevel: string;
};

const personOptions = ["Add", "Update", "Delete"];

export default function Users() {
  const methods = useForm();
  const navigate = useNavigate();

  const { editUsers, setEditUsers } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);

  const [showSF, setShowSF] = useState(false);
  const filterInvite = methods.watch("filterInvite");
  const filterAuthority = methods.watch("filterAuthority");
  const [searchTerm, setSearchTerm] = useState("");

  const onSubmit = async (data: any) => {
    console.log(data);
    setSearchTerm(data.searchQuery);
  };

  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();

  const columnHelper = createColumnHelper<Person>();

  const columns = [
    {
      id: "select",
      header: ({ table }: any) => (
        <Checkbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }: any) => (
        <Checkbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },

    columnHelper.accessor("firstName", {
      header: () => (
        <span className="text-white text-base font-medium">Name</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor("email", {
      header: () => (
        <span className="text-white text-base font-medium">E-mail</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor("phoneNo", {
      header: () => (
        <span className="text-white text-base font-medium">Phone number</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor("inviteAccepted", {
      header: () => (
        <span className="text-white text-base font-medium">Invite Status</span>
      ),
      cell: (info: any) => {
        return info.getValue() ? (
          <span className={`w-full text-base text-gray-dark`}> Accepted</span>
        ) : (
          <span
            className={`w-full flex flex-row items-center gap-1 text-base text-gray-dark`}
          >
            Pending
            <div
              onClick={() => {
                console.log(info.original);
                InviteAPI(info.row.original._id);
              }}
              className="w-8 h-8"
            >
              <ResendIcon className="w-8 h-8 text-[#888] hover:text-[#55A8FD]" />
            </div>
          </span>
        );
      },
    }),
    columnHelper.accessor("companyName", {
      header: () => (
        <span className="text-white text-base font-medium">Registered By</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    // columnHelper.accessor("authorityLevel", {
    //   header: () => (
    //     <span className="text-white text-base font-medium px-2">
    //       Authority Level
    //     </span>
    //   ),
    //   cell: (info: any) => {
    //     return (
    //       <span
    //         className={`w-full min-w-[10rem] py-1 px-3 rounded-full text-base text-gray-dark ${
    //           info.getValue() === "Master Admin"
    //             ? "bg-[#FAE0DA]"
    //             : info.getValue() === "Super Admin"
    //             ? "bg-[#05AEC533]"
    //             : "bg-[#FFD64559]"
    //         }`}
    //       >
    //         {info.getValue()}
    //       </span>
    //     );
    //   },
    // }),
  ];

  const getUsers = async () => {
    setLoading(true);
    let info = {
      query: searchTerm,
      filter: {
        inviteStatus: filterInvite?.toLowerCase(),
        authorityLevel: filterAuthority,
      },
      page: 1,
      pageSize: 10,
    };
    console.log(info);
    try {
      const response = await GetAllUsers(info);
      if (response.status === 200) {
        console.log(response.data.data);
        setUserList(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (showSF === false) {
      methods.setValue("filterInvite", undefined);
      methods.setValue("filterAuthority", undefined);
    }
  }, [showSF]);

  const { confirm, setConfirm } = useContext(UserContext);

  useEffect(() => {
    console.log(editUsers);
  }, [editUsers]);

  useEffect(() => {
    setConfirm(false);
  }, []);

  const deleteUsers = async () => {
    try {
      setLoading(true);
      const response = await DeleteUserAPI({
        authority: "owner",
        ids: editUsers.map((user) => {
          return user._id;
        }),
      });
      if (response.status === 200) {
        console.log(response);
        toast.success(response.message);
        window.location.reload();
        getUsers();
        setLoading(false);
      } else {
        setLoading(false);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [filterAuthority, filterInvite, searchTerm]);

  useEffect(() => {
    if (confirm) deleteUsers();
  }, [confirm]);

  return (
    <div className="flex flex-1 flex-col p-4 md:p-10 items-center w-full gap-6 mt-16 h-[calc(100%-50px)] bg-[#FCFCFC]">
      <div className="flex w-full flex-1 flex-col max-h-[calc(100%-0px)] overflow-hidden p-4 items-center gap-6 bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]">
        <FormProvider {...methods}>
          <chakra.form
            className="w-full flex flex-col items-start"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {/* header  */}
            <div className="w-full flex flex-col lg:flex-row justify-between space-y-4">
              <span className="lg:max-w-[40%] font-medium text-lg sm:text-2xl md:text-[2rem] mt-2 text-[#001124]">
                Users
              </span>
              <div className="flex flex-col sm:flex-row  items-start sm:items-center gap-5">
                <FormControl isRequired={false}>
                  <div
                    className={`group w-full md:min-w-[10rem] sm:min-w-[20rem] h-11 pl-3 pr-0.5 bg-transparent border-[1px] border-[#73B8FF33] rounded flex flex-row items-center`}
                  >
                    <input
                      title="Search"
                      {...methods.register("searchQuery")}
                      type={"text"}
                      placeholder="Search"
                      className="flex flex-1 text-sm placeholder:text-sm bg-transparent text-black"
                    />
                    <SearchIcon
                      className="w-11 h-11"
                      onClick={() => methods.handleSubmit(onSubmit)()}
                    />
                  </div>
                </FormControl>
                {editUsers.length > 0 && (
                  <div
                    className={`h-11 w-full md:min-w-[10rem] px-2 flex flex-row ${
                      editUsers.length > 0 ? "bg-[#CA2524]" : "bg-[#55A8FD]"
                    } rounded justify-center items-center gap-2 cursor-pointer`}
                    onClick={() => {
                      editUsers.length === 0
                        ? navigate("/users/add-user")
                        : onOpenDeleteModal();
                    }}
                  >
                    {editUsers.length > 0 ? <TrashIcon /> : <PlusIcon />}
                    <span className="text-xl text-white font-medium">
                      {editUsers.length > 0 ? "Delete User" : "Add User"}
                    </span>
                    {editUsers.length > 0 && loading && (
                      <div className="h-6 w-6">
                        <Loader type="circle" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="w-full flex flex-col items-start sm:flex-row gap-5  sm:items-center justify-start lg:justify-end mt-4">
              {showSF && (
                <div className="w-full md:max-w-[255px]   flex flex-col sm:flex-row items-center gap-5 z-30">
                  <DropdownInput
                    options={["Accepted", "Pending"]}
                    type="text"
                    name="filterInvite"
                    label=""
                    placeholder="Invite Status"
                    isRequired={false}
                    showLabels={false}
                    search={false}
                  />
                  {/* <DropdownInput
                    options={["Admin", "Master Admin", "Super Admin", "User"]}
                    type="text"
                    name="filterAuthority"
                    label=""
                    placeholder="Authority Level"
                    isRequired={false}
                    showLabels={false}
                    search={false}
                  /> */}
                </div>
              )}
              <div
                onClick={() => {
                  setShowSF(!showSF);
                }}
                className="h-9 w-full lg:w-[6.5rem] flex flex-row bg-white border border-[#55A8FD] rounded justify-center items-center gap-2 cursor-pointer"
              >
                <FilterIcon />
                <span className="text-base text-[#55A8FD] font-medium">
                  {showSF ? "Reset" : "Filters"}
                </span>
              </div>
            </div>
          </chakra.form>
        </FormProvider>
        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader className="w-40 h-40" type="square" />
          </div>
        ) : userList.length > 0 ? (
          <Table tableData={userList} columns={columns} />
        ) : (
          <div className="w-full h-fit flex pl-[4.5rem] justify-start">
            <NoData route="" text="Users" />
          </div>
        )}
      </div>
      <ConfirmModal
        isOpen={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        title={"Delete Users"}
        text={"Click on continue to delete selected users."}
      />
    </div>
  );
}
