export default function Facebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#1877F2"
        d="M25.5 0h-21A4.5 4.5 0 000 4.5v21A4.5 4.5 0 004.5 30h21a4.5 4.5 0 004.5-4.5v-21A4.5 4.5 0 0025.5 0z"
      ></path>
      <path
        fill="#fff"
        d="M20.835 19.336L21.503 15h-4.16v-2.813c0-1.183.58-2.343 2.443-2.343h1.893V6.152s-1.717-.293-3.358-.293c-3.427 0-5.666 2.075-5.666 5.836V15H8.847v4.336h3.808v10.663h4.688V19.336h3.492z"
      ></path>
    </svg>
  );
}
