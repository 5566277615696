import toast from "react-hot-toast";
import axios from "../Helpers/Axios";

interface LoginProps {
  email: string;
  password: string;
}

interface UserResponse {
  _id: string;
  firstName: string;
  lastName: string;
  inviteAccepted: boolean;
  authorityLevel: string;
  companyType: string;
  companyName: string;
  companyId: string;
  phoneNoVerified: boolean;
  phoneNo: string;
  email: string;
}

interface LoginResponse {
  status: number;
  data?: UserResponse;
  message: string;
}

/* 
  Login API
  body - LoginProps
  response - LoginResponse
*/

export const LogInAPI = async (
  credentials: LoginProps
): Promise<LoginResponse> => {
  let responseData: LoginResponse;

  try {
    const response = await axios.post(`/api/auth/login`, credentials);
    console.log(response);

    responseData = { status: response?.status, ...response?.data };
    return responseData;

  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Update Password API
  body - UpdatePasswordProps
  response - UpdatePasswordResponse
*/

interface UpdatePasswordProps {
  oldPassword: string;
  newPassword: string;
}

interface UpdatePasswordResponse {
  status: number;
  user?: UserResponse;
  message: string;
}

export const UpdatePasswordAPI = async (
  credentials: UpdatePasswordProps
): Promise<UpdatePasswordResponse> => {
  let responseData: UpdatePasswordResponse;

  try {
    const response = await axios.post(`/api/auth/update-password`, credentials);
    console.log(response);
    responseData = { status: response?.status, user: response.data.data, message: response?.data?.message };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Request OTP API
  body - RequestOTPProps
  response - RequestOTPResponse
*/

interface RequestOTPProps {
  email?: string;
  phoneNo?: string
}

interface RequestOTPResponse {
  status: number;
  message: string;
}

export const RequestOtpAPI = async (
  credentials: RequestOTPProps
): Promise<RequestOTPResponse> => {
  let responseData: RequestOTPResponse;

  try {
    console.log(credentials)
    const response = await axios.post(`/api/auth/send-otp`, credentials);

    responseData = { status: response?.status, message: response?.data?.message };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Verify OTP API
  body - VerifyOTPProps
  response - VerifyOTPResponse
*/

interface VerifyOTPProps {
  verificationCode: string;
  email: string;
}

interface VerifyOTPResponse {
  status: number;
  message: string;
}

export const VerifyOtpAPI = async (
  credentials: VerifyOTPProps
): Promise<VerifyOTPResponse> => {
  let responseData: VerifyOTPResponse;

  try {
    const response = await axios.post(`/api/auth/verify-otp`, credentials);

    responseData = { status: response?.status, message: response?.data?.message };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Verify Phone
  body - VerifyPhoneProps
  response - VerifyOTPResponse
*/

interface VerifyPhoneProps {
  verificationCode: string;
  phoneNo: string;
}

interface VerifyPhoneResponse {
  status: number;
  message: string;
}

export const VerifyPhone = async (
  credentials: VerifyPhoneProps
): Promise<VerifyPhoneResponse> => {
  let responseData: VerifyPhoneResponse;

  try {
    const response = await axios.post(`/api/auth/verify-phone`, credentials);

    responseData = { status: response?.status, message: response?.data?.message };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Reset Password API
  body - ResetPasswordProps
  response - ResetPasswordResponse
*/

interface ResetPasswordProps {
  newPassword: string;
}

interface ResetPasswordResponse {
  status: number;
  message: string;
}

export const ResetPasswordAPI = async (
  credentials: ResetPasswordProps
): Promise<ResetPasswordResponse> => {
  let responseData: ResetPasswordResponse;

  try {
    const response = await axios.post(`/api/auth/reset-password`, credentials);

    responseData = { status: response?.status, message: response?.data?.message };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      message: error?.response?.data?.message ?? "Please try again.",
    };
    return responseData;
  }
};

/* 
  Get User Details
  body - RequestOTPProps
  response - RequestOTPResponse
*/

interface UserProps {
  authorityLevel: string;
  companyType: string;
  email: string;
  firstName: string;
  inviteAccepted: boolean;
  lastName: string;
  phoneNo: string;
  phoneNoVerified: boolean;
  _id: string
  companyName: string;
  companyId: string;
  notificationPref?:string;
  title?:string;
}

interface GetUserResponse {
  status: number;
  user: UserProps;
  message: string;
}

export const GetUser = async (
  userId?: string
): Promise<GetUserResponse> => {
  let responseData: GetUserResponse;

  try {
    let response;

    if(userId)
      response = await axios.get(`/api/account/get-user?userId=${userId}`);
    else
      response=await axios.get(`/api/account/get-user`);
    
    console.log(response)
    responseData = { status: response?.status, user: response?.data?.data, message: "success" };
    return responseData;
  } catch (error: any) {
    console.error(error);
    responseData = {
      status: error?.response?.status ?? 500,
      user: {} as UserProps,
      message: "Error"
    };
    return responseData;
  }
};

/* 
  Invite API
  body - LoginProps
  response - LoginResponse
*/

interface InviteProps {
  _id: string;
}

interface InviteResponse {
  status: number;
  message: string;
}

export const InviteAPI = async (
  credentials: InviteProps
) => {
  console.log(credentials)
  let responseData: InviteResponse;

  try {
    const response = await axios.get(`/api/auth/resend-invite?id=${credentials}`);
    console.log(response);
    if (response.status === 200) toast.success("Invite sent.")
    else toast.error("Something went wrong.")

  } catch (error: any) {
    console.error(error);
    toast.error(error?.response?.data?.message ?? "Please try again.",)
  }
};
