import { useState, useContext, useEffect } from "react";
import {
  CustomInput,
  CustomPhoneInput,
  DropdownInput,
} from "../../Components/Input";
import CustomButton from "../../Components/Button";
import { useForm, FormProvider } from "react-hook-form";
import { chakra, useDisclosure } from "@chakra-ui/react";

import {
  AddCommunityUserAPI,
  AddCommunityUserProps,
  AddUserAPI,
  AddUserProps,
} from "../../Utils/ADD";
import { UserContext } from "../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import toast from "react-hot-toast";

export default function AddCommunityUser() {
  const methods = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<AddCommunityUserProps>(
    {} as AddCommunityUserProps
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { confirm, setConfirm, communityId } = useContext(UserContext);

  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();

  const onSubmit = async (data: any) => {
    console.log(data);
    // onOpen();
    let info = {
      ...data,
      phoneNo: "+" + data.phoneNo,
      notificationPref: data.notificationPref.toLowerCase(),
      // title: "designation",
      communityId: communityId,
    };
    console.log(info);
    setUserData(info);
    onOpenAddModal();
  };

  const addUser = async () => {
    setLoading(true);
    try {
      const response = await AddCommunityUserAPI(userData);
      if (response.status === 201) {
        console.log(response);
        setLoading(false);
        setConfirm(false);
        navigate("/communities");
      } else {
        setLoading(false);
        setConfirm(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.message || "Something went wrong");
      console.error(error);
    }
  };

  useEffect(() => {
    if (confirm) addUser();
  }, [confirm]);

  return (
    <div className="flex flex-1 flex-col p-10 items-center w-full h-40 mt-16 min-h-full bg-[#FCFCFC]">
      <FormProvider {...methods}>
        <chakra.form
          autoComplete="off"
          className="w-full flex flex-col items-end gap-9"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {/* master admin box  */}
          <div className="w-full bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke  rounded-[8px]">
            <div className="w-full py-2.5 gap-8 border-b-[0.4px] border-[#D3D3D3] border-opacity-30 relative flex items-center">
              <div className="w-1 h-9 rounded-full bg-[#061229] shadow-[1px_1.5px_2px_0px_#0A285E]" />
              <span className="font-semibold text-2xl text-[#061229]">
                Read-only user details
              </span>
            </div>
            <div className="w-full px-9 pt-6 pb-2 grid grid-cols-3 gap-x-6 gap-y-0.5">
              <CustomInput
                type="text"
                name="firstName"
                label="First Name"
                placeholder="First name"
                isRequired={true}
              />
              {/* <CustomInput
                type="text"
                name="middleName"
                label="Middle Name"
                placeholder="Middle name"
                isRequired={false}
              /> */}
              <CustomInput
                type="text"
                name="lastName"
                label="Last Name"
                placeholder="Last name"
                isRequired={true}
              />
              <CustomPhoneInput
                type="number"
                name="phoneNo"
                label="Phone Number"
                placeholder="Phone Number"
                isRequired={true}
              />
              <CustomInput
                type="email"
                name="email"
                label="Email Address"
                placeholder="Email Address"
                isRequired={true}
              />
              <DropdownInput
                options={["Email", "Phone", "Both"]}
                type="text"
                name="notificationPref"
                label="Notification preference"
                placeholder="-Select-"
                isRequired={true}
              />
            </div>
          </div>
          <CustomButton
            colorScheme="blue"
            w={"w-40"}
            loading={loading}
            text="Add"
          />
        </chakra.form>
      </FormProvider>
      <ConfirmModal
        isOpen={isOpenAddModal}
        onClose={onCloseAddModal}
        title="Add New User"
        text="Click on continue to add the user to your dashboard."
      />
    </div>
  );
}
