export default function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="url(#paint0_linear_335_1904)"
        d="M14 7A7 7 0 110 7a7 7 0 0114 0zm-7.81 3.706l5.194-5.193a.452.452 0 000-.639l-.639-.638a.452.452 0 00-.638 0L5.87 8.47 3.893 6.494a.452.452 0 00-.638 0l-.639.638a.452.452 0 000 .639l2.936 2.935a.452.452 0 00.638 0z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_335_1904"
          x1="6.298"
          x2="4.878"
          y1="-15.87"
          y2="16.637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A285E"></stop>
          <stop offset="0.139" stopColor="#30BC93"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
