import logo from "../Assets/temp_avatar.png";
import DefaultProfilePic from "../Assets/DefaultProfilePic.png";
import BellIcon from "../Assets/Icons/Bell";
import { useContext } from "react";
import { UserContext } from "../Context/UserContext";
import { useNavigate } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";

export default function Navbar() {
  const { user, setOpenSidebar } = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <div className=" w-full h-16 bg-white border-b-[0.4px] border-[#D3D3D3] absolute top-0 right-0 flex flex-row justify-between  items-center lg:justify-end gap-2 lg:pl-64  pr-4 pb-2 ">
      {/* <div className="mr-6">
        <BellIcon />
      </div> */}
      <div className=" lg:hidden pl-2 flex w-fit items-center ">
        <HamburgerIcon
          height={7}
          width={7}
          onClick={() => {
            setOpenSidebar(true);
          }}
        />
      </div>

      <div className=" flex justify-between gap-2 items-center  w-fit">
        <img
          src={DefaultProfilePic}
          alt="ProCam Tasks"
          className="cursor-pointer w-11 h-11 rounded-full"
          onClick={() => {
            setOpenSidebar(true);
          }}
        />
        <div className="  flex flex-col h-full justify-around  items-start w-full">
            <div className="w-full flex flex-row justify-between items-center ">
              <span className="text-black text-xs">
                {user.name}
              </span>
            </div>
            <span className="text-black text-xs w-full">
                {user.authority}
              </span>
          
            <span className=" text-black text-xs">
              {user.companyName
                ? user.companyName
                : "Merit Management"}
            </span>
        </div>
      </div>
    </div>
  );
}
