import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import AddManagementCompany from "../../Components/Forms/AddManagementCompany";
import IndividualManager from "../../Components/Forms/IndividualManager";
import PrimaryAdmin from "../../Components/Forms/PrimaryAdmin";
import AddUser from "../../Components/Forms/AddUser";
import Users from "./Users";
import MaintenanceContractors from "./MaintenanceContractors";
import MaintenanceContractorUsers from "./MaintenanceContractorUsers";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../../Context/UserContext";
import VerifyPhoneModal from "../../Components/Modals/VerifyPhoneModal";

import { chakra, useDisclosure } from "@chakra-ui/react";
import { GetUser } from "../../Utils/AUTH";
import ManagementAssignments from "../Assignment/ManagementAssignments";
import CreateAssignment from "../Assignment/CreateAssignment/CreateAssignment";
import MapLocation from "../../Components/MapLocation";
import ClientCommunities from "./Communities";
import AddCommunity from "./AddCommunity";
import AddCommunityUser from "./AddCommunityUser";
import Diary from "../OwnerDashboard/Diary";
import Reports from "./Reports";

export default function ManagementDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const { addUser, verified, user, setUser, openSidebar } =
    useContext(UserContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (
      !(localStorage.getItem("verified") === "true" ? true : false) &&
      user.phone.length > 0
    )
      onOpen();
  }, [verified, user]);

  useEffect(() => {
    if (location.pathname.includes("dashboard")) navigate("/users");
  }, [location.pathname]);

  return (
    <div
      className={`${
        openSidebar ? "lg:pl-[280px] 2xl:pl-[20%]" : "pl-0"
      } w-full h-[100vh] flex flex-row font-inter bg-[#FCFCFC] relative`}
    >
      <Navbar />
      <Sidebar />
      {location.pathname === "/users/add-user" && <AddUser />}
      {location.pathname ===
        "/maintenance-contractors/add-maintenance-company" && <PrimaryAdmin />}
      {location.pathname === "/maintenance-contractor-users/add-user" && (
        <AddUser />
      )}

      {location.pathname.includes("assignment") &&
        !location.pathname.includes("edit") &&
        location.pathname.split("/").length === 3 && <Diary />}

      {location.pathname === "/users" && <Users />}
      {location.pathname === "/assignment" && <ManagementAssignments />}
      {location.pathname === "/create-assignment" && <CreateAssignment />}
      {location.pathname.includes("/edit-assignment") && (
        <CreateAssignment editState={true} />
      )}
      {location.pathname.includes("/management/update-user") && <AddUser />}
      {location.pathname === "/map-assignment" && <MapLocation />}
      {location.pathname.includes("/maintenance-contractors") &&
        !location.pathname.includes("add-maintenance") && (
          <MaintenanceContractors />
        )}
      {location.pathname.includes("/communities") &&
        !location.pathname.includes("add-community") &&
        !location.pathname.includes("add-user") && <ClientCommunities />}
      {location.pathname === "/communities/add-community" && <AddCommunity />}
      {location.pathname === "/communities/add-user" && <AddCommunityUser />}

      {location.pathname === "/reports" && <Reports />}
      {/* <VerifyPhoneModal isOpen={isOpen} onClose={onClose} phone={user.phone} /> */}
    </div>
  );
}
