import { useState, useContext, useEffect } from "react";
import { CustomInput, CustomPhoneInput, DropdownInput } from "../Input";
import CustomButton from "../Button";
import { useForm, FormProvider } from "react-hook-form";
import { chakra, useDisclosure } from "@chakra-ui/react";
import VerifyPhoneModal from "../Modals/VerifyPhoneModal";
import AddCompanyModal from "../Modals/ConfirmModal";
import { AddUserAPI, AddUserProps, UpdateUserAPI } from "../../Utils/ADD";
import { UserContext } from "../../Context/UserContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmModal from "../Modals/ConfirmModal";
import toast from "react-hot-toast";
import { GetUser } from "../../Utils/AUTH";

export default function AddUser() {
  const methods = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<AddUserProps>({} as AddUserProps);
  const { id, userId } = useParams();
  // const { prevUrl } = location.state;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { confirm, setConfirm } = useContext(UserContext);
  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();

  const onSubmit = async (data: any) => {
    // console.log("update", data);
    onOpen();
    let info = {
      ...data,
      // phoneNo: "+" + data.phoneNo,
      notificationPref: data.notificationPref.toLowerCase(),
      company: id ? id : undefined,
      companyType: id
        ? location.pathname.includes("/management")
          ? "Management"
          : "Maintenance"
        : undefined,
    };
    console.log(info);
    setUserData(info);
    onOpenAddModal();
  };

  const addUser = async () => {
    setLoading(true);
    try {
      const response = await AddUserAPI(userData);
      if (response.status === 201) {
        console.log(response);
        setLoading(false);
        setConfirm(false);
        // console.log("prevUrl", prevUrl);
        navigate(
          location?.state?.prevUrl ? location?.state?.prevUrl : "/users"
        );
      } else {
        setLoading(false);
        setConfirm(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.message || "Something went wrong");
      console.error(error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await GetUser(userId);
      if (response?.status === 200) {
        console.log("getuserdata", response);
        methods.setValue("firstName", response?.user?.firstName);
        methods.setValue("lastName", response?.user?.lastName);
        methods.setValue("phoneNo", response?.user?.phoneNo);
        methods.setValue("email", response?.user?.email);
        methods.setValue("notificationPref", response?.user?.notificationPref);
        methods.setValue("authorityLevel", response?.user?.authorityLevel);
        methods.setValue(
          "title",
          response?.user?.title ? response?.user?.title : ""
        );
        //set fields of the form
      } else {
        toast.error(response.message);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const updateUser = async () => {
    try {
      const response = await UpdateUserAPI(userId, userData);

      if (response?.status === 200) {
        console.log("update", response);
        setLoading(false);
        setConfirm(false);
        navigate(
          location?.state?.prevUrl ? location?.state?.prevUrl : "/users"
        );
      } else {
        toast.error(response.message);
        setLoading(false);
        setConfirm(false);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (confirm && userId) {
      //update user api call
      updateUser();
    } else if (confirm) addUser();
  }, [confirm]);

  useEffect(() => {
    if (userId) {
      //fetch user data
      fetchUserData();
    }
  }, [userId]);

  return (
    <div className="flex flex-1 flex-col p-4 md:p-10 items-center w-full h-40 mt-16 min-h-full bg-[#FCFCFC]">
      {location?.state?.tableTitle && (
        <div className="w-[100%] font-medium text-lg sm:text-2xl md:text-[2rem] mt-2  pb-4 text-[#001124] text-left">
          {location?.state?.tableTitle}
        </div>
      )}
      <FormProvider {...methods}>
        <chakra.form
          autoComplete="off"
          className="w-full flex flex-col items-end gap-9 h-[90%] md:h-full overflow-y-scroll md:overflow-visible"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {/* master admin box  */}
          <div className="w-full bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke  rounded-[8px]">
            <div className="w-full py-2.5 gap-8 border-b-[0.4px] border-[#D3D3D3] border-opacity-30 relative flex items-center">
              <div className="w-1 h-9 rounded-full bg-[#061229] shadow-[1px_1.5px_2px_0px_#0A285E]" />
              <span className="font-semibold text-2xl text-[#061229]">
                User Details
              </span>
            </div>
            <div className="w-full px-4 md:px-9 pt-6 pb-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-0.5">
              <CustomInput
                type="text"
                name="firstName"
                label="First Name"
                placeholder="Admin First name"
                isRequired={true}
              />
              {/* <CustomInput
                type="text"
                name="middleName"
                label="Middle Name"
                placeholder="Admin Middle name"
                isRequired={false}
              /> */}
              <CustomInput
                type="text"
                name="lastName"
                label="Last Name"
                placeholder="Admin Last name"
                isRequired={true}
              />

              <CustomPhoneInput
                type="number"
                name="phoneNo"
                label="Admin Phone Number"
                placeholder="Phone Number"
                isRequired={true}
              />
              <CustomInput
                type="email"
                name="email"
                label="Admin Email Address"
                placeholder="Admin Email Address"
                isRequired={true}
              />
              <DropdownInput
                options={["Email", "Phone", "Both"]}
                type="text"
                name="notificationPref"
                label="Notification preference"
                placeholder="-Select-"
                isRequired={true}
              />

              <DropdownInput
                options={["Master Admin", "Super Admin", "Admin"]}
                type="text"
                name="authorityLevel"
                label="Authority Level"
                placeholder="-Select-"
                isRequired={true}
              />

              <CustomInput
                type="text"
                name="title"
                label="Designation"
                placeholder="Eg. CEO, Manager, etc"
                isRequired={false}
              />
            </div>
          </div>
          <CustomButton
            colorScheme="blue"
            w={"w-40"}
            loading={loading}
            text={userId ? "Save" : "Add"}
          />
        </chakra.form>
      </FormProvider>
      <ConfirmModal
        isOpen={isOpenAddModal}
        onClose={onCloseAddModal}
        title={userId ? "Update user details" : "Add New User"}
        text={
          userId
            ? "Click on continue to update user details."
            : "Click on continue to add the user to your dashboard."
        }
      />
    </div>
  );
}
