import logo from "../Assets/logo.png";
import { HStack, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "./Button";
import { HamburgerIcon } from "@chakra-ui/icons";
import BaseSidebar from "./BaseSidebar";

export default function Header() {
  const [page, setPage] = useState("w-5");
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-row items-center justify-between text-white px-4 sm:px-12 py-5 bg-[#0A285E]">
      <HStack gap={28}>
        <img
          onClick={() => {
            navigate("/");
          }}
          src={logo}
          alt="Procam Tasks"
          className="cursor-pointer w-40"
        />
        <VStack className="hidden xl:flex" alignItems="start" gap={4}>
          <HStack className="text-lg" gap={16}>
            <span
              onClick={() => setPage("w-5")}
              className={`${
                page.includes("-5") ? "text-green-primary" : ""
              } cursor-pointer transition-all`}
            >
              Home
            </span>
            <span
              onClick={() => setPage("w-[35%]")}
              className={`${
                page.includes("35") ? "text-green-primary" : ""
              } cursor-pointer transition-all`}
            >
              Queries
            </span>
            <span
              onClick={() => setPage("w-[67%]")}
              className={`${
                page.includes("67") ? "text-green-primary" : ""
              } cursor-pointer transition-all`}
            >
              Pricing
            </span>
            <span
              onClick={() => setPage("w-full")}
              className={`${
                page.includes("full") ? "text-green-primary" : ""
              } cursor-pointer transition-all`}
            >
              About Us
            </span>
          </HStack>

          <div className="relative h-[3px] ml-4 w-[24rem] bg-white rounded-full">
            <div
              className={`h-[3px] ${page} transition-width duration-[500ms] ease-custom flex items-center justify-end bg-green-primary rounded-full`}
            >
              <div className="w-5 h-5 bg-white border border-green-primary rounded-full"></div>
            </div>
          </div>
        </VStack>
      </HStack>
      <div className="flex flex-row items-center gap-5">
        <button
          onClick={() => {
            navigate("/create-maintenance-contractor");
          }}
          className="hidden lg:flex px-7 py-1.5 bg-[#ACE3DF] text-xl text-[#1D1D1D] rounded-lg transition-all"
        >
          Signup as Maintenance Contractor
        </button>
        <button
          onClick={() => {
            navigate("/login");
          }}
          className="hidden lg:flex px-7 py-1.5 border border-green-primary bg-transparent hover:bg-green-primary text-white text-xl hover:text-[#0A285E] rounded-lg transition-all"
        >
          Login
        </button>
        <div
          onClick={() => {
            setOpenSidebar(!openSidebar);
          }}
          className="flex xl:hidden"
        >
          <HamburgerIcon w={35} h={35} />
        </div>
      </div>
      <BaseSidebar open={openSidebar} setOpenSidebar={setOpenSidebar} />
    </div>
  );
}
