export default function UsersIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      fill="none"
      viewBox="0 0 22 18"
      className={className}
    >
      <path
        fill="currentColor"
        d="M13.173 3.09a3.895 3.895 0 011.775 2.887c.408.195.852.296 1.302.297 1.701 0 3.08-1.405 3.08-3.137C19.33 1.404 17.95 0 16.25 0c-1.685 0-3.052 1.38-3.077 3.09zm-2.018 6.422c1.701 0 3.08-1.404 3.08-3.137 0-1.732-1.38-3.136-3.08-3.136-1.701 0-3.08 1.404-3.08 3.137 0 1.732 1.379 3.136 3.08 3.136zm1.306.214H9.848c-2.175 0-3.944 1.802-3.944 4.017v3.255l.009.051.22.07c2.075.66 3.878.881 5.362.881 2.898 0 4.578-.842 4.681-.895l.206-.106h.022v-3.256c0-2.215-1.768-4.017-3.943-4.017zm5.096-3.238h-2.593a3.899 3.899 0 01-1.172 2.693c1.933.585 3.347 2.41 3.347 4.567v1.003c2.56-.096 4.036-.835 4.133-.884l.206-.107h.022v-3.256c0-2.214-1.769-4.016-3.943-4.016zM5.75 6.274c.602 0 1.163-.179 1.638-.484A3.897 3.897 0 018.82 3.314c.004-.059.009-.117.009-.176C8.83 1.405 7.451 0 5.75 0c-1.7 0-3.08 1.404-3.08 3.137 0 1.732 1.38 3.136 3.08 3.136zm2.766 2.907a3.901 3.901 0 01-1.171-2.678c-.096-.007-.191-.015-.29-.015H4.444C2.27 6.488.5 8.29.5 10.504v3.256l.008.05.22.07c1.665.53 3.15.774 4.441.85v-.982c0-2.156 1.414-3.982 3.348-4.567z"
      ></path>
    </svg>
  );
}
