import React from "react";

export default function AssignmentStatusTag({ text }: { text: string }) {
  var bgColor;
  switch (text) {
    case "Declined":
      bgColor = "#e9887bff";
      break;
    case "In-Progress":
      bgColor = "#FACDC7";
      break;
    case "Open":
      bgColor = "#58B7BD";
      break;
    case "Completed":
      bgColor = "#A8C3EA";
      break;
    default:
      break;
  }
  return (
    <span
      className="h-[1.75rem] rounded-[2px] px-3 py-1 text-base shadow-lg"
      style={{ backgroundColor: bgColor }}
    >
      {text === "Open" ? "New" : text}
    </span>
  );
}
