import { useContext, useEffect, useState } from "react";
import { CustomInput, CustomPhoneInput, DropdownInput } from "../Input";
import CustomButton from "../Button";
import { useForm, FormProvider } from "react-hook-form";
import { chakra, useDisclosure } from "@chakra-ui/react";
import {
  AddIndividualManagerAPI,
  AddIndividualManagerProps,
} from "../../Utils/ADD";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../Modals/ConfirmModal";
import { UserContext } from "../../Context/UserContext";
import toast from "react-hot-toast";

export default function IndividualManager() {
  const methods = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [managerData, setManagerData] = useState<AddIndividualManagerProps>(
    {} as AddIndividualManagerProps
  );

  const { confirm, setConfirm } = useContext(UserContext);

  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();

  const onSubmit = async (data: any) => {
    console.log(data);
    let info = {
      ...data,
      masterAdminPhoneNo: "+" + data.masterAdminPhoneNo,
      masterAdminNotificationPref:
        data.masterAdminNotificationPref.toLowerCase(),
    };
    console.log(info);
    setManagerData(info);
    onOpenAddModal();
  };

  const addIndividualManager = async () => {
    setLoading(true);
    try {
      const response = await AddIndividualManagerAPI(managerData);
      if (response.status === 201) {
        console.log(response);
        navigate("/individual-managers");
        setLoading(false);
        setConfirm(false);
      } else {
        setLoading(false);
        toast.error(response.message);
        setConfirm(false);
        console.error(response);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.message || "Something went wrong");
      console.error(error);
    }
  };

  useEffect(() => {
    if (confirm) addIndividualManager();
  }, [confirm]);

  return (
    <div className="flex flex-1 flex-col p-4 md:p-10 items-center w-full h-40 mt-16 min-h-full bg-[#FCFCFC]">
      <FormProvider {...methods}>
        <chakra.form
          autoComplete="off"
          className="w-full flex flex-col items-end gap-9 h-[90%] md:h-full overflow-y-scroll md:overflow-visible"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {/* header box   */}
          <div className="w-full bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke  rounded-[8px]">
            <div className="w-full py-2.5 gap-8 border-b-[0.4px] border-[#D3D3D3] border-opacity-30 relative flex items-center">
              <div className="w-1 h-9 rounded-full bg-[#061229] shadow-[1px_1.5px_2px_0px_#0A285E]" />
              <span className="font-semibold text-2xl text-[#061229]">
                Individual Manager Details
              </span>
            </div>
            <div className="w-full px-4 md:px-9 pt-6 pb-2 grid grid-rows-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-0.5">
              <CustomInput
                type="text"
                name="masterAdminFirstName"
                label="First Name"
                placeholder="Admin First name"
                isRequired={true}
              />
              {/* <CustomInput
                type="text"
                name="masterAdminMiddleName"
                label="Middle Name"
                placeholder="Admin Middle name"
                isRequired={false}
              /> */}
              <CustomInput
                type="text"
                name="masterAdminLastName"
                label="Last Name"
                placeholder="Admin Last name"
                isRequired={true}
              />

              <CustomPhoneInput
                type="number"
                name="masterAdminPhoneNo"
                label="Admin Phone Number"
                placeholder="Phone Number"
                isRequired={true}
              />
              <CustomInput
                type="email"
                name="masterAdminEmail"
                label="Admin Email Address"
                placeholder="Admin Email Address"
                isRequired={true}
              />
              <DropdownInput
                options={["Email", "Phone", "Both"]}
                type="text"
                name="masterAdminNotificationPref"
                label="Notification preference"
                placeholder="-Select-"
                isRequired={true}
              />

              <CustomInput
                type="text"
                name="masterAdminTitle"
                label="Designation"
                placeholder="Eg. CEO, Manager, etc"
                isRequired={false}
              />
            </div>
          </div>

          <CustomButton
            colorScheme="blue"
            w={"w-40"}
            loading={loading}
            text="Add"
          />
        </chakra.form>
      </FormProvider>
      <ConfirmModal
        isOpen={isOpenAddModal}
        onClose={onCloseAddModal}
        title="Add Management Company"
        text="Click on continue to add the Individual Manager."
      />
    </div>
  );
}
