export default function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#000"
        d="M25.5 0h-21A4.5 4.5 0 000 4.5v21A4.5 4.5 0 004.5 30h21a4.5 4.5 0 004.5-4.5v-21A4.5 4.5 0 0025.5 0z"
      ></path>
      <path
        fill="url(#paint0_radial_487_2334)"
        d="M25.5 0h-21A4.5 4.5 0 000 4.5v21A4.5 4.5 0 004.5 30h21a4.5 4.5 0 004.5-4.5v-21A4.5 4.5 0 0025.5 0z"
      ></path>
      <path
        fill="url(#paint1_linear_487_2334)"
        d="M25.5 0h-21A4.5 4.5 0 000 4.5v21A4.5 4.5 0 004.5 30h21a4.5 4.5 0 004.5-4.5v-21A4.5 4.5 0 0025.5 0z"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="1.677"
        d="M19.278 5.975h-8.554a4.746 4.746 0 00-4.746 4.746v8.554a4.746 4.746 0 004.746 4.747h8.554a4.746 4.746 0 004.746-4.747v-8.554a4.746 4.746 0 00-4.746-4.746z"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="1.677"
        d="M15.001 19.217a4.219 4.219 0 100-8.438 4.219 4.219 0 000 8.438zM20.334 10.018a.352.352 0 100-.704.352.352 0 000 .704z"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_487_2334"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(30 0 0 30 12 30)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1" stopColor="#FD5"></stop>
          <stop offset="0.5" stopColor="#FF543E"></stop>
          <stop offset="1" stopColor="#C837AB"></stop>
        </radialGradient>
        <linearGradient
          id="paint1_linear_487_2334"
          x1="0"
          x2="6"
          y1="0"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1" stopColor="#3771C8"></stop>
          <stop offset="0.5" stopColor="#60F" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
