import React, { useState, useContext, useEffect } from "react";
import { chakra, FormControl } from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import AssignmentCard, {
  AssignmentData,
} from "../../Components/AssignmentCard";
import { useForm, FormProvider } from "react-hook-form";
import PlusIcon from "../../Assets/Icons/PlusIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import FilterIcon from "../../Assets/Icons/FilterIcon";
import { UserContext } from "../../Context/UserContext";
import TrashIcon from "../../Assets/Icons/TrashIcon";
import { useLocation, useNavigate } from "react-router-dom";
import FilterButton from "../../Components/FilterButton";
import CustomButton from "../../Components/Button";
import {
  GetAssignmentRequests,
  GetAssignments,
  GetAssignmentsProps,
  GetCommunityAssignment,
  GetCompanyFilterList,
} from "../../Utils/GET";
import toast from "react-hot-toast";
import NoData from "../../Components/NoData";
import { Loader } from "../../Components/Loader";
import MultiSelectDropdown from "./CreateAssignment/partials/MultiSelectDropdown";
import { Pagination } from "flowbite-react";

export default function Assignments() {
  const methods = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [assignments, setAssignments] = useState<AssignmentData[]>([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [allAssignments, setAllAssignments] = useState<AssignmentData[]>([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(
    location?.state?.company ? [location?.state?.company] : []
  );
  const [managementCompanies, setManagementCompanies] = useState<
    { label: string; value: string }[]
  >([]);
  const { user } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);


  const onPageChange = (page: number) => setCurrentPage(page);
  const defaultValues = location?.state?.company
    ? [location?.state?.company]
    : [];

  const [totalCount, setTotalCount] = useState({
    all: 0,
    open: 0,
    inProgress: 0,
    completed: 0,
  });
  const setAssignmentsAfterFilter = (data: any) => {
    setAssignments(data);
    setAllAssignments(data);
    // setActiveFilter("All");
  };
  const managementCompanyFilterSubmit = async (
    companyFilter: string[],
    searchTerm: string
  ) => {
    console.log("Running 57");
    getAssignments(
      page,
      activeFilter,
      setAssignmentsAfterFilter,
      companyFilter,
      searchTerm
    );
  };
  const getManagementCompanies = async () => {
    try {
      const response = await GetCompanyFilterList();
      console.log("companyList", response);
      const companyList = response.data.data[0].managementCompanyList.map(
        (company: any) => {
          return { label: company.companyName, value: company._id };
        }
      );

      setManagementCompanies(companyList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getManagementCompanies();
  }, []);

  const getAssignments = async (
    pageNo: number,
    status: string,
    setterFunction: React.Dispatch<React.SetStateAction<AssignmentData[]>>,
    companyFilter?: string[],
    search?: string
  ) => {
    setLoading(true);
    let info: GetAssignmentsProps = {
      status: activeFilter,
      query: search,
      page: pageNo,
    };
    setTotalPage(page)
    setCurrentPage(pageNo)
      if (
      (companyFilter && companyFilter.length > 0) ||
      (filter && filter.length > 0)
    ) {
      info = {
        ...info,
        filter: { managementCompany: companyFilter ? companyFilter : filter },
      };
    }

    console.log("queryData", info, searchTerm);
    try {
      const response =
        user.company === "Read-Only"
          ? await GetCommunityAssignment({
              ...info,
              query: searchTerm,
              id: location.pathname.split("/")[2],
            })
          : await GetAssignmentRequests(info);
      if (response.status === 200) {
        console.log(response.data.data);
        setTotalRequests(
          response.data.data.totalMaintenanceAssignmentCount.Open
        );
        setTotalCount({
          all:
            response.data.data.totalMaintenanceAssignmentCount.Open +
            response.data.data.totalMaintenanceAssignmentCount["In-Progress"] +
            response.data.data.totalMaintenanceAssignmentCount.Completed,
          open: response.data.data.totalMaintenanceAssignmentCount.Open,
          inProgress:
            response.data.data.totalMaintenanceAssignmentCount["In-Progress"],
          completed:
            response.data.data.totalMaintenanceAssignmentCount.Completed,
        });
        setterFunction(response.data.data.assignments);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    setSearchTerm(data.searchQuery);
    console.log("running 139");
    managementCompanyFilterSubmit(filter, data.searchQuery);
  };

  const [activeFilter, setActiveFilter] = useState(
    location?.state?.status || "All"
  );

  useEffect(() => {
    console.log("useEffect 147");
    getAssignments(
      page,
      activeFilter,
      setAssignmentsAfterFilter,
      filter,
      searchTerm
    );
  }, [activeFilter]);
  console.log(page)
  // useEffect(() => {
  //   getAssignments(activeFilter, setAllAssignments);
  // }, [searchTerm]);

  // let all = 0,
  //   inProgress = 0,
  //   completed = 0;

  // allAssignments?.forEach((assignment) => {
  //   if (assignment.status === "In-Progress") inProgress++;
  //   if (assignment.status === "Completed") completed++;
  // });

  // all = allAssignments?.length || 0;

  return (
    <div className="w-full h-[100vh] flex flex-row font-inter bg-[#F8FEFC] relative">
      <Navbar />
      {/* <Sidebar /> */}

      <div className="flex flex-1 flex-col p-4 md:p-10 items-center overflow-hidden w-full gap-6 mt-16 h-[calc(100%-50px)] bg-[#FCFCFC]">
        <div className="flex w-full flex-1 flex-col max-h-[calc(100%-0px)] overflow-hidden p-4 items-center gap-6 bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]">
          <FormProvider {...methods}>
            <chakra.form
              className="w-full flex flex-col items-start"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              {/* header  */}
              <div className="flex flex-col 2xl:flex-row w-full justify-between items-center">
                <span className="font-medium lg:text-4xl text-xl mt-2 text-[#001124]">
                  Assignments
                </span>
                <div className=" w-full  lg:w-2/3 flex flex-col md:flex-row justify-around ">
                  <div className="w-full lg:min-w-[22rem] my-2 md:w-1/3">
                    <MultiSelectDropdown
                      options={managementCompanies}
                      onChange={(value: any) => {
                        console.log("running 226", value);
                        setFilter(value);
                        managementCompanyFilterSubmit(value, searchTerm);
                      }}
                      label="Management Company"
                      defaultValues={defaultValues}
                    />
                  </div>
                  <FormControl
                    className="w-full my-2 md:w-1/3"
                    isRequired={false}
                  >
                    <div
                      className={` relative group w-full  h-11 pl-3 md:pr-0.5 bg-transparent border-[1px] border-[#73B8FF33] rounded flex flex-row items-center `}
                    >
                      <input
                        title="Search for assignment"
                        {...methods.register("searchQuery")}
                        type={"text"}
                        placeholder="Search"
                        className="flex flex-1 text-sm placeholder:text-sm bg-transparent text-black lg:h-11 h-8"
                      />
                      <SearchIcon
                        className="absolute right-1"
                        onClick={() => methods.handleSubmit(onSubmit)()}
                      />
                    </div>
                  </FormControl>
                  <div
                    className={` relative my-2 lg:h-11 h-11 lg:min-w-[10rem] px-2 flex flex-row bg-[#55A8FD] rounded justify-center items-center gap-2 cursor-pointer`}
                    onClick={() => {
                      navigate("/assignment-requests");
                    }}
                  >
                    <span className=" text-sm  lg:text-lg text-white font-medium">
                      Requests
                      {totalRequests > 0 ? (
                        <div className="text-sm flex justify-center items-center rounded-full bg-red-600 absolute h-[23px] w-[23px] -top-2 -right-2 ">
                          {totalRequests}
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col gap-4 items-start sm:flex-row sm:items-center sm:justify-between mt-4">
                <div className="filter-list gap-x-4 flex justify-between">
                  <FilterButton
                    buttonText="All"
                    value="All"
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    count={`${totalCount.all}`}
                  />
                  <FilterButton
                    buttonText="New"
                    value="Open"
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    count={`${totalCount.open}`}
                    extraClass="bg-[#58B7BD]"
                  />
                  <FilterButton
                    buttonText="In-Progress"
                    value="In-Progress"
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    count={`${totalCount.inProgress}`}
                    extraClass="bg-[#fbbeb6]"
                  />
                  <FilterButton
                    buttonText="Completed"
                    value="Completed"
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    count={`${totalCount.completed}`}
                    extraClass="bg-[#9ebfed]"
                  />
                </div>
                <div>
                {totalPage>1 ? (
                <div className="flex overflow-x-auto sm:justify-center md:justify-end">
                <Pagination className="flowbite-pagination" currentPage={currentPage} totalPages={totalPage} onPageChange={onPageChange} />
              </div>
            ):('')}
                </div>
              </div>
            </chakra.form>
          </FormProvider>
          {loading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Loader className="w-40 h-40" type="square" />
            </div>
          ) : assignments.length > 0 ? (
           
                  
            <div className="w-full h-full overflow-y-auto scrollbar flex flex-wrap justify-center md:justify-start">
              {assignments.map((assignment, index) => (
                <div
                  onClick={() => {
                    navigate(`/assignment/${assignment._id}`);
                  }}
                  key={index}
                  className=" mx-4 my-8 xl:mx-8 hover:-translate-y-1 hover:scale-110 duration-300 transition ease-in-out delay-150"
                >
                  <AssignmentCard {...assignment} />
                </div>
              ))}{" "}
           
            </div>
          ) : (
            <div className="w-full h-fit flex pt-20 pl-[4.5rem] justify-start">
              <NoData route="" text="Assignments" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
