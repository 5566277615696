import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
} from "@chakra-ui/react";
import CustomButton from "../Button";
import { useContext, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import { CustomInput } from "../Input";
import { chakra, useDisclosure } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import mapIcon from "../../Assets/mapIcon.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { ImageObj } from "./Photos";
import { AssignmentData } from "../AssignmentCard";
import LocationPicker from "../../Assets/Icons/LocationPicker";
import LocationModal from "../../Pages/Assignment/CreateAssignment/partials/LocationModal";
import LocationIcon from "../../Assets/Icons/LocationIcon";

SwiperCore.use([Navigation]);

interface ModalProps {
  isOpen: any;
  onClose: any;
  assignment: AssignmentData;
}

export default function PhotosModal({
  isOpen,
  onClose,
  assignment,
}: ModalProps) {
  const { confirm, setConfirm } = useContext(UserContext);
  const methods = useForm();

  const [active, setActive] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState({});
  const onSubmit = async (data: any) => {
    console.log(data);
  };

  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"5xl"} isCentered>
      <ModalOverlay />
      <ModalContent shadow={"none"} bg={"transparent"}>
        <div className="w-[95%] sm:w-4/5 lg:w-full relative bg-white rounded-lg mx-auto shadow-lg">
          <ModalHeader
            display="flex"
            justifyContent={"center"}
            className="flex-col"
          >
            <span className="text-[#001124] lg:ml-8 ml-2 text-lg lg:text-2xl text-medium">
              Pictures
            </span>
          </ModalHeader>
          <CloseButton
            position={"absolute"}
            top={2}
            right={5}
            onClick={onClose}
          />
          <ModalBody px={6} py={6}>
            <div className="lg:w-full flex flex-col md:flex-row gap-2">
              <div className="md:w-[50%] w-full lg:h-72 h-54 ">
                <Swiper
                  // key={selectedNft.layerOrdering.length}
                  key={assignment.images!.length}
                  // loop={true}
                  onSlideChange={(swiper: SwiperCore) => {
                    const curr_ = swiper.slides[swiper.realIndex + 1];
                    if (curr_) {
                      setActive(swiper.activeIndex);
                      console.log(
                        curr_.id,
                        swiper.realIndex,
                        swiper.activeIndex
                      );
                    }
                  }}
                  navigation={true}
                  // modules={[Navigation]}
                  observer={true}
                  observeParents={true}
                  className="mySwiper2 w-full"
                >
                  {assignment.images?.map((image, index) => (
                    <SwiperSlide id={`${index}`} key={index}>
                      <div
                        className={` relative h-[14rem] lg:h-[18rem] rounded-[10px] w-full text-black text-4xl flex flex-row gap-2 p-5`}
                      >
                        <div className="w-full h-full">
                          <img
                            src={image.url}
                            alt=""
                            className="object-cover w-full h-full shadow-xl shadow-gray-900 "
                          />
                        </div>
                        <div
                         
                          onClick={() => {
                            console.log(
                              "inside PhotosModal",
                              image.geoLocation
                            );
                            setSelectedLocation({
                              location: {
                                lat: image.geoLocation.lat,
                                lng: image.geoLocation.lng,
                              },
                            });
                            onOpenAddModal();
                          }}
                          className="group cursor-pointer absolute top-6 right-8 w-6 h-6"
                        >
                          <LocationIcon className="h-8 w-8 text-red-500" />
                          <div className="invisible absolute text-sm z-100 border-[1px] border-white bg-gray-600 top-[1.5rem] left-[1.8rem] py-[2px] px-[3px] text-white group-hover:visible">Location</div>
                        </div>
                      </div>
                      {/* Step-2 work here  */}
                    </SwiperSlide>
                  ))}
                </Swiper>
                <LocationModal
                  isOpen={isOpenAddModal}
                  onClose={onCloseAddModal}
                  title={"Choose Location"}
                  text={`""`}
                  defaultLocation={selectedLocation}
                  setLocation={() => { }}
                  viewOnly={true}
                // imageLocation={image.geoLocation}
                />
              </div>
              <div className="lg:w-[50%] w-[40%] flex flex-col">
                <span className="text-gray-dark text-lg lg:text-2xl text-medium">
                  {assignment.subject}
                </span>
                <span className="text-gray-medium  truncate text-base font-light">
                  {assignment._id}
                </span>
                <div className="w-full lg:grid lg:grid-cols-4 mt-2  flex flex-row flex-wrap gap-2">
                  {assignment.images?.map((img, index) => (
                    <div
                      key={index}
                      className={`flex flex-1 h-20 bg-[#0000002c] text-white text-lg border overflow-hidden ${index === active
                          ? "border-[#55A8FD]"
                          : "border-transparent"
                        } hover:border-[#55A8FD] cursor-pointer items-center justify-center`}
                    >
                      <div className="w-full h-20 relative">
                        <img
                          src={img.url}
                          alt=""
                          className="object-cover w-[10rem] h-[10rem]  lg:w-full lg:h-full"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </ModalContent>
    </Modal>
  );
}
