export default function DownloadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      fill="none"
      viewBox="0 0 27 26"
    >
      <path
        fill="#55A8FD"
        d="M24.025 17.571l-.886 5.25H3.405l-.886-5.25H.312v8.346h25.92v-8.346h-2.207zm-.559-9.326h-6.495V5.21H9.575v3.034H3.141l10.097 11.814L23.466 8.245zM16.97 0H9.575v1.104h7.396V0zm0 2.061H9.575v2.11h7.396v-2.11z"
      ></path>
    </svg>
  );
}
