import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import LocationModal from "./LocationModal";
import Upload from "../../../../Assets/upload.png";
import { useDisclosure } from "@chakra-ui/react";

interface DropZoneModel {
  setImages: React.Dispatch<React.SetStateAction<any>>;
  setLocations: React.Dispatch<React.SetStateAction<any>>;
}

const DropZone = ({ setImages, setLocations }: DropZoneModel) => {
  const [location, setLocation] = useState<any>({});

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: 5000000,
    accept: {
      "image/": [".png", ".jpg", ".jpeg"],
    },
    onDrop: () => {
      console.log("Dropped successfully");
    },
    onDropAccepted: () => {
      console.log("Drop Accepted");
      openLocationModal();
    },
    onDropRejected: () => {
      console.log("Drop rejected");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const {
    isOpen: isOpenLocationModal,
    onOpen: openLocationModal,
    onClose: closeLocationModal,
  } = useDisclosure();

  console.log("acceptedFiles", acceptedFiles, location);

  useEffect(() => {
    if (acceptedFiles[0] && Object.keys(location)?.length > 0) {
      //   setLocations(location);
      setImages((prev: File[]) => {
        return [...prev, acceptedFiles[0]];
      });
      setLocations((prev: any) => {
        return [...prev, location];
      });
    }
  }, [location]);

  return (
    <div
      className="container flex flex-col justify-center items-center 
border border-[#55A8FD] border-dashed bg-[#55A8FD0A] rounded-lg lg:w-[400px] lg:h-[450px] mt-2 max-w-full lg:p-0 p-2"
    >
      <div
        {...getRootProps({
          className:
            "dropzone flex flex-col justify-center items-center gap-y-4 w-full h-full",
        })}
      >
        <input {...getInputProps()} />
        <img
          src={Upload}
          alt=""
          className="lg:w-fit lg:h-fit lg:pt-0 pt-4 w-[60px] h-[80px]"
        />
        <p className="text-md lg:text-xl lg:mt-8 mt-2">Drag and Drop file</p>
        <p className="text-md lg:text-xl">or</p>
        <button
          type="button"
          onClick={open}
          className="text-white bg-[#55A8FD] lg:px-6 lg:py-2.5 px-4 py-1 rounded font-semibold text-md lg:text-xl"
        >
          Browse image
        </button>
        <div className="flex items-center">
          <div className="flex flex-col lg:items-end items-start">
            <p className="text-[#ACACAC] text-xs">Max file size: </p>
            <p className="text-[#ACACAC] text-xs">Supported file types: </p>
          </div>
          <div className="flex flex-col items-start px-1">
            <p className="font-medium text-xs text-[#5C5E5D]">5MB</p>
            <p className="font-medium text-xs text-[#5C5E5D]">PNG, JPG, JPEG</p>
          </div>
        </div>
      </div>
      <aside></aside>
      <LocationModal
        isOpen={isOpenLocationModal}
        onClose={closeLocationModal}
        title={"Choose Location"}
        text={`""`}
        setLocation={setLocation}
      />
    </div>
  );
};

export default DropZone;
