import { Dispatch, SetStateAction } from "react";

interface FilterButtonProps {
  buttonText: string;
  value: string;
  activeFilter: string;
  setActiveFilter: Dispatch<SetStateAction<string>>;
  count?: any;
  extraClass?: string;
}

export default function FilterButton({
  buttonText,
  value,
  activeFilter,
  setActiveFilter,
  count = "0/0",
  extraClass,
}: FilterButtonProps) {
  const handleFilter = () => {
    setActiveFilter(value);
  };
  return (
    <button
      className={`px-4 py-1 border-[#55A8FD] border rounded-[16px] flex space-x-1 items-center ${
        activeFilter === value ? "bg-[#E8F1FB]" : "bg-white"
      } text-[#55A8FD] text-[10px] md:text-xs lg:text-md  `}
      onClick={handleFilter}
    >
      <span>{buttonText}</span>
      {count && (
        <span
          className={`w-5 h-5 flex items-center justify-center text-[0.7rem] rounded-full text-white ${
            extraClass ? extraClass : "bg-[#55A8FD]"
          } px-1 py-[1px]`}
        >
          {count}
        </span>
      )}
    </button>
  );
}
