import { useContext, useState } from "react";
import CustomButton from "../Button";
import { AddCommentAPI } from "../../Utils/ADD";
import toast from "react-hot-toast";
import { UserContext } from "../../Context/UserContext";

interface AddComment {
  comments: any[];
  setComments: React.Dispatch<React.SetStateAction<any[]>>;
  assignmentId: string;
}

export default function AddComment({
  comments,
  setComments,
  assignmentId,
}: AddComment) {
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const { user } = useContext(UserContext);

  const addComment = async () => {
    setLoading(true);
    let commentData = {
      assignmentId: assignmentId,
      comment: comment,
      commentDate: new Date().toISOString(),
    };
    try {
      const response = await AddCommentAPI(commentData);
      if (response.status === 200) {
        console.log(response);
        let newComment = {
          comment: comment,
          commentDate: new Date().toISOString(),
          commentUserData: [
            {
              firstName: user.name,
              lastName: "",
            },
          ],
        };
        setComments([...comments, newComment]);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
    setComment("");
  };

  return (
    <div className={`w-full flex flex-col items-start gap-3 pt-5 border-t ${comments.length > 0 ? "border-[#7E7E7E]" : "border-transparent"}`}>
      <div className="flex justify-start items-center gap-5">
        <div className="w-7 h-7 sm:w-11 sm:h-11 bg-gray-medium rounded-full" />
        <span className="text-black text-sm sm:text-base">Add a comment</span>
      </div>
      <div className={`flex w-full pl-16`}>
        <textarea
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          placeholder={"Add your comment here..."}
          className="flex w-full h-[7rem] bg-transparent text-gray-medium text-sm outline rounded-md px-5 py-2 outline-2 outline-[#E8E8E8]"
        />
      </div>
      <div className="w-full flex justify-end mt-5">
        <div
          onClick={() => {
            addComment();
          }}
        >
          <CustomButton
            colorScheme="blue"
            w={"w-40"}
            loading={loading}
            text="Comment"
          />
        </div>
      </div>
    </div>
  );
}
