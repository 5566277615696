import React from "react";
import TimeLineCard from "./partials/TimeLineCard";

const TimeLine = ({ timeLineData }) => {
  console.log(timeLineData);
  return (
    <div className="mt-4 ">
      <div className="py-2 text-lg 2xl:text-2xl font-semibold">
        Assignment History
      </div>
      <div className="scrollbar max-h-[450px] 2xl:max-h-[600px] overflow-y-auto ">
        {timeLineData?.length > 0 && timeLineData[0]?.event.length > 0 ? (
          timeLineData?.map((data, index) => {
            return (
              <TimeLineCard
                key={index}
                data={data}
                isLastCard={timeLineData?.length - 1 === index}
              />
            );
          })
        ) : (
          <p>No history yet.</p>
        )}
      </div>
    </div>
  );
};

export default TimeLine;
