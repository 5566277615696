import { Triangle } from "react-loader-spinner";
import circleLoader from "../Assets/circleLoader.gif";
import squareLoader from "../Assets/squareLoader.gif";

export const Loader = ({
  type,
  className,
}: {
  type: string;
  className?: string;
}) => {
  return (
    <div className={className ? className : "w-full h-full"}>
      {/* <img
        src={type === "circle" ? circleLoader : squareLoader}
        alt="ProCam Tasks"
        className="cursor-pointer w-full"
      /> */}
      <Triangle
        visible={true}
        height="80"
        width="80"
        color="#55A8FD"
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};
