import React from "react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import Camera from "../Assets/Icons/Camera"
import ProfileForm from "../Components/Forms/ProfileForm";

export default function MyProfile() {
  return (
    <div className="w-full h-[100vh] flex flex-row font-inter bg-[#F8FEFC] relative">
      <Navbar />
      <Sidebar />
      <div className="flex flex-1 flex-col p-10 items-center w-full gap-6 mt-16 h-[calc(100%-50px)] bg-[#F8FEFC]">
        <div
          className="flex w-full flex-1 flex-col max-h-[calc(100%-0px)] overflow-hidden p-8 gap-6
         bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]"
        >
          <span className="font-medium text-4xl mt-2 text-[#001124]">
            My Profile
          </span>
          <span className="text-lg font-normal">Profie Image</span>
          <div className="container-wrapper flex justify-between">
            {/* left-container */}
            <div className="">
              <div className="profile-picture h-[24rem] w-[20rem]  rounded border-[1px] border-[#CCCCCC] flex flex-col justify-evenly items-center">
                <div className="h-[12rem] w-[12rem] rounded-full bg-[#E8E8E8]"></div>
                <div className="flex flex-col w-full items-center">
                  <span className="text-xl font-medium">John Doe</span>
                  <span className="font-normal text-lg">johndoe@gmail.com</span>
                </div>
              </div>
              <div className="w-[20rem] flex justify-center gap-x-2 mt-2">
                <Camera/>
                <span className="font-normal text-sm text-[#959595]">
                  Browse Image
                </span>
              </div>
            </div>
            {/* right-container */}
            <div className="form-fields   ">
              <ProfileForm/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
