import React, { useEffect, useState } from "react";
import CardTag from "./CardTag";
import DefaultImage from "../Assets/dafaultImage.png";
import CustomButton from "./Button";
import { AssignmentData } from "./AssignmentCard";
import toast from "react-hot-toast";
import {
  AcceptAssignment,
  AcceptAssignmentProps,
  DeclineAssignment,
  UpdateAssignment,
} from "../Utils/ADD";
import { MultiSelectInput } from "./Input";
import { useForm, FormProvider } from "react-hook-form";
import {
  CloseButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "./Modals/ConfirmModal";

export default function AssignmentRequestCard({
  _id,
  subject,
  images,
  assignmentDates,
  location,
  assigner,
  contractorType,
  allEmployees,
}: AssignmentData) {
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [assignmentId, setAssignmentId] = useState("");
  const [declineReason, setDeclineReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm();

  const navigate = useNavigate();

  let users: string[] = methods.watch("assignedUsers", "");

  const acceptAssignment = async () => {
    setLoading(true);
    let info: AcceptAssignmentProps = {
      assignedUsers: users,
      assignmentId: _id,
    };
    console.log(info);
    try {
      const response = await AcceptAssignment(info);
      if (response.status === 200) {
        console.log(response.message);
        setLoading(false);
        navigate("/assignment");
      } else {
        setLoading(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const declineAssignment = async () => {
    try {
      setLoading(true);
      const response = await DeclineAssignment({
        assignmentId: _id,
        declineReason,
      });
      if (response.status === 200) {
        console.log(response.message);
        setLoading(false);
        navigate("/assignment");
      } else {
        setLoading(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="w-full h-fit items-center lg:items-start flex flex-col lg:flex-row">
      <div className="w-[12rem] h-[15rem] md:w-[25rem] md:h-[20rem]   lg:w-[16rem] lg:h-[12rem] ">
        <img
          src={images && images.length > 0 ? images[0].url : DefaultImage}
          alt=""
          className="object-contain w-[100%] h-[100%]"
        />
      </div>
      <div className="w-full px-4 flex flex-col md:flex-row md:justify-between">
        <div className="w-[100%] md:w-[15.5rem]  flex flex-col items-center lg:items-start md:justify-between py-4">
          <div className="">
            <p className="font-normal text-black text-lg text-left ">
              {subject}
            </p>
            <p className="text-[#909090] font-normal text-xs">
              {assignmentDates
                ? formatDate(assignmentDates.openingDate)
                : "Date"}
            </p>
          </div>
          <div className="">
            <p className=" font-normal text-sm text-gray-700">Assigned by</p>
            {assigner && (
              <p className="font-normal text-[#909090] text-base">
                {assigner?.firstName + " " + assigner?.lastName}
              </p>
            )}
          </div>
        </div>
        <div className="w-[100%] md:w-[15rem] flex flex-col items-center lg:items-start md:justify-between py-4">
          <p className="font-normal text-gray-700 text-xs">{location}</p>
          {contractorType && contractorType.length > 0 && (
            <div className="">
              <p className="text-[#909090] font-normal text-[11px]">TYPE</p>
              <div className="flex flex-wrap items-left gap-x-2 gap-y-1">
                {contractorType?.map((x) => (
                  <CardTag tagText={x} />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="input-field w-[100%] md:w-[15rem] px-4 py-4 flex flex-col items-center lg:items-start md:justify-between">
          <div className="">
            <p className="font-normal text-xs">Assign to</p>
            <FormProvider {...methods}>
              <chakra.form
                autoComplete="off"
                className="w-full flex flex-col items-end gap-9"
                // onSubmit={methods.handleSubmit(onSubmit)}
              >
                <MultiSelectInput
                  options={allEmployees.map((employee) => {
                    return employee.firstName + " " + employee.lastName;
                  })}
                  map={allEmployees.map((employee) => employee._id)}
                  label=""
                  name="assignedUsers"
                  placeholder="- Select -"
                  isRequired={false}
                />
              </chakra.form>
            </FormProvider>
          </div>
          <div className="flex gap-2 md:justify-between">
            <button
              onClick={() => {
                acceptAssignment();
              }}
              className=" bg-[#55A8FD] text-white w-18 h-8 md:w-24 md:h-8 py-auto rounded px-[16px] text-[17px]"
            >
              Accept
            </button>
            <button
              onClick={() => {
                // declineAssignment();
                setIsOpen(true);
              }}
              className=" bg-[#ED5050] text-white w-18 h-8 md:w-24 md:h-8 py-auto rounded px-[16px] text-[17px]"
            >
              Decline
            </button>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"} isCentered>
        <ModalOverlay />
        <ModalContent shadow={"none"} bg={"transparent"}>
          <div className="w-[90%] lg:w-full relative bg-white mx-auto shadow-lg">
            <ModalHeader
              display="flex"
              justifyContent={"center"}
              className="flex-col"
            >
              <span className="text-gray-dark text-sm lg:text-2xl text-medium">
                Decline Assignment
              </span>
              {/* <span className="text-gray-medium text-sm lg:text-base font-light">
                {assignment._id}
              </span> */}
            </ModalHeader>
            <CloseButton
              position={"absolute"}
              top={2}
              right={5}
              onClick={() => {
                setIsOpen(false);
              }}
            />
            <div className="m-4 p-2 rounded border-2 border-slate-300 ">
              <textarea
                onChange={(e) => {
                  setDeclineReason(e.target.value);
                }}
                value={declineReason}
                placeholder="Reason to decline this assignment request"
                className="w-[95%] h-[8rem] p-2 resize-none"
              />
            </div>
            <div className="flex flex-row gap-2 items-center justify-center m-4">
              <button
                onClick={() => {
                  if (declineReason.length > 0) declineAssignment();
                  else {
                    toast.error(
                      "Please specify a reason to decline this assignment"
                    );
                  }
                }}
                className=" bg-[#ED5050] text-white w-18 h-8 md:w-24 md:h-8 py-auto rounded px-[16px] text-[17px]"
              >
                Decline
              </button>
              <button
                onClick={() => {
                  setDeclineReason("");
                  setIsOpen(false);
                }}
                className=" border border-[#55A8FD] w-18 h-8 md:w-24 md:h-8 py-auto rounded px-[16px] text-[17px]"
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}

export const formatDate = (date: string): string => {
  let inputDate = new Date(date);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = months[inputDate.getMonth()];
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
  return formattedDate;
};
