export default function HideIcon({className}:{className?:string}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      fill="none"
      viewBox="0 0 20 18"
      className={className ?? "w-6 h-6 text-[#ACACAC]"}
    >
      <path
        fill="currentColor"
        d="M9.998 10.499a1.5 1.5 0 100-2.998 1.5 1.5 0 000 2.998zM13.287 15.116l-1.29-1.34-.07-.07-1.269-1.269A3.5 3.5 0 016.5 9c.005-.204.025-.408.06-.61L4.561 6.392 3.002 4.87A15.881 15.881 0 00.134 8.5a1 1 0 000 .999c.63 1.09 3.998 6.496 9.884 6.496h.25a9.476 9.476 0 003.229-.67l-.21-.209zM6.59 2.763L9.39 5.562c.201-.035.405-.056.61-.06A3.498 3.498 0 0113.495 9a4.074 4.074 0 01-.06.61l2.68 2.678.839.84A15.882 15.882 0 0019.863 9.5a1 1 0 000-1c-.64-1.11-4.158-6.676-10.134-6.496-1.107.033-2.2.26-3.229.67l.09.09z"
      ></path>
      <path
        fill="currentColor"
        d="M18.703 16.285l-1.3-1.29-1.998-1.998L5.89 3.472 4.42 2.003 2.713.293a1.003 1.003 0 10-1.42 1.42l2.24 2.289L5.28 5.7l7.306 7.296.07.07 1.34 1.34.589.589 2.699 2.708a1 1 0 001.419 0 .998.998 0 000-1.419z"
      ></path>
    </svg>
  );
}
