import { FormControl, FormLabel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import CloseIcon from "../../../../Assets/Icons/CloseIcon";
import ArrowDown from "../../../../Assets/Icons/ArrowDown";
import ErrorIcon from "../../../../Assets/Icons/ErrorIcon";

export const MultiDropDown = ({
  options,
  label,
  name,
  values,
  mapValues,
  placeholder,
  isRequired,
  showLabels,
  search,
  map,
  ...rest
}: any) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<FieldValues>();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [showResults, setShowResults] = useState(false);

  const [selected, setSelected] = useState<any[]>([]);
  const [selectedMap, setSelectedMap] = useState<any[]>([]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowResults(true);
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    if (inputValue === "") {
    }

    const results = options.filter((item: any) =>
      item.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSearchResults(options ? options : []);
  };

  const showDropdown = (event: any) => {
    event.preventDefault();
    setShowResults(true);
    console.log("jere2", searchResults);
    if (searchResults.length === 0) setSearchResults(options);
  };

  useEffect(() => {
    if (showResults) {
      //@ts-ignore
      document.addEventListener("click", function (event) {
        //@ts-ignore
        var targetId = event.target.id;
        if (targetId !== "dropdown-result-box") {
          if (
            targetId !== "dropdown-search-box-1234" &&
            targetId !== "dropicon-search-box-1234" &&
            targetId !== "multi-remove-item"
          ) {
            setShowResults(false);
          }
        }
      });
    }
  }, [showResults]);

  useEffect(() => {
    console.log(selected, selectedMap);
    setValue(name, map ? selectedMap : selected);
  }, [selected]);

  useEffect(() => {
    if (values) {
      setSelected(values);
    }
    if (mapValues) {
      setSelectedMap(mapValues);
      setValue(name, mapValues);
    } else {
      setValue(name, values);
    }
  }, []);
  console.log("input select", selected, values, selectedMap, searchResults);
  return (
    <FormControl isRequired={isRequired}>
      {(showLabels ?? true) && (
        <FormLabel
          // fontSize={{ base: "1.2rem", md: "1.2rem", "3xl": "2rem" }}
          className="text-gray-dark text-sm md:text-xl"
        >
          {label}
        </FormLabel>
      )}
      <div className={`select-none w-full flex flex-col justify-start mb-0`}>
        <div
          className={`group overflow-x-auto relative w-full h-10 mb-1 px-4 border-[1px] border-[#E6E6E6] focus-within:border-[#55A8FD] bg-transparent rounded-lg flex items-center gap-2 cursor-pointer`}
        >
          {selected?.map((item, index) => (
            <div className="flex items-center gap-1 text-black text-[1rem] bg-[#E5E5E5] px-2 rounded truncate">
              <span className=" truncate">{item}</span>
              <div
                id="multi-remove-item"
                onClick={() => {
                  setSelected(
                    [...selected].filter((option, i) => option !== item)
                  );
                  if (map)
                    setSelectedMap(
                      [...selectedMap].filter((option, i) => index !== i)
                    );
                }}
              >
                <CloseIcon />
              </div>
            </div>
          ))}
        </div>
        <div
          className={`group relative w-full h-11 px-4 border-[8px] ${
            errors[name] ? "border-[#D51D14]" : "border-[#E6E6E6]"
          } bg-transparent rounded-lg flex items-center cursor-pointer`}
        >
          <input
            title=""
            id="dropdown-search-box-1234"
            type="text"
            autoComplete="off"
            value={searchTerm}
            onChange={handleSearch}
            placeholder={placeholder}
            onClick={showDropdown}
            className="flex w-full bg-transparent text-black outline-none"
          />
          <div onClick={showDropdown} id="dropicon-search-box-1234">
            <ArrowDown className="text-gray-dark w-3 h-3" />
          </div>
          {showResults && (
            <div
              className={`flex flex-col gap-1 w-full py-1 max-h-[300px] overflow-auto bg-transparent z-10 bg-white rounded-md absolute mt-4 left-0 top-full scrollbar shadow-[2px_2px_40px_1px_rgba(237,237,237,0.90)]`}
            >
              {searchResults?.map((item, index) => (
                <div
                  key={index}
                  id="dropdown-result-box"
                  onClick={() => {
                    let temp = [];
                    let tempMap: string[] = [];
                    if (selected.some((option) => option === item)) {
                      temp = [...selected].filter(
                        (option, i) => option !== item
                      );
                      if (map)
                        tempMap = [...selectedMap].filter(
                          (option, i) => index !== i
                        );
                    } else {
                      temp = [...selected].concat([item]);
                      if (map) tempMap = [...selectedMap].concat([map[index]]);
                    }
                    setSelected(temp);
                    if (map) {
                      console.log(map, tempMap);
                      setValue(name, tempMap);
                      setSelectedMap(tempMap);
                    } else setValue(name, temp);
                  }}
                  className={`flex items-center ${
                    selected.some((option) => option === item)
                      ? "text-[#ACACAC]"
                      : "text-gray-dark"
                  } gap-2 py-1 px-2 hover:bg-[#55A8FD] cursor-pointer`}
                >
                  {item}
                  {/* <MdVerified className="text-blue-500" /> */}
                </div>
              ))}
            </div>
          )}
        </div>
        {(showLabels ?? true) && (
          <span
            className={`${
              errors[name] ? "opacity-100" : "opacity-0"
            } flex flex-row items-center gap-1 text-[#D51D14] text-sm m-1.5`}
          >
            <ErrorIcon />
            {errors[name] ? errors[name]!.message!.toString() : "NONE"}
          </span>
        )}
      </div>
    </FormControl>
  );
};
