import React, { useState, useContext, useEffect } from "react";
import { chakra, filter, FormControl, Spinner } from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import AssignmentCard, {
  AssignmentData,
} from "../../Components/AssignmentCard";
import { useForm, FormProvider } from "react-hook-form";
import PlusIcon from "../../Assets/Icons/PlusIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import FilterIcon from "../../Assets/Icons/FilterIcon";
import { UserContext } from "../../Context/UserContext";
import TrashIcon from "../../Assets/Icons/TrashIcon";
import { useNavigate } from "react-router-dom";
import FilterButton from "../../Components/FilterButton";
import CustomButton from "../../Components/Button";
import {
  GetAssignments,
  GetAssignmentsProps,
  GetCompanyFilterList,
} from "../../Utils/GET";
import toast from "react-hot-toast";
import { Loader } from "../../Components/Loader";
import NoData from "../../Components/NoData";
import MultiSelectDropdown from "./CreateAssignment/partials/MultiSelectDropdown";
import { Pagination } from 'flowbite-react';


export default function ManagementAssignments() {
  const methods = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [assignments, setAssignments] = useState<AssignmentData[]>([]);
  const [allAssignments, setAllAssignments] = useState<AssignmentData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [maintenanceCompanies, setMaintenanceCompanies] = useState<
    { label: string; value: string }[]
  >([]);
  const [filter, setFilter] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);


  const onPageChange = (page: number) => setCurrentPage(page);


  const [totalCount, setTotalCount] = useState({
    all: 0,
    open: 0,
    inProgress: 0,
    completed: 0,
    declined: 0,
  });
  const onSubmit = async (data: any) => {
    console.log(data);
    setSearchTerm(data.searchQuery);
    console.log("Running 3");
    maintenanceCompanyFilterSubmit(filter, data.searchQuery);
  };

  const setAssignmentsAfterFilter = (data: any) => {
    setAssignments(data);
    setAllAssignments(data);
    // setActiveFilter("All");
  };

  const maintenanceCompanyFilterSubmit = async (
    companyFilter: string[],
    searchTerm: string
  ) => {
    console.log("Running 1");
    getAssignments(
      page,
      activeFilter,
      setAssignmentsAfterFilter,
      companyFilter,
      searchTerm
    );
  };
  console.log("filter", filter);
  const getAssignments = async (
    pageNo: number,
    status: string,
    setterFunction: React.Dispatch<React.SetStateAction<AssignmentData[]>>,
    companyFilter?: string[],
    search?: string
  ) => {
    setLoading(true);
    let info: GetAssignmentsProps = {
      status: status,
      query: search,
      page: pageNo,
    };
    console.log(pageNo)
    setTotalPage(pageNo)
    if (
      (companyFilter && companyFilter.length > 0) ||
      (filter && filter.length > 0)
    ) {
      info = {
        ...info,
        filter: { maintenanceCompany: companyFilter ? companyFilter : filter },
      };
    }

    console.log(info);
    try {
      const response = await GetAssignments(info);
      if (response.status === 200) {
        console.log(response.data.data);
        setterFunction(response.data.data.assignments);
        setTotalCount({
          all:
            response.data.data.totalMaintenanceAssignmentCount.Open +
            response.data.data.totalMaintenanceAssignmentCount["In-Progress"] +
            response.data.data.totalMaintenanceAssignmentCount.Completed +
            response.data.data.totalMaintenanceAssignmentCount.Declined,
          open: response.data.data.totalMaintenanceAssignmentCount.Open,
          inProgress:
            response.data.data.totalMaintenanceAssignmentCount["In-Progress"],
          completed:
            response.data.data.totalMaintenanceAssignmentCount.Completed,
          declined: response.data.data.totalMaintenanceAssignmentCount.Declined,
        });
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getMaintenanceCompanies = async () => {
    try {
      const response = await GetCompanyFilterList();
      console.log("companyList", response);
      const companyList = response.data.data[0].maintenanceCompanyList.map(
        (company: any) => {
          return { label: company.companyName, value: company._id };
        }
      );

      setMaintenanceCompanies(companyList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMaintenanceCompanies();
  }, []);

  const [activeFilter, setActiveFilter] = useState("All");

  // useEffect(() => {
  //   console.log("Running 2");
  //   if (filter.length === 0)
  //     getAssignments(page, "All", setAssignmentsAfterFilter);
  // }, [searchTerm]);

  useEffect(() => {
    getAssignments(
      page,
      activeFilter,
      setAssignmentsAfterFilter,
      filter,
      searchTerm
    );
  }, [activeFilter]);
  console.log("length", assignments.length);
  console.log("assignmentData", assignments);

  // console.log(filterCount, "filterCount");

  return (
    <div className="flex flex-1 flex-col p-4 md:p-10 items-center overflow-hidden w-full gap-6 mt-16 h-[calc(100%-50px)] bg-[#FCFCFC]">
      <div className="flex w-full flex-1 flex-col max-h-[calc(100%-0px)] overflow-hidden p-4 items-center gap-6 bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]">
        <FormProvider {...methods}>
          <chakra.form
            className="w-full flex flex-col items-start"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {/* header  */}
            <div className="flex flex-col 2xl:flex-row w-full justify-between items-center">
              <span className="font-medium lg:text-4xl text-xl mt-2 text-[#001124]">
                Assignments
              </span>
              <div className=" w-full  2xl:w-2/3 flex flex-col md:flex-row justify-around ">
              <div  className="w-full lg:min-w-[22rem] my-2 md:w-1/3 ">
              <MultiSelectDropdown
                  options={maintenanceCompanies}
                  onChange={(value: any) => {
                    console.log("filter", value);
                    setFilter(value);
                    maintenanceCompanyFilterSubmit(value, searchTerm);
                  }}
                  label="Maintenance Contractor"
                  
                />
                </div>
            
            
                <FormControl className="w-full  my-2 md:w-1/3" isRequired={false}>
                  <div
                    className={`relative group w-full  h-11 pl-3 md:pr-0.5 bg-transparent border-[1px] border-[#73B8FF33] rounded flex flex-row items-center  `}
                  >
                    <input
                      title="Search"
                      {...methods.register("searchQuery")}
                      type={"text"}
                      placeholder="Search"
                      className="flex flex-1 text-sm placeholder:text-sm bg-transparent text-black lg:h-11 h-8"
                    />
                    <SearchIcon
                      className="absolute right-1 "
                      onClick={() => methods.handleSubmit(onSubmit)()}
                    />
                  </div>
                </FormControl>
                  <div
                    className={` w-full md:w-1/6 my-2 lg:h-11 h-11  px-2 flex flex-row bg-[#55A8FD] rounded justify-center items-center gap-2 cursor-pointer  `}
                    onClick={() => {
                      navigate("/create-assignment");
                    }}
                  >
                    <PlusIcon />
                    <span className="text-sm  lg:text-md text-white font-medium">
                      Add New
                    </span>
                  </div>
              </div>
            </div>

            {/* <div className=" mt-4 w-full h-full flex flex-col justify-between items-end">
              <div className=" w-full lg:w-[30%] flex flex-col gap-2">
                <MultiSelectDropdown
                  options={maintenanceCompanies}
                  onChange={(value: any) => {
                    console.log("filter", value);
                    setFilter(value);
                    maintenanceCompanyFilterSubmit(value, searchTerm);
                  }}
                  label="Maintenance Contractor"
                />
              </div>
            </div> */}

            <div className="w-full flex flex-row items-center justify-between mt-4">
              <div className="filter-list lg:gap-x-4 gap-x-[0.5rem] gap-y-2 flex-wrap flex lg:justify-between">
                <FilterButton
                  buttonText="All"
                  value="All"
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                  count={`${totalCount.all}`}
                />
                <FilterButton
                  buttonText="New"
                  value="Open"
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                  count={`${totalCount.open}`}
                  extraClass="bg-[#58B7BD]"
                />
                <FilterButton
                  buttonText="In-Progress"
                  value="In-Progress"
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                  count={`${totalCount.inProgress}`}
                  extraClass="bg-[#fbbeb6]"
                />
                <FilterButton
                  buttonText="Completed"
                  value="Completed"
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                  count={`${totalCount.completed}`}
                  extraClass="bg-[#9ebfed]"
                />
                <FilterButton
                  buttonText="Declined"
                  value="Declined"
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                  count={`${totalCount.declined}`}
                  extraClass="bg-[#e9887bff]"
                />
              </div>
              <div>
              {totalPage>1 ? (
                <div className="flex overflow-x-auto sm:justify-center md:justify-end">
                <Pagination className="flowbite-pagination" currentPage={currentPage} totalPages={totalPage} onPageChange={onPageChange} />
              </div>
            ):('')}
              </div>
            </div>
          </chakra.form>
        </FormProvider>

        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader className="w-40 h-40" type="square" />
          </div>
        ) : assignments.length > 0 ? (
       
          <div className="w-full h-full overflow-y-auto scrollbar flex flex-wrap justify-center md:justify-start">
         
              
              {assignments.map((assignment, index) => (
                <div
                  onClick={() => {
                    navigate(`/assignment/${assignment._id}`);
                  }}
                  key={index}
                  className="mx-4 my-8 xl:mx-8 hover:-translate-y-1 hover:scale-110 duration-300 transition ease-in-out delay-150"
                >
                  <AssignmentCard {...assignment} />
                </div>
              ))}{" "}

          </div>
        
        ) : (
          <div className="w-full h-fit flex pt-20 pl-[4.5rem] justify-start">
            <NoData route="" text="Assignments" />
          </div>
        )}
        {/* <div className="w-full flex  justify-end items-center mt-10 space-x-5">
          <button
            onClick={() => {
              setPage(page - 1);
              getAssignments(page - 1);
            }}
            disabled={page === 1 ? true : false}
            className={`w-12 h-12 p-3 rounded-full ${
              page > 1 ? "bg-blue-500" : "bg-slate-300"
            } text-white text-lg shadow`}
          >
            {"<"}
          </button>
          <button
            onClick={() => {
              setPage(page + 1);
              getAssignments(page + 1);
            }}
            disabled={assignments?.length >= 10 ? false : true}
            className={`w-12 h-12 p-3 rounded-full ${
              assignments?.length === 10 ? "bg-blue-500" : "bg-slate-300"
            } text-white text-lg shadow`}
          >
            {">"}
          </button>
        </div> */}
      </div>
    </div>
  );
}