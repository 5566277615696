//import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./Pages/Login";
import CreatePassword from "./Pages/CreatePassword";
import ResetPassword from "./Pages/ResetPassword";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import OwnerDashboard from "./Pages/OwnerDashboard/OwnerDashboard";
import CreateAccount from "./Pages/CreateAccount";
import { useLocation } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import ProtectedRoute from "./Helpers/protected-route";
import ManagementDashboard from "./Pages/ManagementDashboard/ManagementDashboard";
import MaintenanceDashboard from "./Pages/MaintenanceCompanyDashboard/MaintenanceDashboard";
import { useContext, useEffect } from "react";
import { UserContext } from "./Context/UserContext";
import IndividualDashboard from "./Pages/IndividualManagerDashboard/IndividualDashboard";
import { GetUser } from "./Utils/AUTH";
import MyProfile from "./Pages/MyProfile";
import AssignmentRequests from "./Pages/Assignment/ContractorAssignmentRequests";
import ManagementAssignments from "./Pages/Assignment/ManagementAssignments";
import CreateAssignment from "./Pages/Assignment/CreateAssignment/CreateAssignment";
import Diary from "./Pages/OwnerDashboard/Diary";
import CommunityUserDashboard from "./Pages/CommunityUser/CommunityUserDashboard";
import AddUser from "./Components/Forms/AddUser";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const breakpoints = {
    base: "0em", // 0px
    sm: "30em", // ~480px. em is a relative unit and is dependant on the font size.
    md: "48em", // ~768px
    lg: "62em", // ~992px
    xl: "80em", // ~1280px
    "2xl": "96em", // ~1536px
    "3xl": "150em",
  };

  const theme = extendTheme({ breakpoints });
  const userId = localStorage.getItem("userId");
  console.log(userId, "userId");

  const { user, setUser, setConfirm, setVerified, setOpenSidebar } =
    useContext(UserContext);

  const screenWidth = window.innerWidth;
  console.log(screenWidth, "screenWidth");
  useEffect(() => {
    if (screenWidth < 1024) {
      setOpenSidebar(false);
    } else {
      setOpenSidebar(true);
    }
  }, []);

  const getUser = async () => {
    console.log("in get user");
    try {
      const response = await GetUser();
      if (response.status === 200) {
        console.log(response.user, "user info");
        setVerified(response.user.phoneNoVerified);
        if (localStorage.getItem("verified") === null)
          localStorage.setItem(
            "verified",
            response.user.phoneNoVerified ? "true" : "false"
          );
        setUser({
          name: response.user.firstName! + " " + response.user.lastName!,
          email: response.user.email!,
          phone: response.user.phoneNo,
          company: response.user.companyType
            ? response.user.companyType
            : response.user.authorityLevel,
          authority: response.user.authorityLevel,
          companyName: response.user?.companyName,
          companyId: response.user?.companyId,
        });
      } else {
        localStorage.clear();
        navigate("/login");
        console.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userId") && !user.name) getUser();
    if (!localStorage.getItem("userId")) navigate("/login");
  }, []);

  useEffect(() => {
    console.log(user);
    setConfirm(false);
  }, [location.pathname, user]);

  return (
    <ChakraProvider>
      <div className="w-full overflow-hidden min-h-[100vh] relative bg-white">
        {/* <Router> */}
        {(location.pathname === "/login" ||
          location.pathname === "/create-password" ||
          location.pathname === "/reset-password") && <Header />}
        <Routes>
          <Route
            path="/"
            element={!userId ? <Login /> : <Navigate to="/dashboard" replace />}
          />
          <Route
            path="/login"
            element={!userId ? <Login /> : <Navigate to="/dashboard" replace />}
          />
          <Route
            path="/create-password"
            element={
              !userId ? (
                <CreatePassword />
              ) : (
                <Navigate to="/dashboard" replace />
              )
            }
          />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route
            path="/create-maintenance-contractor"
            element={<CreateAccount />}
          />

          {user.company === "Owner" && (
            <Route
              element={<ProtectedRoute userId={userId} redirectPath="/login" />}
            >
              <Route path="/dashboard" element={<OwnerDashboard />} />
              <Route path="/users" element={<OwnerDashboard />} />
              <Route path="/users/add-user" element={<OwnerDashboard />} />
              <Route
                path="/management-companies/add-management-company"
                element={<OwnerDashboard />}
              />
              <Route
                path="/individual-managers/add-individual-manager"
                element={<OwnerDashboard />}
              />
              <Route
                path="/maintenance-contractors/add-maintenance-company"
                element={<OwnerDashboard />}
              />
              <Route
                path="/maintenance-contractors"
                element={<OwnerDashboard />}
              />
              <Route
                path="/maintenance-contractors/:id"
                element={<OwnerDashboard />}
              />
              <Route
                path="/management-companies"
                element={<OwnerDashboard />}
              />
              <Route
                path="/management-companies/:id"
                element={<OwnerDashboard />}
              />
              <Route
                path="/management/add-user/:id"
                element={<OwnerDashboard />}
              />
              <Route
                path="/maintenance/add-user/:id"
                element={<OwnerDashboard />}
              />
              <Route
                path="/management/update-user/:userId"
                element={<OwnerDashboard />}
              />
              <Route
                path="/maintenance/update-user/:userId"
                element={<OwnerDashboard />}
              />
              <Route path="/individual-managers" element={<OwnerDashboard />} />
              <Route
                path="/individual-managers/:id"
                element={<OwnerDashboard />}
              />
              {/* Created by rohit */}
              <Route path="/profile" element={<MyProfile />} />
            </Route>
          )}

          {user.company === "Management" && (
            <Route
              element={<ProtectedRoute userId={userId} redirectPath="/login" />}
            >
              {" "}
              <Route path="/dashboard" element={<ManagementDashboard />} />
              <Route path="/users" element={<ManagementDashboard />} />
              <Route path="/users/add-user" element={<ManagementDashboard />} />
              <Route
                path="/maintenance-contractors/add-maintenance-company"
                element={<ManagementDashboard />}
              />
              <Route
                path="/maintenance-contractors"
                element={<ManagementDashboard />}
              />
              <Route
                path="/maintenance-contractors/:id"
                element={<ManagementDashboard />}
              />
              <Route
                path="/management/update-user/:userId"
                element={<ManagementDashboard />}
              />
              <Route
                path="/maintenance-contractor-users/add-user"
                element={<ManagementDashboard />}
              />
              <Route path="/communities" element={<ManagementDashboard />} />
              <Route
                path="/communities/add-user"
                element={<ManagementDashboard />}
              />
              <Route
                path="/communities/add-community"
                element={<ManagementDashboard />}
              />
              <Route
                path="/communities/:id"
                element={<ManagementDashboard />}
              />
              <Route path="/assignment" element={<ManagementDashboard />} />
              <Route path="/assignment/:id" element={<ManagementDashboard />} />
              <Route
                path="/create-assignment"
                element={<ManagementDashboard />}
              />
              <Route
                path="/edit-assignment/:id"
                element={<ManagementDashboard />}
              />
              <Route path="/map-assignment" element={<ManagementDashboard />} />
              <Route path="/profile" element={<MyProfile />} />
              <Route path="/diary" element={<ManagementDashboard />} />
              <Route path="/reports" element={<ManagementDashboard />} />
            </Route>
          )}

          {user.company === "Individual" && (
            <Route
              element={<ProtectedRoute userId={userId} redirectPath="/login" />}
            >
              <Route path="/dashboard" element={<IndividualDashboard />} />
              <Route path="/users" element={<IndividualDashboard />} />
              <Route path="/users/add-user" element={<IndividualDashboard />} />
              <Route path="/assignment" element={<IndividualDashboard />} />
              <Route path="/assignment/:id" element={<ManagementDashboard />} />
              <Route
                path="/create-assignment"
                element={<IndividualDashboard />}
              />
              <Route
                path="/edit-assignment/:id"
                element={<IndividualDashboard />}
              />
              <Route path="/map-assignment" element={<IndividualDashboard />} />
              <Route
                path="/maintenance-contractors/add-maintenance-company"
                element={<ManagementDashboard />}
              />
              <Route
                path="/maintenance-contractors"
                element={<ManagementDashboard />}
              />
              <Route
                path="/maintenance-contractors/:id"
                element={<ManagementDashboard />}
              />
              <Route path="/create-assignment" element={<CreateAssignment />} />
              <Route path="/communities" element={<ManagementDashboard />} />
              <Route
                path="/communities/add-community"
                element={<ManagementDashboard />}
              />
              <Route
                path="/communities/add-user"
                element={<ManagementDashboard />}
              />
              <Route
                path="/communities/:id"
                element={<ManagementDashboard />}
              />

              <Route path="/assignment" element={<ManagementDashboard />} />
              <Route path="/assignment/:id" element={<ManagementDashboard />} />

              <Route path="/reports" element={<ManagementDashboard />} />

              <Route path="/profile" element={<MyProfile />} />
            </Route>
          )}

          {user.company === "Maintenance" && (
            <Route
              element={<ProtectedRoute userId={userId} redirectPath="/login" />}
            >
              <Route path="/dashboard" element={<MaintenanceDashboard />} />
              <Route path="/users" element={<MaintenanceDashboard />} />
              <Route
                path="/users/add-user"
                element={<MaintenanceDashboard />}
              />
              <Route
                path="/parent-companies"
                element={<MaintenanceDashboard />}
              />
              <Route
                path="/maintenance/update-user/:userId"
                element={<MaintenanceDashboard />}
              />
              <Route path="/assignment" element={<MaintenanceDashboard />} />
              <Route
                path="/assignment/:id"
                element={<MaintenanceDashboard />}
              />
              <Route
                path="/assignment/:id"
                element={<MaintenanceDashboard />}
              />
              <Route
                path="/assignment-requests"
                element={<MaintenanceDashboard />}
              />
              <Route path="/reports" element={<MaintenanceDashboard />} />
              <Route path="/profile" element={<MyProfile />} />
            </Route>
          )}

          {user.company === "Read-Only" && (
            <Route
              element={<ProtectedRoute userId={userId} redirectPath="/login" />}
            >
              <Route path="/dashboard" element={<CommunityUserDashboard />} />
              <Route path="/community" element={<CommunityUserDashboard />} />
              <Route
                path="/community/:id"
                element={<CommunityUserDashboard />}
              />
              <Route path="/reports" element={<CommunityUserDashboard />} />
            </Route>
          )}
        </Routes>
        {(location.pathname === "/login" ||
          location.pathname === "/create-password" ||
          location.pathname === "/reset-password") && <Footer />}
        {/* </Router> */}
      </div>
    </ChakraProvider>
  );
}

export default App;
