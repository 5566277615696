import React, { useEffect, useState } from "react";
import ErrorIcon from "../Assets/Icons/ErrorIcon";
import SuccessIcon from "../Assets/Icons/SuccessIcon";

interface CheckProps {
  password: string;
  setOkPass: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PasswordCheck({ password, setOkPass }: CheckProps) {
  useEffect(() => {
    if (
      password.length >= 8 &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|-]/.test(password)
    )
      setOkPass(true);
    else setOkPass(false);
  }, [password]);

  return (
    <div className={`flex-col ${password !== "" ? "flex" : "hidden"} items-start -mt-5 mb-5`}>
      <span
        className={`flex flex-row items-center gap-1 text-gray-dark text-sm m-1.5`}
      >
        Password must have:
      </span>
      <span
        className={`${
          password.length >= 8 ? "text-green-primary " : "text-[#D51D14]"
        } flex flex-row items-center gap-1 text-sm m-1.5`}
      >
        {password.length >= 8 ? <SuccessIcon /> : <ErrorIcon />}
        Eight or more characters
      </span>
      <span
        className={`${
          /[a-z]/.test(password) && /[A-Z]/.test(password)
            ? "text-green-primary "
            : "text-[#D51D14]"
        } flex flex-row items-center gap-1 text-[#D51D14] text-sm m-1.5`}
      >
        {/[a-z]/.test(password) && /[A-Z]/.test(password) ? (
          <SuccessIcon />
        ) : (
          <ErrorIcon />
        )}
        Atleast one uppercase and one lowercase letter
      </span>
      <span
        className={`${
          /\d/.test(password) ? "text-green-primary " : "text-[#D51D14]"
        } flex flex-row items-center gap-1 text-[#D51D14] text-sm m-1.5`}
      >
        {/\d/.test(password) ? <SuccessIcon /> : <ErrorIcon />}
        Atleast one number
      </span>
      <span
        className={`${
          /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|-]/.test(password)
            ? "text-green-primary "
            : "text-[#D51D14]"
        } flex flex-row items-center gap-1 text-[#D51D14] text-sm m-1.5`}
      >
        {/[!@#$%^&*()_+{}\[\]:;<>,.?~\\|-]/.test(password) ? (
          <SuccessIcon />
        ) : (
          <ErrorIcon />
        )}
        Atleast one special symbol
      </span>
    </div>
  );
}
