import { createContext, useState } from "react";
import { AssignmentData } from "../Components/AssignmentCard";

export interface User {
  name: string;
  email: string;
  phone: string;
  company: string;
  companyName: string;
  companyId: string;
  authority: string;
  firstName?: string;
  lastName?: string;
}

export interface ReportFilterObj {
  maintenanceCompany?: string[];
  managementCompany?: string[];
  communityId?: string;
  openedOn?:
    | {
        range: { from: number; to: number };
      }
    | "L1M"
    | "L7D";
  closedOn?:
    | {
        range: { from: number; to: number };
      }
    | "L1M"
    | "L7D";
}

export interface ImageFile {
  file: File;
  location: any;
}

export const UserContext = createContext({
  user: {} as User,
  setUser: (value: User) => {},

  editUsers: [] as any[],
  setEditUsers: (value: any[]) => {},

  addUser: false as boolean,
  setAddUser: (value: boolean) => {},

  tableTitle: "" as string,
  setTableTitle: (value: string) => {},

  layer: 0 as number,
  setLayer: (value: number) => {},

  verified: false as boolean,
  setVerified: (value: boolean) => {},

  confirm: false as boolean,
  setConfirm: (value: boolean) => {},

  communityId: "" as string,
  setCommunityId: (value: string) => {},

  images: [] as ImageFile[],
  setImages: (value: ImageFile[]) => {},

  location: [] as any,
  setLocation: (value: any) => {},

  attachments: [] as File[],
  setAttachments: (value: File[]) => {},

  assignment: {} as AssignmentData,
  setAssignment: (value: AssignmentData) => {},

  reportFilter: {} as ReportFilterObj,
  setReportFilter: (value: ReportFilterObj) => {},

  openSidebar: false as boolean,
  setOpenSidebar: (value: boolean) => {},
});

export const UserProvider = (props: any) => {
  const [user, setUser] = useState<User>({} as User);
  const [editUsers, setEditUsers] = useState<any[]>([]);
  const [addUser, setAddUser] = useState<boolean>(false);
  const [tableTitle, setTableTitle] = useState<string>("");
  const [verified, setVerified] = useState<boolean>(false);
  const [layer, setLayer] = useState<number>(0);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [communityId, setCommunityId] = useState<string>("");

  const [images, setImages] = useState<ImageFile[]>([]);
  const [location, setLocation] = useState<any>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [assignment, setAssignment] = useState({} as AssignmentData);

  const [reportFilter, setReportFilter] = useState({} as ReportFilterObj);

  const [openSidebar, setOpenSidebar] = useState<boolean>(true);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        editUsers,
        setEditUsers,
        addUser,
        setAddUser,
        tableTitle,
        setTableTitle,
        verified,
        setVerified,
        layer,
        setLayer,
        confirm,
        setConfirm,
        communityId,
        setCommunityId,
        images,
        setImages,
        location,
        setLocation,
        attachments,
        setAttachments,
        assignment,
        setAssignment,
        reportFilter,
        setReportFilter,
        openSidebar,
        setOpenSidebar,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
