import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
} from "@chakra-ui/react";
import CustomButton from "../Button";
import { useContext, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import { CustomInput } from "../Input";
import { chakra, useDisclosure } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { AssignmentData } from "../AssignmentCard";
import { CreateDiaryAPI, UpdateDiaryAPI } from "../../Utils/ADD";
import toast from "react-hot-toast";
import DatePicker from "./Datepicker";

interface ModalProps {
  isOpen: any;
  onClose: any;
  assignment: AssignmentData;
  setAssignment: any;
}

export default function DiaryModal({
  isOpen,
  onClose,
  assignment,
  setAssignment,
}: ModalProps) {
  const { confirm, setConfirm } = useContext(UserContext);
  const methods = useForm();

  const [loading, setLoading] = useState(false);
  const [diaryDate, setDiaryDate] = useState<string>(new Date().toISOString());

  const createDiary = async (data: any) => {
    setLoading(true);
    let diaryData = {
      assignmentId: assignment._id,
      diaryNote: data.diaryNote,
      diaryDate: diaryDate,
    };
    try {
      const response = await CreateDiaryAPI(diaryData);
      console.log("create diary", response);
      if (response.status === 200) {
        setLoading(false);
        const newDiaryData: any = { ...response?.message?.data?.newDiary };
        setAssignment((prev: AssignmentData) => {
          return { ...prev, diaryData: newDiaryData };
        });

        toast.success("Diary created successfully");
      } else {
        setLoading(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      onClose();
    }
  };

  const updateDiary = async (data: any) => {
    setLoading(true);
    let diaryData = {
      diaryId: assignment?.diaryData?._id,
      diaryNote: data.diaryNote,
      diaryDate: diaryDate,
    };
    try {
      const response = await UpdateDiaryAPI(diaryData);
      if (response.status === 201) {
        console.log("diary response", response);
        const newDiaryData: any = { ...response?.message?.data?.existingDiary };

        setAssignment((prev: AssignmentData) => {
          return { ...prev, diaryData: newDiaryData };
        });

        toast.success(response?.message?.message);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      onClose();
    }
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    assignment?.diaryData?.diaryDate ? updateDiary(data) : createDiary(data);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"3xl"} isCentered>
      <ModalOverlay />
      <ModalContent shadow={"none"} bg={"transparent"}>
        <div className="w-[90%] lg:w-full relative bg-white mx-auto shadow-lg">
          <ModalHeader
            display="flex"
            justifyContent={"center"}
            className="flex-col"
          >
            <span className="text-gray-dark text-sm lg:text-2xl text-medium">
              {assignment.subject}
            </span>
            <span className="text-gray-medium text-sm lg:text-base font-light">
              {assignment._id}
            </span>
          </ModalHeader>
          <CloseButton
            position={"absolute"}
            top={2}
            right={5}
            onClick={onClose}
          />
          <FormProvider {...methods}>
            <chakra.form
              autoComplete="off"
              className="w-full flex flex-col items-end gap-2 lg:gap-9"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <ModalBody w={"full"} py={6}>
                <div className="w-full flex lg:flex-row flex-col gap-10">
                  <div className="lg:min-w-[16rem] lg:min-h-[16rem]">
                    <DatePicker setDiaryDate={setDiaryDate} />
                  </div>

                  <div className="w-full flex flex-col">
                    <div className="flex flex-col items-start gap-2 mb-8">
                      <span className="text-gray-dark text-[1rem] lg:text-[1.2rem]">
                        Client Community
                      </span>
                      <span className="w-full h-11 flex items-center px-4 border-[1px] border-[#DFDFDF] bg-transparent rounded-lg text-[1rem]">
                        Community Name
                      </span>
                    </div>
                    <div className="flex flex-col items-start gap-2 mb-8">
                      <span className="text-gray-dark text-[1rem] lg:text-[1.2rem]">
                        Maintenance Contractor
                      </span>
                      <div className="w-full h-11 flex items-center px-4 border-[1px] border-[#DFDFDF] bg-transparent rounded-lg lg:text-[1.2rem] text-[1rem]">
                        <span className="w-[90%] truncate">
                          {assignment?.maintenanceCompanyData
                            ? assignment?.maintenanceCompanyData[0].companyName
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <CustomInput
                      type="text"
                      name="diaryNote"
                      label="Diary Note"
                      placeholder="Eg: Merit Management"
                      value={assignment?.diaryData?.diaryNote ?? null}
                      isRequired={true}
                    />
                  </div>
                </div>
              </ModalBody>

              <ModalFooter>
                <div className="w-full flex justify-end items-center gap-5">
                  <div
                    onClick={() => {
                      setConfirm(true);
                      // onClose();
                    }}
                  >
                    <CustomButton
                      w="w-36"
                      colorScheme="blue"
                      loading={loading}
                      text="Diary"
                    />
                  </div>
                </div>
              </ModalFooter>
            </chakra.form>
          </FormProvider>
        </div>
      </ModalContent>
    </Modal>
  );
}
