export default function PdfIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="48"
      fill="none"
      viewBox="0 0 40 48"
    >
      <path
        fill="#ACACAC"
        d="M31.282 0H14.098l-.874.873L1.151 12.947l-.873.873v26.127C.278 44.387 3.89 48 8.331 48h22.95c4.44 0 8.052-3.613 8.052-8.053V8.053C39.333 3.612 35.721 0 31.282 0zm5.07 39.947a5.07 5.07 0 01-5.07 5.071H8.33a5.07 5.07 0 01-5.072-5.07V15.054h7.848a4.226 4.226 0 004.227-4.225V2.982h15.948c2.8 0 5.07 2.27 5.07 5.07v31.895z"
      ></path>
      <path
        fill="#ACACAC"
        d="M11.923 23.7H9.25c-.51 0-.815.33-.815.827v6.914c0 .599.394.994.942.994.535 0 .93-.395.93-.994v-2.1c0-.052.025-.077.076-.077h1.541c1.884 0 3.018-1.146 3.018-2.776 0-1.656-1.12-2.789-3.018-2.789zm-.115 3.946h-1.426c-.05 0-.077-.025-.077-.076v-2.177c0-.052.026-.077.077-.077h1.426c.79 0 1.26.471 1.26 1.172 0 .7-.47 1.158-1.26 1.158zM19.258 23.7h-2.05c-.51 0-.814.33-.814.827v7.016c0 .497.305.815.814.815h2.05c1.846 0 2.993-.586 3.451-2 .165-.496.256-1.094.256-2.33 0-1.235-.09-1.833-.256-2.33-.458-1.413-1.605-1.999-3.45-1.999zm1.656 6.01c-.217.687-.84.98-1.707.98h-.865c-.051 0-.077-.025-.077-.076v-5.17c0-.051.026-.077.077-.077h.865c.867 0 1.49.293 1.707.98.09.294.152.803.152 1.682 0 .878-.063 1.387-.152 1.68zM29.66 23.7h-4.2c-.51 0-.816.33-.816.827v6.914c0 .599.395.994.943.994.534 0 .929-.395.929-.994v-2.47c0-.05.025-.076.076-.076h2.445c.548 0 .866-.331.866-.803 0-.471-.318-.802-.866-.802h-2.445c-.051 0-.076-.025-.076-.076v-1.821c0-.052.025-.077.076-.077h3.069c.522 0 .866-.344.866-.802 0-.471-.344-.815-.867-.815z"
      ></path>
    </svg>
  );
}
