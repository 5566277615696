import { AssignmentData } from "../AssignmentCard";

export default function DiaryNotes({ assignment }: { assignment: AssignmentData }) {
  return (
    <div className="w-full flex flex-col gap-2">
      <span className="text-base text-gray-medium">Diary Notes</span>
      <span className="text-black text-xs">
        {assignment.diaryData?.diaryNote ?? "-"}
      </span>
    </div>
  );
}
