export default function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#55A8FD"
        stroke="#55A8FD"
        strokeWidth="0.3"
        d="M23.358.87h0a2.156 2.156 0 00-.861.322 3.4 3.4 0 00-.335.287 23.25 23.25 0 00-.55.535h0l-.685.687-.105.106.106.106 1.962 1.963h0l1.962 1.964.107.107.106-.107.714-.719h0c.274-.277.46-.469.589-.61.128-.141.205-.238.255-.323h0c.355-.607.38-1.282.075-1.919h0l-.122-.25-.01-.023-.019-.018-.887-.885h0M23.358.87l1.087.336c.218.105.25.134 1.109.993l.106-.106M23.358.87c.4-.046.785.023 1.152.2m-1.152-.2l1.152.2m1.15 1.023a26.102 26.102 0 00-.8-.785 1.244 1.244 0 00-.35-.237m1.15 1.022L24.51 1.07M1.02 3.825a1.61 1.61 0 01.153-.27c.058-.08.127-.153.224-.249h0c.096-.095.17-.163.25-.22.08-.06.161-.104.268-.155a2.31 2.31 0 01.468-.173h.001a2.65 2.65 0 01.36-.022c.204-.006.519-.01 1.003-.012.97-.006 2.622-.008 5.43-.009l6.578-.003h.15v2.091H2.893V24.89H22.98V11.957h2.171v.15l-.006 6.617v0l-.008 6.619v.022l-.006.022-.06.197h0c-.216.693-.7 1.18-1.396 1.395h0l-.198.06-.021.007h-.022l-10.313.008c-3.66.003-6.254.003-7.953 0a345.288 345.288 0 01-2.385-.014 2.839 2.839 0 01-.215-.008l.02-.149-.02.149a2.016 2.016 0 01-1.674-1.377h0v-.001l-.071-.21-.008-.024v-.025L.807 14.978.805 12.43C.802 8.322.8 6.271.82 5.216c.011-.623.03-.912.062-1.075a.634.634 0 01.07-.196l.03-.053a.655.655 0 00.039-.067zm0 0s0 0 0 0l.135.065m-.135-.065v-.001l.135.066m0 0c.1-.211.155-.287.347-.477L.965 25.395.957 14.978C.95 4.63.935 4.271 1.087 4.01c.02-.034.042-.066.068-.12z"
      ></path>
      <path
        fill="#55A8FD"
        stroke="#55A8FD"
        strokeWidth="0.3"
        d="M10.473 13.373l-.107-.106.107-.106 4.543-4.544a1728.568 1728.568 0 014.202-4.185 54.168 54.168 0 01.378-.367.158.158 0 01.029-.019h0a.15.15 0 01.067-.016c.034 0 .059.012.064.014h0a.156.156 0 01.032.02l.016.013.036.033c.03.027.07.067.121.116a317.075 317.075 0 011.813 1.797l1.95 1.95.105.105-.106.106-4.557 4.557-4.557 4.556-.106.107-.106-.107-1.962-1.962-1.962-1.962zM7.984 20.397h0l-.105-2.217s0 0 0 0c.284-.886.458-1.439.56-1.784.053-.173.086-.292.106-.374a.73.73 0 00.025-.13c0-.38.128-.791.344-1.113a2.936 2.936 0 00.107-.176.182.182 0 01.029-.07.168.168 0 01.139-.073.151.151 0 01.09.029l.01.008.014.012.034.033c.028.027.07.066.12.117l.434.427c.363.36.862.857 1.409 1.403.89.892 1.387 1.39 1.653 1.668.132.137.212.225.256.281a.356.356 0 01.057.094.167.167 0 010 .114.159.159 0 01-.103.097h.002l-.013.006a1.246 1.246 0 00-.13.081h0a2.057 2.057 0 01-1.115.355h-.006c-.05 0-.09 0-.576.148-.498.153-1.45.458-3.341 1.064z"
      ></path>
    </svg>
  );
}
