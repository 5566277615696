export default function LogoutIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      className={className}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.045.629c.47-.137.967-.165 1.45-.083a3.2 3.2 0 011.335.558c.394.284.714.654.934 1.08.221.425.336.894.336 1.37v14.891c0 .477-.115.946-.335 1.372-.22.425-.54.795-.935 1.079-.394.284-.85.475-1.334.558-.484.082-.98.054-1.451-.083l-6.3-1.833a3.152 3.152 0 01-1.622-1.102A2.994 2.994 0 01.5 16.613V5.386c0-.657.219-1.296.623-1.823a3.151 3.151 0 011.622-1.102l6.3-1.833zm5.105 2.225c0-.27.11-.53.307-.72.197-.191.464-.299.743-.299h3.15a3.2 3.2 0 012.227.895c.591.573.923 1.35.923 2.16V5.91c0 .27-.11.529-.308.72-.196.19-.464.298-.742.298-.278 0-.546-.107-.742-.298a1.003 1.003 0 01-.308-.72V4.89c0-.27-.11-.529-.308-.72a1.067 1.067 0 00-.742-.298H15.2c-.278 0-.546-.107-.742-.298a1.003 1.003 0 01-.308-.72zm6.3 12.219c.278 0 .546.107.742.298.197.191.308.45.308.72v1.018c0 .81-.332 1.588-.923 2.16a3.2 3.2 0 01-2.227.895H15.2c-.278 0-.546-.107-.742-.298a1.003 1.003 0 01-.308-.72c0-.27.11-.53.307-.72.197-.191.464-.298.743-.298h3.15c.278 0 .546-.108.742-.299.197-.19.308-.45.308-.72v-1.018c0-.27.11-.529.308-.72.196-.19.464-.298.742-.298zM7.85 9.982c-.278 0-.546.107-.742.298-.197.19-.308.45-.308.72 0 .27.11.529.308.72.196.19.464.298.742.298h.001c.279 0 .546-.107.743-.298.196-.191.307-.45.307-.72 0-.27-.11-.53-.307-.72a1.067 1.067 0 00-.743-.298H7.85zM17.606 8.244a1.073 1.073 0 011.485 0l2.1 2.036.002.002a1.014 1.014 0 01.305.718.987.987 0 01-.305.718l-.002.002-2.1 2.037a1.073 1.073 0 01-1.485 0 .997.997 0 010-1.44l.307-.299h-2.715c-.58 0-1.05-.456-1.05-1.018s.47-1.018 1.05-1.018h2.715l-.307-.298a.997.997 0 010-1.44z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
