import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={28}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.832 4.664H4.665a2.333 2.333 0 0 0-2.333 2.333v16.334a2.333 2.333 0 0 0 2.333 2.333h16.333a2.333 2.333 0 0 0 2.334-2.333v-8.167"
      stroke="#55A8FD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.582 2.912a2.475 2.475 0 1 1 3.5 3.5L13.998 17.496l-4.666 1.166 1.166-4.666L21.582 2.912Z"
      stroke="#55A8FD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
