import React, { useEffect, useState } from "react";
import { CloseButton, FormControl, FormLabel, theme } from "@chakra-ui/react";
import { useFormContext, FieldValues, Controller } from "react-hook-form";
import HideIcon from "../Assets/Icons/HideIcon";
import ShowIcon from "../Assets/Icons/ShowIcon";
import ErrorIcon from "../Assets/Icons/ErrorIcon";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ArrowDown from "../Assets/Icons/ArrowDown";
import CloseIcon from "../Assets/Icons/CloseIcon";
import { useClickOutside } from "../hooks/useClickOutsite";

interface InputProps {
  type: string;
  name: string;
  label: string;
  placeholder: string;
  isRequired: boolean;
  Icon?: any;
  themeColor?: string;
  value?: string;
  [key: string]: any;
}

interface Validator {
  required?: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
  minLength?: {
    value: number;
    message: string;
  };
}

export const CustomInput = ({
  type,
  name,
  label,
  placeholder,
  isRequired,
  Icon,
  themeColor,
  value,
  ...rest
}: InputProps) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<FieldValues>();

  const [showPass, setShowPass] = useState(false);
  const [validator, setValidator] = useState<Validator>({});

  const [border, setBorder] = useState("focus-within:border-[#55A8FD]");
  const [text, setText] = useState("group-focus-within:text-[#55A8FD]");

  useEffect(() => {
    let validatorLocal: Validator = {};
    if (isRequired) validatorLocal.required = `${label} is Required`;
    switch (type) {
      case "email":
        validatorLocal.pattern = {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "Invalid Email Address",
        };
        break;
      case "password":
        Icon = HideIcon;
        validatorLocal.minLength = {
          value: 8,
          message: "Password must have at least 8 characters",
        };
        break;
    }
    setValidator(validatorLocal);

    if (themeColor) {
      switch (themeColor) {
        case "green":
          setBorder("focus-within:border-green-primary");
          setText("group-focus-within:text-green-primary");
          break;
        default:
          setBorder("focus-within:border-[#55A8FD]");
          setText("group-focus-within:text-[#55A8FD]");
          break;
      }
    }
    if (value) setValue(name, value);
  }, [value]);

  return (
    <FormControl isRequired={isRequired}>
      <FormLabel className="text-gray-dark text-sm sm:text-base xl:text-xl">
        {label}
      </FormLabel>
      <div
        className={`group w-full h-11 px-4 mt-1 bg-transparent border-[1px] ${
          errors[name] ? "border-[#D51D14]" : `border-[#DFDFDF] ${border}`
        } rounded-lg flex flex-row items-center`}
      >
        <input
          title=""
          {...register(name, validator)}
          {...rest}
          type={type === "password" ? (showPass ? "text" : "password") : type}
          placeholder={placeholder}
          className="flex flex-1 bg-transparent text-black"
        />
        {Icon && (
          <div
            onClick={() => {
              type === "password" && setShowPass(!showPass);
            }}
            className={`text-gray-light cursor-pointer flex items-center transition-all w-6 h-6 ${text}`}
          >
            {type === "password" ? (
              showPass ? (
                <ShowIcon className={text} />
              ) : (
                <HideIcon className={text} />
              )
            ) : (
              <Icon className={`w-6 h-6 ${text}`} />
            )}
          </div>
        )}
      </div>

      <span
        className={`${
          errors[name] ? "opacity-100" : "opacity-0"
        } flex flex-row items-center gap-1 text-[#D51D14] text-sm m-1.5`}
      >
        <ErrorIcon />
        {errors[name] ? errors[name]!.message!.toString() : "NONE"}
      </span>
    </FormControl>
  );
};

export const CustomPhoneInput = ({
  name,
  label,
  placeholder,
  isRequired,
  icon,
  ...rest
}: InputProps) => {
  const { control, trigger, formState, register } =
    useFormContext<FieldValues>();
  const { errors } = formState;

  const [validator, setValidator] = useState<Validator>({});

  useEffect(() => {
    let validatorLocal: Validator = {};
    if (isRequired) validatorLocal.required = `${label} is Required`;
    setValidator(validatorLocal);
  }, []);

  return (
    <FormControl isRequired={isRequired}>
      <FormLabel className="text-gray-dark text-sm sm:text-base xl:text-xl">
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <PhoneInput
            {...field}
            inputProps={{
              name,
              id: name,
              ref: field.ref,
            }}
            onChange={(e) => {
              // trigger(name);
              field.onChange(e);
            }}
            country={"us"}
            onlyCountries={["us", "in"]}
            placeholder={placeholder}
            containerClass="phone-container"
            inputClass={errors[name] ? "error-phone-input" : "phone-input"}
            value={field.value}
          />
        )}
        rules={{ required: `${label} is Required` }}
      />
      <span
        className={`${
          errors[name] ? "opacity-100" : "opacity-0"
        } flex flex-row items-center gap-1 text-[#D51D14] text-sm m-1.5`}
      >
        <ErrorIcon />
        {errors[name] ? errors[name]!.message!.toString() : "NONE"}
      </span>
    </FormControl>
  );
};

interface DropdownProps {
  options: String[];
  //   selected: string;
  //   setSelected: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  name: string;
  placeholder: string;
  isRequired: boolean;
  showLabels?: boolean;
  search?: boolean;
  map?: string[];
  [key: string]: any;
}

export const DropdownInput = ({
  options,
  label,
  name,
  value,
  mapValue,
  placeholder,
  isRequired,
  showLabels,
  search,
  map,
  ...rest
}: DropdownProps) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<FieldValues>();

  const [searchTerm, setSearchTerm] = useState(value || "");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [validator, setValidator] = useState<Validator>({});
  let domNode: any = useClickOutside(() => {
    setShowResults(false);
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowResults(true);
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    if (inputValue === "") {
    }

    const results = options.filter((item: any) =>
      item.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSearchResults(search ?? true ? results.slice(0, 3) : options);
  };

  const showDropdown = (event: any) => {
    event.preventDefault();
    setShowResults(true);
    console.log("clicked arrow");
    if (searchResults.length === 0)
      setSearchResults(search ?? true ? options.slice(0, 3) : options);
  };

  // useEffect(() => {
  //   if (showResults) {
  //     //@ts-ignore
  //     document.addEventListener("click", function (event) {
  //       //@ts-ignore
  //       var targetId = event.target.id;
  //       console.log(event, "targetId");
  //       if (targetId !== "dropdown-result-box") {
  //         if (
  //           targetId !== "dropdown-search-box-1234" &&
  //           targetId !== "dropicon-search-box-1234"
  //         ) {
  //           console.log("Closing dropdown");
  //           setShowResults(false);
  //         }
  //       }
  //     });
  //   }
  // }, [showResults]);

  useEffect(() => {
    if (mapValue) setValue(name, mapValue);
    else setValue(name, value);

    if (value) setSearchTerm(value);

    let validatorLocal: Validator = {};
    if (isRequired) validatorLocal.required = `${label} is Required`;
    setValidator(validatorLocal);
  }, []);

  return (
    <FormControl isRequired={isRequired}>
      {(showLabels ?? true) && (
        <FormLabel
          // fontSize={{ base: "1.2rem", md: "1.2rem", "3xl": "2rem" }}
          className="text-gray-dark text-sm sm:text-base xl:text-xl"
        >
          {label}
        </FormLabel>
      )}
      <div className={`select-none w-full flex flex-col justify-start mb-0`}>
        <div
          className={`group relative w-full h-11 px-4 border-[1px] ${
            errors[name]
              ? "border-[#D51D14]"
              : "border-[#DFDFDF] focus-within:border-[#55A8FD]"
          } bg-transparent rounded-lg flex items-center cursor-pointer`}
          // onClick={showDropdown}
          ref={domNode}
        >
          <input
            title=""
            id="dropdown-search-box-1234"
            type="text"
            {...register(name, validator)}
            {...rest}
            autoComplete="off"
            value={searchTerm}
            onChange={handleSearch}
            placeholder={placeholder}
            onClick={showDropdown}
            className="flex w-full bg-transparent text-black outline-none"
          />
          <div
            onClick={showDropdown}
            id="dropdown-search-box-1234"
            className="w-6 h-4 flex flex-row items-center justify-center"
          >
            <ArrowDown
              onClick={showDropdown}
              id="dropdown-search-box-1234"
              className="text-gray-dark w-3 h-3"
            />
          </div>
          {showResults && (
            <div
              className={`flex flex-col gap-1 overflow-x-auto sidebar w-full py-1 ${
                search ? "max-h-56" : "max-h-fit"
              } bg-transparent border-[1px] z-10 bg-white border-gray-light rounded-md absolute mt-2 left-0 top-full`}
            >
              {searchResults?.map((item, index) => (
                <span
                  key={index}
                  id="dropdown-result-box"
                  onClick={() => {
                    //   setSelected(item);
                    console.log(map, map && map[index]);
                    setSearchTerm(item);
                    setValue(name, map ? map[index] : item);
                    setShowResults(false);
                  }}
                  className="flex items-center gap-2 py-1 px-2 hover:bg-[#00000010] cursor-pointer"
                >
                  {item}
                  {/* <MdVerified className="text-blue-500" /> */}
                </span>
              ))}
            </div>
          )}
        </div>
        {(showLabels ?? true) && (
          <span
            className={`${
              errors[name] ? "opacity-100" : "opacity-0"
            } flex flex-row items-center gap-1 text-[#D51D14] text-sm m-1.5`}
          >
            <ErrorIcon />
            {errors[name] ? errors[name]!.message!.toString() : "NONE"}
          </span>
        )}
      </div>
    </FormControl>
  );
};

interface MultiSelectProps {
  options: String[];
  //   selected: string;
  //   setSelected: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  name: string;
  placeholder: string;
  isRequired: boolean;
  showLabels?: boolean;
  search?: boolean;
  map?: string[];
  [key: string]: any;
}

export const MultiSelectInput = ({
  options,
  label,
  name,
  values,
  mapValues,
  placeholder,
  isRequired,
  showLabels,
  search,
  map,
  ...rest
}: MultiSelectProps) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<FieldValues>();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [showResults, setShowResults] = useState(false);

  const [selected, setSelected] = useState<any[]>([]);
  const [selectedMap, setSelectedMap] = useState<any[]>([]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowResults(true);
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    if (inputValue === "") {
    }

    const results = options.filter((item: any) =>
      item.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSearchResults(options ? options : []);
  };

  const showDropdown = (event: any) => {
    event.preventDefault();
    setShowResults(true);
    console.log("jere2", searchResults);
    if (searchResults.length === 0) setSearchResults(options);
  };

  useEffect(() => {
    if (showResults) {
      //@ts-ignore
      document.addEventListener("click", function (event) {
        //@ts-ignore
        var targetId = event.target.id;
        if (targetId !== "dropdown-result-box") {
          if (
            targetId !== "dropdown-search-box-1234" &&
            targetId !== "dropicon-search-box-1234" &&
            targetId !== "multi-remove-item"
          ) {
            setShowResults(false);
          }
        }
      });
    }
  }, [showResults]);

  useEffect(() => {
    console.log(selected, selectedMap);
    setValue(name, map ? selectedMap : selected);
  }, [selected]);

  useEffect(() => {
    if (values) {
      setSelected(values);
    }
    if (mapValues) {
      setSelectedMap(mapValues);
      setValue(name, mapValues);
    } else {
      setValue(name, values);
    }
  }, []);
  console.log("input select", selected, values);
  return (
    <FormControl isRequired={isRequired}>
      {(showLabels ?? true) && (
        <FormLabel
          // fontSize={{ base: "1.2rem", md: "1.2rem", "3xl": "2rem" }}
          className="text-gray-dark text-sm sm:text-base xl:text-xl"
        >
          {label}
        </FormLabel>
      )}
      <div className={`select-none w-full flex flex-col justify-start mb-0`}>
        <div
          className={`group overflow-x-auto relative w-full h-10 mb-1 px-4 border-[1px] border-[#E6E6E6] focus-within:border-[#55A8FD] bg-transparent rounded-lg flex items-center gap-2 cursor-pointer`}
        >
          {selected?.map((item) => (
               <div className="relative">
              <div className="w-[8rem] truncate bg-gray-100 mx-1 p-1 pr-4 border-[#73B8FF33] border-[1px] rounded-2xl text-sm">{item} </div>
        
              <div   onClick={() => {
                  setSelected(
                    [...selected].filter((option) => option !== item)
                  );
                  if (map)
                    setSelectedMap(
                      [...selectedMap].filter((option) => option !== item)
                    );
                }} className="absolute cursor p-1 top-[6px] border-gray-600 border-[.5px] bg-gray-200 right-[8px] rounded-full"><CloseIcon className="w-2 h-2" /></div>

            </div>
          ))}
            {(selected.length >1)?(<div className="w-[1.8rem] h-[1.6rem] mt-[0.15rem]  ml-[10px] bg-gray-100 border-[#73B8FF33] border-[1px] flex pl-[2px] items-center rounded-full text-sm">+{selected.length - 1}</div>):('')}

        </div>
        <div
          className={`group relative w-full h-11 px-4 border-[8px] ${
            errors[name] ? "border-[#D51D14]" : "border-[#E6E6E6]"
          } bg-transparent rounded-lg flex items-center cursor-pointer`}
        >
          <input
            title=""
            id="dropdown-search-box-1234"
            type="text"
            autoComplete="off"
            value={searchTerm}
            onChange={handleSearch}
            placeholder={placeholder}
            onClick={showDropdown}
            className="flex w-full bg-transparent text-black outline-none"
          />
          <div onClick={showDropdown} id="dropicon-search-box-1234">
            <ArrowDown className="text-gray-dark w-3 h-3" />
          </div>
          {showResults && (
            <div
              className={`flex flex-col gap-1 w-full py-1 max-h-[300px] overflow-auto bg-transparent z-10 bg-white rounded-md absolute mt-4 left-0 top-full scrollbar shadow-[2px_2px_40px_1px_rgba(237,237,237,0.90)]`}
            >
              {searchResults?.map((item, index) => (
                <div
                  key={index}
                  id="dropdown-result-box"
                  onClick={() => {
                    let temp = [];
                    let tempMap: string[] = [];
                    if (selected.some((option) => option === item)) {
                      temp = [...selected].filter((option) => option !== item);
                      if (map)
                        tempMap = [...selectedMap].filter(
                          (option) => option !== item
                        );
                    } else {
                      temp = [...selected].concat([item]);
                      if (map) tempMap = [...selectedMap].concat([map[index]]);
                    }
                    setSelected(temp);
                    if (map) {
                      console.log(map, tempMap);
                      setValue(name, tempMap);
                      setSelectedMap(tempMap);
                    } else setValue(name, temp);
                  }}
                  className={`flex items-center ${
                    selected.some((option) => option === item)
                      ? "text-[#ACACAC]"
                      : "text-gray-dark"
                  } gap-2 py-1 px-2 hover:bg-[#55A8FD] cursor-pointer`}
                >
                  {item}
                  {/* <MdVerified className="text-blue-500" /> */}
                </div>
              ))}
            </div>
          )}
        </div>
        {(showLabels ?? true) && (
          <span
            className={`${
              errors[name] ? "opacity-100" : "opacity-0"
            } flex flex-row items-center gap-1 text-[#D51D14] text-sm m-1.5`}
          >
            <ErrorIcon />
            {errors[name] ? errors[name]!.message!.toString() : "NONE"}
          </span>
        )}
      </div>
    </FormControl>
  );
};
