import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Tab, chakra, FormControl, useDisclosure } from "@chakra-ui/react";

import { createColumnHelper } from "@tanstack/react-table";
import { Table, Checkbox } from "../../Components/Table";
import MenuDots from "../../Assets/Icons/MenuDots";
import PlusIcon from "../../Assets/Icons/PlusIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import FilterIcon from "../../Assets/Icons/FilterIcon";
import { UserContext } from "../../Context/UserContext";
import TrashIcon from "../../Assets/Icons/TrashIcon";
import { useNavigate } from "react-router-dom";
import {
  GetAllUsers,
  GetCommunities,
  GetParentCommunities,
  GetParentCompanies,
} from "../../Utils/GET";
import { Loader } from "../../Components/Loader";
import { DeleteUserAPI } from "../../Utils/ADD";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import toast from "react-hot-toast";
import NoData from "../../Components/NoData";
import RedirectIcon from "../../Assets/Icons/RedirectIcon";

type Company = {
  _id: string;
  communityName: string;
  communityPhoneNo: string;
  managementCompanyName: string;
  address: string;
};

export default function Communities() {
  const methods = useForm();
  const navigate = useNavigate();

  const { editUsers, setEditUsers, setTableTitle } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const onSubmit = async (data: any) => {
    console.log(data)
    setSearchTerm(data.searchQuery);
  };


  const columnHelper = createColumnHelper<Company>();

  const columns = [
    columnHelper.accessor("communityName", {
      header: () => (
        <span className="text-white text-base font-medium">Community Name</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor("address", {
      header: () => (
        <span className="text-white text-base font-medium">Address</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor("communityPhoneNo", {
      header: () => (
        <span className="text-white text-base font-medium">Phone Number</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue() ?? 0}
          </div>
        );
      },
    }),
    columnHelper.accessor("managementCompanyName", {
      header: () => (
        <span className="text-white text-base font-medium">Company Name</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()[0]}
          </div>
        );
      },
    }),
    {
      id: "redirect",
      // header: ({ table }: any) => (

      // ),
      cell: ({ row }: any) => (
        <div
          
          onClick={() => {
            console.log(row.original._id);
            setTableTitle(row.original.companyName);
            navigate(`/community/${row.original._id}`);
          }}
        >
           <span className="text-[#55A8FD] underline font-semibold">View Details</span>
          {/* <RedirectIcon className="w-4 h-4 text-[#353535] opacity-0 group-hover:opacity-100" /> */}
        </div>
      ),
    },
  ];

  const getCompanies = async () => {
    setLoading(true);
    let info = {
      page: 1,
      pageSize: 10,
    };
    console.log(info);
    try {
      const response = await GetParentCommunities(info);
      if (response.status === 200) {
        console.log(response.data);
        setCompanyList(response.data.communities);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.message ?? "Something went wrong");
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getCompanies();
  }, [searchTerm]);

  useEffect(() => {
    console.log(editUsers);
  }, [editUsers]);

  return (
    <div className="flex flex-1 flex-col p-10 items-center w-full gap-6 mt-16 h-[calc(100%-50px)] bg-[#FCFCFC]">
      <div className="flex w-full flex-1 flex-col max-h-[calc(100%-0px)] overflow-hidden p-4 items-center gap-6 bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]">
        <FormProvider {...methods}>
          <chakra.form
            className="w-full flex flex-col items-start"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {/* header  */}
            <div className="w-full flex justify-between">
              <span className="font-medium text-4xl mt-2 text-[#001124]">
                Client Communit
              </span>
              <div className="flex flex-row items-center w-fit gap-5">
                <FormControl isRequired={false}>
                  <div
                    className={`group w-full min-w-[20rem] h-11 pl-3 pr-0.5 bg-transparent border-[1px] border-[#73B8FF33] rounded flex flex-row items-center`}
                  >
                    <input
                      title="Search"
                      {...methods.register("searchQuery")}
                      type={"text"}
                      placeholder="Search"
                      className="flex flex-1 text-sm placeholder:text-sm bg-transparent text-black"
                    />
                    <SearchIcon className="w-11 h-11" />
                  </div>
                </FormControl>
              </div>
            </div>
          </chakra.form>
        </FormProvider>
        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader className="w-40 h-40" type="square" />
          </div>
        ) : companyList.length > 0 ? (
          <Table tableData={companyList} columns={columns} />
        ) : (
          <div className="w-full h-fit flex pl-[4.5rem] justify-start">
            <NoData route="" text="Management Company" />
          </div>
        )}
      </div>
    </div>
  );
}
