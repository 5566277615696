export default function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M19 11.001h-6v-6a1 1 0 00-2 0v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 000-2z"
      ></path>
    </svg>
  );
}
