export default function LocationArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path fill="#fff" d="M12 0L0 6l4.5 1.5L6 12l6-12z"></path>
    </svg>
  );
}
