import { useContext, useEffect, useState } from "react";
import { CustomInput, DropdownInput, MultiSelectInput } from "./Input";
import { useForm, FormProvider } from "react-hook-form";
import { chakra, useDisclosure } from "@chakra-ui/react";
import CustomButton from "./Button";
import { ReportFilterObj, UserContext } from "../Context/UserContext";
import toast from "react-hot-toast";

export function ReportFilter() {
  const methods = useForm();
  const [opened1, setOpened1] = useState<boolean | null>(null);
  const [closed1, setClosed1] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);

  const { reportFilter, setReportFilter } = useContext(UserContext);

  const onSubmit = async (data: any) => {
    console.log(data, opened1, closed1);
    let closedFrom = new Date(data.closedFrom).getTime();
    let closedTo = new Date(data.closedTo).getTime();
    let openFrom = new Date(data.openFrom).getTime();
    let openTo = new Date(data.openTo).getTime();
    if (openFrom && !openTo) {
      toast.error("Wrong date inputs.");
      return;
    }
    if (closedFrom && !closedTo) {
      toast.error("Wrong date inputs.");
      return;
    }
    if (openFrom && openTo && openFrom > openTo) {
      toast.error("Wrong date inputs.");
      return;
    }
    if (closedFrom && closedTo && closedFrom > closedTo) {
      toast.error("Wrong date inputs.");
      return;
    }
    // if (openFrom && closedFrom && openFrom > closedFrom){
    //   toast.error("Wrong date inputs.");
    //   return;
    // }
    let filterOptions: ReportFilterObj = {} as ReportFilterObj;
    if (opened1 !== null) {
      opened1
        ? (filterOptions.openedOn = "L1M")
        : (filterOptions.openedOn = "L7D");
    } else {
      if (openFrom && openTo)
        filterOptions.openedOn = { range: { from: openFrom, to: openTo } };
    }

    if (closed1 !== null) {
      closed1
        ? (filterOptions.openedOn = "L1M")
        : (filterOptions.openedOn = "L7D");
    } else {
      if (closedFrom && closedTo)
        filterOptions.openedOn = { range: { from: closedFrom, to: closedTo } };
    }
    setReportFilter(filterOptions);
  };

  const [openFromValue, setOpenFromValue] = useState("");
  const [openToValue, setOpenToValue] = useState("");
  const [closedFromValue, setClosedFromValue] = useState("");
  const [closedToValue, setClosedToValue] = useState("");

  useEffect(() => {
    console.log(opened1);
    if (opened1 === true) {
      setOpenFromValue("2023-11-01");
      setOpenToValue("2023-11-30");
    }
    if (opened1 === false) {
      setOpenFromValue("2023-11-23");
      setOpenToValue("2023-11-30");
    }
  }, [opened1]);

  useEffect(() => {
    if (closed1 === true) {
      setClosedFromValue("2023-11-01");
      setClosedToValue("2023-11-30");
    }
    if (closed1 === false) {
      setClosedFromValue("2023-11-23");
      setClosedToValue("2023-11-30");
    }
  }, [closed1]);

  console.log(openFromValue, openToValue);

  return (
    <div className="w-full flex flex-row items-center gap-5">
      <FormProvider {...methods}>
        <chakra.form
          autoComplete="off"
          className="w-full flex flex-col xl:flex-row items-center gap-5"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {/* opened box  */}
          <div className="w-full h-full flex flex-col justify-between p-4 sm:p-7 pb-0 gap-5 border border-[#EAEAEA] shadow-[2px_2px_40px_1px_rgba(237,237,237,0.90)]">
            {/* opened on  */}
            <div className="w-full flex flex-col items-start">
              <span className="text-black pb-2">Opened on</span>
              <div className="w-full flex border border-[#55A8FD] rounded overflow-hidden">
                <span
                  onClick={() => {
                    opened1 === null || opened1 === false
                      ? setOpened1(true)
                      : setOpened1(null);
                  }}
                  className={`w-full h-full cursor-pointer ${
                    opened1 === true
                      ? "bg-[#55A8FD] text-white"
                      : "bg-white text-[#55A8FD]"
                  } text-center py-2`}
                >
                  Last 1 Month
                </span>
                <div className="min-w-[1px] min-h-full bg-[#55A8FD]" />
                <span
                  onClick={() => {
                    opened1 === null || opened1 === true
                      ? setOpened1(false)
                      : setOpened1(null);
                  }}
                  className={`w-full h-full cursor-pointer ${
                    opened1 === false
                      ? "bg-[#55A8FD] text-white"
                      : "bg-white text-[#55A8FD]"
                  } text-center py-2`}
                >
                  Last 7 days
                </span>
              </div>
            </div>

            {/* from - to  */}
            <div className="w-full flex flex-col sm:flex-row gap-5">
              {/* from  */}
              <div className="w-full flex flex-col items-start">
                <span className="text-sm text-black">From</span>
                <CustomInput
                  type="date"
                  name="openFrom"
                  value={openFromValue}
                  label=""
                  placeholder="date"
                  isRequired={false}
                />
              </div>
              <div className="w-full flex flex-col items-start">
                <span className="text-sm text-black">To</span>
                <CustomInput
                  type="date"
                  name="openTo"
                  value={openToValue}
                  label=""
                  placeholder="date"
                  isRequired={false}
                />
              </div>
            </div>
          </div>

          {/* closed box  */}
          <div className="w-full h-full flex flex-col justify-between p-4 sm:p-7 pb-0 gap-5 border border-[#EAEAEA] shadow-[2px_2px_40px_1px_rgba(237,237,237,0.90)]">
            {/* closed on  */}
            <div className="w-full flex flex-col items-start">
              <span className="text-black pb-2">Closed on</span>
              <div className="w-full flex border border-[#55A8FD] rounded overflow-hidden">
                <span
                  onClick={() => {
                    closed1 === null || closed1 === false
                      ? setClosed1(true)
                      : setClosed1(null);
                  }}
                  className={`w-full h-full cursor-pointer ${
                    closed1 === true
                      ? "bg-[#55A8FD] text-white"
                      : "bg-white text-[#55A8FD]"
                  } text-center py-2`}
                >
                  Last 1 Month
                </span>
                <div className="min-w-[1px] min-h-full bg-[#55A8FD]" />
                <span
                  onClick={() => {
                    closed1 === null || closed1 === true
                      ? setClosed1(false)
                      : setClosed1(null);
                  }}
                  className={`w-full h-full cursor-pointer ${
                    closed1 === false
                      ? "bg-[#55A8FD] text-white"
                      : "bg-white text-[#55A8FD]"
                  } text-center py-2`}
                >
                  Last 7 days
                </span>
              </div>
            </div>

            {/* from - to  */}
            <div className="w-full flex flex-col sm:flex-row gap-5">
              {/* from  */}
              <div className="w-full flex flex-col items-start">
                <span className="text-sm text-black">From</span>
                <CustomInput
                  type="date"
                  name="closedFrom"
                  value={closedFromValue}
                  label=""
                  placeholder="date"
                  isRequired={false}
                />
              </div>
              <div className="w-full flex flex-col items-start">
                <span className="text-sm text-black">To</span>
                <CustomInput
                  type="date"
                  name="closedTo"
                  value={closedToValue}
                  label=""
                  placeholder="date"
                  isRequired={false}
                />
              </div>
            </div>
          </div>

          {/* dropdown box  */}
          <div className="w-full h-full flex flex-col justify-between">
            <div className="w-full flex flex-col items-end gap-2">
              <DropdownInput
                options={["Option A", "Option B", "Option C", "Option D"]}
                type="text"
                name="notificationPref"
                label=""
                placeholder="Maintenance Contractor"
                showLabels={false}
                isRequired={false}
              />
              <DropdownInput
                options={["Option A", "Option B", "Option C", "Option D"]}
                type="text"
                name="notificationPref"
                label=""
                placeholder="Client Contractor"
                showLabels={false}
                isRequired={false}
              />
              <DropdownInput
                options={["Option A", "Option B", "Option C", "Option D"]}
                type="text"
                name="notificationPref"
                label=""
                placeholder="Assigned Status"
                showLabels={false}
                isRequired={false}
              />
              <CustomButton
                colorScheme="blue"
                w={"w-full rounded-sm"}
                loading={loading}
                text="Apply Filter"
              />
            </div>
          </div>
        </chakra.form>
      </FormProvider>
    </div>
  );
}
