import React, {
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Button,
  IconButton,
  GridItem,
  Center,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

interface DatePickerProps {
  setDiaryDate: React.Dispatch<SetStateAction<string>>;
}

export default function DatePicker({ setDiaryDate }: DatePickerProps) {
  const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const currentDate = new Date();
  const [date, setDate] = useState(currentDate.toISOString());

  const [slct_month, setSlct_month] = useState(dayjs().format("MMMM"));

  useEffect(() => {
    setCurrentMonthIdx(dayjs().month());
  }, [dayjs().month()]);

  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIdx));
  }, [currentMonthIdx]);

  useEffect(() => {
    setCurrentMonthIdx(dayjs().month());
    setSlct_month(dayjs().format("MMMM"));
  }, []);

  function handlePrevMonth() {
    setCurrentMonthIdx(currentMonthIdx - 1);
  }
  function handleNextMonth() {
    setCurrentMonthIdx(currentMonthIdx + 1);
  }

  const month = dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM");
  const year = dayjs(new Date(dayjs().year(), currentMonthIdx)).format("YYYY");

  return (
    <Box w="100%">
      {/*header*/}
      <Box
        w="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton
          aria-label="prev-month"
          bg="white"
          borderRadius="30"
          onClick={handlePrevMonth}
          icon={<ChevronLeftIcon />}
        />
        <Text
          fontFamily="sans-serif"
          fontSize={{ base: "12px", sm: "18px" }}
          align="center"
        >
          {month}, {year}
        </Text>
        <IconButton
          aria-label="next-month"
          bg="white"
          borderRadius="30"
          onClick={handleNextMonth}
          icon={<ChevronRightIcon />}
        />
      </Box>
      {/*day letters*/}
      <Grid my={3} templateColumns="repeat(7, 1fr)" gap={"1"}>
        {currentMonth[0].map((day, i) => (
          <GridItem w="100%">
            <Text className="text-gray-dark text-center font-light text-xs">
              {day.format("dd").charAt(0)}
            </Text>
          </GridItem>
        ))}
      </Grid>
      {/*date matrix*/}
      <Grid templateColumns="repeat(7, 1fr)" gap={{ base: "0", xl: "2" }}>
        {currentMonth.map((row, i) => (
          <Fragment key={i}>
            {row.map((day, inx) => (
              <Center key={inx}>
                <Button
                  key={inx}
                  bg={
                    dayjs(date).format("D") == day.format("D") &&
                    month == slct_month
                      ? "#4285F4"
                      : "white"
                  }
                  _hover={{
                    bg:
                      dayjs(date).format("D") == day.format("D") &&
                      month == slct_month
                        ? "#2684FC"
                        : "blackAlpha.300",
                  }}
                  isDisabled={monthNo(month) == day.month() ? false : true}
                  w="25px"
                  pr="16px"
                  size="xs"
                  borderRadius="30"
                  onClick={(e) => {
                    console.log(dayjs(day.toISOString()).format("D"));
                    setDate(day.toISOString());
                    setDiaryDate(day.toISOString());
                    setSlct_month(month);
                  }}
                >
                  <Text fontFamily="sans-serif" className="pl-2.5">
                    {day.format("D")}
                  </Text>
                </Button>
              </Center>
            ))}
          </Fragment>
        ))}
      </Grid>
    </Box>
  );
}

{
  /* retunrs 7x5 matrix of days of the month */
}
export function getMonth(month = dayjs().month()) {
  month = Math.floor(month);
  const year = dayjs().year();
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;
  const daysMatrix = new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
  return daysMatrix;
}

{
  /* returns month number 0-11 to text input */
}
export function monthNo(m: string) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var n = 0;
  for (let i = 0; i < 12; i++) {
    if (months[i] == m) return n;
    else n = n + 1;
  }
}
