import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Paperclip from "../Assets/Icons/Paperclip";

export default function DropzoneButton({ setAttachments }: any) {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    // noClick: true,
    // noKeyboard: true,
    accept: {
      "text/": [".xls", ".xlsx", ".pdf", ".doc", "docx", ".ppt", ".pptx"],
    },
    onDrop: () => {
      console.log("Dropped successfully");
    },
    onDropAccepted: () => {
      console.log("Drop Accepted");
    },
    onDropRejected: () => {
      console.log("Drop rejected");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (acceptedFiles[0])
      setAttachments((prev: File[]) => {
        return [...prev, acceptedFiles[0]];
      });
  }, [acceptedFiles]);

  // console.log(files)
  return (
    <div className="container cursor-pointer h-[24px] w-[24px]">
      <div
        {...getRootProps({
          className: "dropzone",
        })}
      >
        <input {...getInputProps()} />
        <Paperclip />
      </div>
      {/* <aside></aside> */}
    </div>
  );
}
