export default function SearchIcon({
  className,
  onClick,
}: {
  className: string;
  onClick?: () => void;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="34"
      fill="none"
      viewBox="0 0 39 34"
      className={className ?? "w-6 h-6 text-green-primary"}
      onClick={onClick}
    >
      <rect width="39" height="34" fill="#55A8FD" rx="3"></rect>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.5 24a8 8 0 100-16 8 8 0 000 16zM28.498 25.998l-4.35-4.35"
      ></path>
    </svg>
  );
}
