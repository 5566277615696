import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#a)"
      strokeWidth={1.536}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M17.652 14.588a1.536 1.536 0 0 1-1.536 1.536H2.292a1.536 1.536 0 0 1-1.536-1.536V6.141a1.536 1.536 0 0 1 1.536-1.536h3.072L6.9 2.3h4.608l1.536 2.304h3.072a1.536 1.536 0 0 1 1.536 1.536v8.447Z"
        fill="#959595"
        stroke="#959595"
      />
      <path
        d="M9.204 13.05a3.072 3.072 0 1 0 0-6.144 3.072 3.072 0 0 0 0 6.144Z"
        stroke="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18.431v18.431H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
