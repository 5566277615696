import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import AddManagementCompany from "../../Components/Forms/AddManagementCompany";
import IndividualManager from "../../Components/Forms/IndividualManager";
import PrimaryAdmin from "../../Components/Forms/PrimaryAdmin";
import Users from "./Users";
import AddUser from "../../Components/Forms/AddUser";
import ManagementCompanies from "./ManagementCompanies";
import MaintenanceContractors from "./MaintenanceContractor";
import IndividualManagers from "./IndividualManagers";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../../Context/UserContext";
import VerifyPhoneModal from "../../Components/Modals/VerifyPhoneModal";

import { chakra, useDisclosure } from "@chakra-ui/react";
import { GetUser } from "../../Utils/AUTH";

export default function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const { addUser, verified, user, setUser, openSidebar } =
    useContext(UserContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (
      !(localStorage.getItem("verified") === "true" ? true : false) &&
      user.phone.length > 0
    )
      onOpen();
    console.log(user);
  }, [verified, user]);

  useEffect(() => {
    if (location.pathname.includes("dashboard"))
      navigate("/management-companies");
  }, [location.pathname]);

  return (
    <div
      className={`${
        openSidebar ? "lg:pl-[280px] 2xl:pl-[20%]" : "pl-0"
      } w-full h-[100vh] flex flex-row font-inter bg-[#FCFCFC] relative`}
    >
      <Navbar />
      <Sidebar />
      {location.pathname === "/users/add-user" && <AddUser />}
      {location.pathname === "/management-companies/add-management-company" && (
        <AddManagementCompany />
      )}
      {location.pathname === "/individual-managers/add-individual-manager" && (
        <IndividualManager />
      )}
      {location.pathname.includes("/maintenance/update-user") && <AddUser />}
      {location.pathname.includes("/management/update-user") && <AddUser />}
      {location.pathname.includes("/management/add-user") && <AddUser />}
      {location.pathname.includes("/maintenance/add-user") && <AddUser />}
      {location.pathname ===
        "/maintenance-contractors/add-maintenance-company" && <PrimaryAdmin />}
      {location.pathname === "/users" && <Users />}
      {location.pathname.includes("/maintenance-contractors") &&
        !location.pathname.includes("add-maintenance") && (
          <MaintenanceContractors />
        )}
      {location.pathname.includes("/management-companies") &&
        !location.pathname.includes("add-management") && (
          <ManagementCompanies />
        )}
      {location.pathname.includes("/individual-managers") &&
        !location.pathname.includes("add-individual") && <IndividualManagers />}
      {/* <VerifyPhoneModal isOpen={isOpen} onClose={onClose} phone={user.phone} /> */}
    </div>
  );
}
