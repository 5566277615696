import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { chakra, FormControl, useDisclosure } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { Table, Checkbox } from "../../Components/Table";
import PlusIcon from "../../Assets/Icons/PlusIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import FilterIcon from "../../Assets/Icons/FilterIcon";
import { UserContext } from "../../Context/UserContext";
import TrashIcon from "../../Assets/Icons/TrashIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SortIcon from "../../Assets/Icons/SortIcon";
import RedirectIcon from "../../Assets/Icons/RedirectIcon";
import { DropdownInput } from "../../Components/Input";
import {
  GetCommunities,
  GetCommunitiesProps,
  GetCommunityUsers,
  GetCompaniesProps,
  GetCompanyList,
  GetCompanyUserList,
} from "../../Utils/GET";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import { Loader } from "../../Components/Loader";
import {
  DeleteCommunityAPI,
  DeleteCompanyAPI,
  DeleteUserAPI,
} from "../../Utils/ADD";
import toast from "react-hot-toast";
import ResendIcon from "../../Assets/Icons/ResendIcon";
import { InviteAPI } from "../../Utils/AUTH";
import NoData from "../../Components/NoData";

type Community = {
  _id: string;
  communityName: string;
  communityPhoneNo: string;
  state: string;
  city: string;
  totalUsers: number;
};

type Person = {
  _id: string;
  userId: string;
  firstName: string;
  email: string;
  phoneNo: string;
  inviteAccepted: boolean;
};

export default function ClientCommunities(props: any) {
  const methods = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  console.log(id, "id");

  const { editUsers, setEditUsers, tableTitle, setTableTitle, setCommunityId } =
    useContext(UserContext);
  const [layer, setLayer] = useState<number>(0);
  const [showSF, setShowSF] = useState(false);
  const [loading, setLoading] = useState(false);

  const [companyId, setCompanyId] = useState("");
  const [communityList, setCommunityList] = useState([]);
  const [communityUserList, setCommunityUserList] = useState([]);

  const columnHelper0 = createColumnHelper<Community>();
  const columnHelper1 = createColumnHelper<Person>();

  const sortCompanies = methods.watch("sortCompanies");
  const filterInvite = methods.watch("filterInvite");
  const [searchTerm, setSearchTerm] = useState("");

  const onSubmit = async (data: any) => {
    console.log(data);
    setSearchTerm(data.searchQuery);
  };

  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();

  const columnsLayer0 = [
    {
      id: "select",
      header: ({ table }: any) => (
        <Checkbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }: any) => (
        <Checkbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    columnHelper0.accessor("communityName", {
      header: () => (
        <span className="text-white text-base font-medium">Community Name</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),

    columnHelper0.accessor("communityPhoneNo", {
      header: () => (
        <span className="text-white text-base font-medium">Phone No</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper0.accessor("state", {
      header: () => (
        <span className="text-white text-base font-medium">State</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper0.accessor("city", {
      header: () => (
        <span className="text-white text-base font-medium">City</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper0.accessor("totalUsers", {
      header: () => (
        <span className="text-white text-base font-medium">
          Read only users
        </span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    {
      id: "redirect",
      // header: ({ table }: any) => (

      // ),
      cell: ({ row }: any) => (
        <div
          onClick={() => {
            console.log(row.original._id);
            setTableTitle(row.original.communityName);
            navigate(`/communities/${row.original._id}`);
            setCommunityId(row.original._id);
          }}
        >
            <span className="text-[#55A8FD] underline ml-[0] font-semibold">View Details</span>
          {/* <RedirectIcon className="w-4 h-4 text-[#353535] opacity-0 group-hover:opacity-100" /> */}
        </div>
      ),
    },
  ];

  const columnsLayer1 = [
    // columnHelper1.accessor("_id", {
    //   header: () => (
    //     <span className="text-white text-base font-medium">User ID</span>
    //   ),
    //   cell: (info: any) => {
    //     return (
    //       <span className={`w-[90%] truncate text-base text-gray-dark`}>
    //         {info.getValue()}
    //       </span>
    //     );
    //   },
    // }),
    columnHelper1.accessor("firstName", {
      header: () => (
        <span className="text-white text-base font-medium">Name</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper1.accessor("email", {
      header: () => (
        <span className="text-white text-base font-medium">E-mail Address</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper1.accessor("phoneNo", {
      header: () => (
        <span className="text-white text-base font-medium">Phone number</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper1.accessor("inviteAccepted", {
      header: () => (
        <span className="text-white text-base font-medium">Invite Status</span>
      ),
      cell: (info: any) => {
        return info.getValue() ? (
          <span className={`w-full text-base text-gray-dark`}> Accepted</span>
        ) : (
          <span
            className={`w-full flex flex-row items-center gap-1 text-base text-gray-dark`}
          >
            Pending
            <div
              onClick={() => {
                console.log(info.original);
                InviteAPI(info.row.original._id);
              }}
              className="w-8 h-8"
            >
              <ResendIcon className="w-8 h-8 text-[#888] hover:text-[#55A8FD]" />
            </div>
          </span>
        );
      },
    }),
  ];

  const getCommunities = async (query?: string) => {
    setLoading(true);
    let info: GetCommunitiesProps = {
      query: searchTerm,
      page: 1,
      pageSize: 10,
    };
    // if (sortCompanies) {
    //   info.sortBy = sortCompanies.includes("Increasing") ? "asc" : "desc";
    //   let sort = sortCompanies.split(" ");
    //   console.log(sort);
    //   info.role = sort[1].toLowerCase() + (sort.length > 2 ? sort[2] : "");
    // }
    // console.log(info);
    try {
      const response = await GetCommunities(info);
      if (response.status === 200) {
        console.log(response.data.data);
        setCommunityList(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getCommunityUserList = async (id: string, query?: string) => {
    setLoading(true);
    let info = {
      query: searchTerm,
      communityId: id,
      page: 1,
      pageSize: 10,
      filter: {
        inviteStatus: filterInvite,
      },
    };
    try {
      const response = await GetCommunityUsers(info);
      if (response.status === 200) {
        console.log("Debug", response);
        if (response.data.data.length === 0) {
          setCommunityUserList([]);
        } else setCommunityUserList(response.data.data[0].allCommunityUsers);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(editUsers);
  }, [editUsers]);

  useEffect(() => {
    setTableTitle("");
  }, []);

  useEffect(() => {
    if (location.pathname === "/communities") {
      setLayer(0);
      getCommunities();
    } else {
      setLayer(1);
      console.log(location.pathname.split("/")[2]);
      setCompanyId(location.pathname.split("/")[2]);
      getCommunityUserList(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  const { confirm, setConfirm } = useContext(UserContext);

  useEffect(() => {
    setShowSF(false);
    setConfirm(false);
    if (layer === 0) {
      setTableTitle("");
    }
  }, [layer]);

  useEffect(() => {
    if (layer === 0) getCommunities();
    else getCommunityUserList(companyId);
  }, [filterInvite, searchTerm]);

  const deleteAPI = async () => {
    try {
      setLoading(true);
      const response =
        layer === 0
          ? await DeleteCommunityAPI({
              ids: editUsers.map((user) => {
                return user._id;
              }),
            })
          : await DeleteUserAPI({
              authority: "owner",
              ids: editUsers.map((user) => {
                return user._id;
              }),
            });
      if (response.status === 200) {
        console.log(response);
        toast.success(response.message);
        window.location.reload();
        // layer === 0 ? getCompanyList() : getCompanyUserList(companyId);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (confirm) deleteAPI();
  }, [confirm]);

  console.log("Debug", communityUserList);

  return (
    <div className="flex flex-1 flex-col p-4 md:p-10 items-center w-full gap-6 mt-16 h-[calc(100%-50px)] bg-[#FCFCFC]">
      <div className="flex w-full flex-1 flex-col max-h-[calc(100%-0px)] overflow-hidden p-4 items-center gap-6 bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]">
        <FormProvider {...methods}>
          <chakra.form
            className="w-full flex flex-col items-start"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {/* header  */}
            <div className="w-full flex justify-between flex-col lg:flex-row">
              <span className="font-medium text-3x lg:text-4xl mt-2 text-[#001124]">
                {tableTitle && tableTitle.length > 0
                  ? tableTitle
                  : layer === 0
                  ? "Client Communities"
                  : "Community Users"}
               
              </span>
              <div className="flex flex-col md:flex-row  items-start sm:items-center gap-5">
                <div className="flex flex-row w-full items-center gap-5 ">
                  <FormControl isRequired={false}>
                    <div
                      className={`group w-full lg:min-w-[20rem] h-11 pl-3 pr-0.5 bg-transparent border-[1px] border-[#73B8FF33] rounded flex flex-row items-center`}
                    >
                      <input
                        title="Search"
                        {...methods.register("searchQuery")}
                        type={"text"}
                        placeholder="Search"
                        className="flex flex-1 text-sm placeholder:text-sm bg-transparent text-black"
                      />
                      <SearchIcon
                        className="w-11 h-11"
                        onClick={() => methods.handleSubmit(onSubmit)()}
                      />
                    </div>
                  </FormControl>

                </div>
                <div
                  className={`h-11 lg:min-w-[10rem] w-full px-2 flex flex-row ${
                    editUsers.length > 0 ? "bg-[#CA2524]" : "bg-[#55A8FD]"
                  } rounded justify-center items-center gap-2 cursor-pointer`}
                  onClick={() => {
                    if (editUsers.length === 0) {
                      layer === 0
                        ? navigate("/communities/add-community")
                        : navigate("/communities/add-user");
                    } else {
                      onOpenDeleteModal();
                    }
                  }}
                >
                  {editUsers.length > 0 ? <TrashIcon /> : <PlusIcon />}
                  <span className="text-xl text-white font-medium">
                    {editUsers.length > 0 ? "Delete" : "Add"}
                  </span>
                  {editUsers.length > 0 && loading && (
                    <div className="h-6 w-6">
                      <Loader type="circle" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full flex flex-row gap-5 items-center justify-end mt-4">
              {showSF && layer === 1 && (
                <div className="w-[25rem] flex flex-row items-center gap-5 z-30">
                  <DropdownInput
                    options={["Accepted", "Pending"]}
                    type="text"
                    name="filterInvite"
                    label=""
                    placeholder="Invite Status"
                    isRequired={false}
                    showLabels={false}
                    search={false}
                  />
                </div>
              )}

              {layer === 1 && (
                <div
                  onClick={() => {
                    setShowSF(!showSF);
                  }}
                  className={`h-11 w-[6.5rem] flex flex-row ${
                    showSF
                      ? "bg-[#55A8FD] text-white"
                      : "bg-white text-[#55A8FD]"
                  } border border-[#55A8FD] rounded justify-center items-center gap-2 cursor-pointer`}
                >
                  {!loading && communityUserList.length > 0 && layer === 1 && (
                    <FilterIcon
                      className={
                        showSF ? "w-6 h-6 text-white" : "w-6 h-6 text-[#55A8FD]"
                      }
                    />
                  )}
                  <span className="text-base font-medium">Filters</span>
                </div>
              )}
            </div>
          </chakra.form>
        </FormProvider>
        {layer === 1 &&
          (loading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Loader className="w-40 h-40" type="square" />
            </div>
          ) : communityUserList.length > 0 ? (
            <Table tableData={communityUserList} columns={columnsLayer1} />
          ) : (
            <div className="w-full h-fit flex pt-20 pl-[4.5rem] justify-start">
              <NoData route="" text="Users" />
            </div>
          ))}

        {layer === 0 &&
          (loading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Loader className="w-40 h-40" type="square" />
            </div>
          ) : communityList.length > 0 ? (
            <Table tableData={communityList} columns={columnsLayer0} />
          ) : (
            <div className="w-full h-fit flex pt-20 pl-[4.5rem] justify-start">
              <NoData route="" text="Client Community." />
            </div>
          ))}
      </div>
      <ConfirmModal
        isOpen={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        title={layer === 0 ? "Delete Client Community." : "Delete Users"}
        text={
          layer === 0
            ? "Click on continue to delete the Client Community and all its users."
            : "Click on continue to delete selected users."
        }
      />
    </div>
  );
}
