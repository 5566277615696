import { useState } from "react";
import { CustomInput, CustomPhoneInput, DropdownInput } from "../Input";
import CustomButton from "../Button";
import { useForm, FormProvider } from "react-hook-form";
import { chakra, useDisclosure } from "@chakra-ui/react";
import VerifyPhoneModal from "../Modals/VerifyPhoneModal";
import AddCompanyModal from "../Modals/ConfirmModal";
import { AddUserAPI } from "../../Utils/ADD";
import toast from "react-hot-toast";

export default function ProfileForm() {
  const methods = useForm();
  const [loading, setLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = async (data: any) => {
    console.log(data);
    // onOpen();
    let info = {
      ...data,
      countryCode: "+91",
      designation: undefined,
    };
    console.log(info);
    // onOpen();
    setLoading(true);
    try {
      const response = await AddUserAPI(info);
      if (response.status === 200) {
        console.log(response);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.message || "Something went wrong");
      console.error(error);
    }
  };

  let phone: string = methods.watch("adminPhoneNumber", "");

  return (
    <>
      <FormProvider {...methods}>
        <chakra.form
          autoComplete="off"
          className="w-full flex flex-col items-end gap-9"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="w-[800px] px-9 pb-2 grid grid-cols-2 gap-x-6 gap-y-0.5">
            <CustomInput
              type="text"
              name="firstName"
              label="First Name"
              placeholder="First name"
              isRequired={true}
            />
            {/* <CustomInput
              type="text"
              name="middleName"
              label="Middle Name"
              placeholder="Middle name"
              isRequired={false}
            /> */}
            <CustomInput
              type="text"
              name="lastName"
              label="Last Name"
              placeholder="Last name"
              isRequired={true}
            />

            <CustomPhoneInput
              type="number"
              name="phoneNo"
              label="Phone Number"
              placeholder="Phone Number"
              isRequired={true}
            />
            <div className="col-span-2">
              <CustomInput
                type="email"
                name="email"
                label="Email Address"
                placeholder="Email Address"
                isRequired={true}
              />
            </div>
            <div className="w-full flex justify-center col-span-2">
              <CustomButton
                colorScheme="blue"
                w={"w-[60%]"}
                loading={loading}
                text="Save"
              />
            </div>
          </div>
          {/* </div> */}
        </chakra.form>
      </FormProvider>
      {/* <VerifyPhoneModal isOpen={isOpen} onClose={onClose} phone={phone} /> */}
    </>
  );
}
