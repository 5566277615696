export default function Comments({ comments }: { comments: any[] }) {
  return (
    <div className="flex flex-col w-full">
      {comments.map((comment, index) => (
        <div className="w-full flex flex-col items-start">
          <CommentBox comment={comment} index={index} />
          <div className="w-[1px] h-8 ml-24 bg-gray-light" />
        </div>
      ))}
    </div>
  );
}

function CommentBox({ comment, index }: { comment: any; index: number }) {
  return (
    <div className="w-full flex sm:items-start gap-5">
      <div className="w-7 h-7 mt-2 sm:mt-0 sm:w-11 sm:h-11 bg-gray-medium rounded-full" />
      <div
        className={`flex flex-1 flex-col border ${
          index % 2 === 0 ? "border-[#82BEFB]" : "border-[#E8E8E8]"
        } rounded-md relative`}
      >
        <div
          className={`w-5 h-5 border-b border-l rounded-bl ${
            index % 2 === 0
              ? "border-[#82BEFB] bg-[#E9F4F8]"
              : "border-[#E8E8E8] bg-[#F0F0F0]"
          } transform rotate-45 absolute top-2.5 -left-[0.7rem]`}
        />
        <div
          className={`w-full h-10 ${
            index % 2 === 0
              ? "bg-[#E9F4F8] border-[#82BEFB]"
              : "bg-[#F0F0F0] border-[#E8E8E8]"
          } text-gray-medium text-xs sm:text-base gap-2 border-b flex items-center pl-7 rounded-t-md`}
        >
          <span className="text-black">
            {comment.commentUserData[0].firstName +
              " " +
              comment.commentUserData[0].lastName}
          </span>
          commented {formatDateString(comment.commentDate)}
        </div>
        <span className="w-full text-sm sm:text-base px-8 py-4 text-start text-black text-[0.81rem]">
          {comment.comment}
        </span>
      </div>
    </div>
  );
}

function formatDateString(dateString: string) {
  const currentDate = new Date().getTime();
  const inputDate = new Date(dateString).getTime();
  const timeDifference = currentDate - inputDate;
  const oneDayInMillis = 24 * 60 * 60 * 1000;
  const daysDifference = Math.floor(timeDifference / oneDayInMillis);

  if (daysDifference === 0) {
    return "today";
  } else if (daysDifference === 1) {
    return "yesterday";
  } else if (daysDifference <= 6) {
    return `${daysDifference} days ago`;
  } else if (daysDifference <= 13) {
    return "last week";
  } else if (daysDifference > 13) {
    const formattedDate = new Date(inputDate).toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  }
}
