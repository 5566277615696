export default function CompanyIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      className={className ?? "w-6 h-6 text-green-primary"}
    >
      <path
        fill="currentColor"
        d="M16.226 15.196c-.569 0-1.032.463-1.032 1.032.052 1.368 2.013 1.367 2.065 0 0-.569-.464-1.032-1.033-1.032zm-5.223-2.94l.85-.851a.612.612 0 01-.152-.405v-.659c.03-.815 1.2-.815 1.23 0v.24c.292-.103.623-.126.93-.064.322-.48.87-.791 1.48-.791h.226v-8.61A.615.615 0 0014.952.5H4.408a.615.615 0 00-.615.615v16.431h5.985a1.775 1.775 0 01-.054-.433v-1.77c0-.61.311-1.158.791-1.48a1.783 1.783 0 01.487-1.607zm.698-8.505c.03-.815 1.2-.815 1.23 0v.66c-.03.815-1.2.814-1.23 0v-.66zm0 3.295c.03-.815 1.2-.815 1.23 0v.66c-.03.815-1.2.814-1.23 0v-.66zM7.659 11c-.03.816-1.2.815-1.23 0v-.659c.03-.815 1.2-.815 1.23 0V11zm0-3.295c-.03.816-1.2.815-1.23 0v-.659c.03-.815 1.2-.815 1.23 0v.66zm0-3.295c-.03.816-1.2.815-1.23 0v-.659c.03-.815 1.2-.815 1.23 0v.66zM10.295 11c-.03.816-1.2.815-1.23 0v-.659c.03-.815 1.2-.815 1.23 0V11zm0-3.295c-.03.816-1.2.815-1.23 0v-.659c.03-.815 1.2-.815 1.23 0v.66zm0-3.295c-.03.816-1.2.815-1.23 0v-.659c.03-.815 1.2-.815 1.23 0v.66zm8.297 6.107c.306-.062.637-.039.93.063V6.387a.615.615 0 00-.181-.435l-1.977-1.977a.615.615 0 00-.566-.165v5.916h.313c.611 0 1.159.311 1.48.791z"
      ></path>
      <path
        fill="currentColor"
        d="M21.017 14.797l-.672-.084a4.339 4.339 0 00-.134-.323l.416-.535a.551.551 0 00-.045-.728l-1.252-1.251a.55.55 0 00-.728-.046l-.535.416a4.357 4.357 0 00-.323-.134l-.084-.672a.551.551 0 00-.547-.483h-1.77a.55.55 0 00-.547.483l-.084.672c-.11.04-.217.085-.323.134l-.535-.416a.551.551 0 00-.728.046l-1.252 1.252a.551.551 0 00-.045.727l.416.535a4.339 4.339 0 00-.134.323l-.672.084a.551.551 0 00-.483.547v1.77a.55.55 0 00.483.547l.672.084c.04.11.085.217.134.323l-.416.535a.551.551 0 00.045.728l1.252 1.252a.55.55 0 00.728.045l.535-.416c.106.05.214.094.323.134l.084.672a.55.55 0 00.547.483h1.77a.55.55 0 00.547-.483l.084-.672c.11-.04.217-.085.323-.134l.535.416a.55.55 0 00.728-.045l1.252-1.252a.55.55 0 00.045-.728l-.416-.535a4.38 4.38 0 00.134-.323l.672-.084a.55.55 0 00.483-.547v-1.77a.55.55 0 00-.483-.546zm-4.789 3.695a2.265 2.265 0 01-2.263-2.263c.114-2.997 4.413-2.996 4.526 0a2.265 2.265 0 01-2.263 2.263zM.5 8.365v8.567c0 .34.275.615.615.615h1.45V7.75h-1.45a.615.615 0 00-.615.615z"
      ></path>
    </svg>
  );
}
