import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { chakra, FormControl, useDisclosure } from "@chakra-ui/react";

import { createColumnHelper } from "@tanstack/react-table";
import { Table, Checkbox } from "../../Components/Table";
import PlusIcon from "../../Assets/Icons/PlusIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import FilterIcon from "../../Assets/Icons/FilterIcon";
import { UserContext } from "../../Context/UserContext";
import TrashIcon from "../../Assets/Icons/TrashIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SortIcon from "../../Assets/Icons/SortIcon";
import RedirectIcon from "../../Assets/Icons/RedirectIcon";
import { DropdownInput } from "../../Components/Input";
import {
  GetCompaniesProps,
  GetCompanyList,
  GetCompanyUserList,
  GetMaintenanceList,
} from "../../Utils/GET";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import { Loader } from "../../Components/Loader";
import { DeleteCompanyAPI, DeleteUserAPI } from "../../Utils/ADD";
import toast from "react-hot-toast";
import ResendIcon from "../../Assets/Icons/ResendIcon";
import { InviteAPI } from "../../Utils/AUTH";
import NoData from "../../Components/NoData";

type MaintenanceContractors = {
  _id: string;
  companyName: string;
  companyPhoneNo: string;
  masterAdmins: number;
  superAdmins: number;
  admins: number;
  readOnly: number;
};

type MaintenanceContractorUsers = {
  _id: string;
  firstName: string;
  phoneNo: string;
  email: string;
  inviteAccepted: boolean;
  authorityLevel: string;
};

export default function MaintenanceContractors() {
  const methods = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const { editUsers, setEditUsers, tableTitle, setTableTitle, user } =
    useContext(UserContext);
  const [layer, setLayer] = useState<number>(0);
  const [showSF, setShowSF] = useState(false);
  const [loading, setLoading] = useState(false);

  const [companyId, setCompanyId] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [companyUserList, setCompanyUserList] = useState([]);

  const sortCompanies = methods.watch("sortCompanies");
  const filterInvite = methods.watch("filterInvite");
  const filterAuthority = methods.watch("filterAuthority");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (showSF === false) {
      methods.setValue("sortCompanies", undefined);
      methods.setValue("filterInvite", undefined);
      methods.setValue("filterAuthority", undefined);
    }
  }, [showSF]);

  const onSubmit = async (data: any) => {
    console.log(data);
    setSearchTerm(data.searchQuery);
  };

  const columnHelper0 = createColumnHelper<MaintenanceContractors>();
  const columnHelper1 = createColumnHelper<MaintenanceContractorUsers>();

  const columnsLayer0 = [
    {
      id: "select",
      header: ({ table }: any) => (
        <Checkbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }: any) => (
        <Checkbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },

    columnHelper0.accessor("companyName", {
      header: () => (
        <span className="text-white text-base font-medium">
          Maintenance Contractor
        </span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper0.accessor("companyPhoneNo", {
      header: () => (
        <span className="text-white text-base font-medium">Phone Number</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),

    columnHelper0.accessor("masterAdmins", {
      header: () => (
        <span className="text-white text-base font-medium">Master Admin</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper0.accessor("superAdmins", {
      header: () => (
        <span className="text-white text-base font-medium">Super Admins</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper0.accessor("admins", {
      header: () => (
        <span className="text-white text-base font-medium">Admins</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    // columnHelper0.accessor("readOnly", {
    //   header: () => (
    //     <span className="text-white text-base font-medium">Users</span>
    //   ),
    //   cell: (info: any) => {
    //     return (
    //       <div
    //         className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
    //       >
    //         {info.getValue()}
    //       </div>
    //     );
    //   },
    // }),
    {
      id: "redirect",
      // header: ({ table }: any) => (

      // ),
      cell: ({ row }: any) => (
        <div
          onClick={() => {
            console.log(row.original._id);
            setTableTitle(row.original.companyName);
            navigate(`/maintenance-contractors/${row.original.companyId}`);
          }}
        >
            <span className="text-[#55A8FD] underline ml-[0] font-semibold">View Details</span>
          {/* <RedirectIcon className="w-4 h-4 text-[#353535] opacity-0 group-hover:opacity-100" /> */}
        </div>
      ),
    },
  ];

  const columnsLayer1 = [
    // {
    //   id: "select",
    //   header: ({ table }: any) => (
    //     <Checkbox
    //       {...{
    //         checked: table.getIsAllRowsSelected(),
    //         indeterminate: table.getIsSomeRowsSelected(),
    //         onChange: table.getToggleAllRowsSelectedHandler(),
    //       }}
    //     />
    //   ),
    //   cell: ({ row }: any) => (
    //     <Checkbox
    //       {...{
    //         checked: row.getIsSelected(),
    //         disabled: !row.getCanSelect(),
    //         indeterminate: row.getIsSomeSelected(),
    //         onChange: row.getToggleSelectedHandler(),
    //       }}
    //     />
    //   ),
    // },

    // columnHelper1.accessor("_id", {
    //   header: () => (
    //     <span className="text-white text-base font-medium">User ID</span>
    //   ),
    //   cell: (info: any) => {
    //     return (
    //       <div
    //         className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
    //       >
    //         {info.getValue()}
    //       </div>
    //     );
    //   },
    // }),
    columnHelper1.accessor("firstName", {
      header: () => (
        <span className="text-white text-base font-medium">Name</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper1.accessor("email", {
      header: () => (
        <span className="text-white text-base font-medium">E-mail</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper1.accessor("phoneNo", {
      header: () => (
        <span className="text-white text-base font-medium">Phone number</span>
      ),
      cell: (info: any) => {
        return (
          <div
            className={`max-w-[85%] overflow-hidden truncate text-base text-gray-dark`}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper1.accessor("inviteAccepted", {
      header: () => (
        <span className="text-white text-base font-medium">Invite Status</span>
      ),
      cell: (info: any) => {
        return info.getValue() ? (
          <span className={`w-full text-base text-gray-dark`}> Accepted</span>
        ) : (
          <span
            className={`w-full flex flex-row items-center gap-1 text-base text-gray-dark`}
          >
            Pending
            <div
              onClick={() => {
                console.log(info.original);
                InviteAPI(info.row.original._id);
              }}
              className="w-8 h-8"
            >
              <ResendIcon className="w-8 h-8 text-[#888] hover:text-[#55A8FD]" />
            </div>
          </span>
        );
      },
    }),
    columnHelper1.accessor("authorityLevel", {
      header: () => (
        <span className="text-white text-base font-medium">
          Authority Level
        </span>
      ),
      cell: (info: any) => {
        return (
          <span
            className={`w-full py-1 px-3 rounded-full text-base text-gray-dark ${
              info.getValue() === "Master Admin"
                ? "bg-[#FAE0DA]"
                : info.getValue() === "Super Admin"
                ? "bg-[#05AEC533]"
                : "bg-[#FFD64559]"
            }`}
          >
            {info.getValue()}
          </span>
        );
      },
    }),
  ];

  const getCompanyList = async () => {
    setLoading(true);
    let info = {
      companyId: user.companyId,
      query: searchTerm,
      page: 1,
      pageSize: 10,
      filter: sortCompanies,
    };
    console.log(info);
    try {
      const response = await GetMaintenanceList(info);
      if (response.status === 200) {
        console.log(response.data.data);
        setCompanyList(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.message ?? "Something went wrong.");
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getCompanyUserList = async (id: string) => {
    setLoading(true);
    let info = {
      companyType: "Maintenance",
      companyId: id,
      query: searchTerm,
      page: 1,
      pageSize: 10,
      filter: {
        inviteStatus: filterInvite?.toLowerCase(),
        authorityLevel: filterAuthority,
      },
    };
    console.log(info);
    try {
      const response = await GetCompanyUserList(info);
      if (response.status === 200) {
        console.log(response);
        setCompanyUserList(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.message ?? "Something went wrong.");
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (location.pathname === "/maintenance-contractors") {
      setLayer(0);
      getCompanyList();
    } else {
      setLayer(1);
      console.log(location.pathname.split("/")[2]);
      setCompanyId(location.pathname.split("/")[2]);
      getCompanyUserList(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  useEffect(() => {
    setShowSF(false);
  }, [layer]);

  useEffect(() => {
    console.log(sortCompanies);
    if (layer === 0) getCompanyList();
    else getCompanyUserList(companyId);
  }, [sortCompanies, filterInvite, filterAuthority, searchTerm]);

  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();

  const { confirm } = useContext(UserContext);

  const deleteAPI = async () => {
    try {
      const response =
        layer === 0
          ? await DeleteCompanyAPI({
              companyType: "Maintenance",
              ids: editUsers.map((user) => {
                return user.companyId;
              }),
            })
          : await DeleteUserAPI({
              authority: "management",
              ids: editUsers.map((user) => {
                return user._id;
              }),
            });
      if (response.status === 200) {
        console.log(response);
        toast.success(response.message);
        window.location.reload();
        layer === 0 ? getCompanyList() : getCompanyUserList(companyId);
        setLoading(false);
      } else {
        setLoading(false);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (confirm) deleteAPI();
  }, [confirm]);

  return (
    <div className="flex flex-1 flex-col p-4 md:p-10 items-center overflow-hidden w-full gap-6 mt-16 h-[calc(100%-50px)] bg-[#FCFCFC]">
      <div className="flex w-full flex-1 flex-col max-h-[calc(100%-0px)] overflow-hidden p-4 items-center gap-6 bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]">
        <FormProvider {...methods}>
          <chakra.form
            className="w-full flex flex-col items-start"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {/* header  */}
            <div className="w-full flex flex-col lg:flex-row justify-between space-y-4  ">
              <span className="lg:max-w-[40%] font-medium text-lg sm:text-2xl md:text-[2rem] mt-2 text-[#001124]">
                {layer === 0 ? "Maintenance Contractors" : tableTitle}
              </span>
              <div className="flex flex-col sm:flex-row  items-start sm:items-center gap-5">
                <FormControl isRequired={false}>
                  <div
                    className={`group w-full min-w-[10rem] sm:min-w-[20rem] h-11 pl-3 pr-0.5 bg-transparent border-[1px] border-[#73B8FF33] rounded flex flex-row items-center`}
                  >
                    <input
                      title="Search"
                      {...methods.register("searchQuery")}
                      type={"text"}
                      placeholder="Search"
                      className="flex flex-1 text-sm placeholder:text-sm bg-transparent text-black"
                    />
                    <SearchIcon className="w-11 h-11" onClick={()=> methods.handleSubmit(onSubmit)()}/>
                  </div>
                </FormControl>
                {layer === 0 && (
                  <div
                    className={`h-11 w-full px-2 flex flex-row ${
                      editUsers.length > 0 ? "bg-[#CA2524]" : "bg-[#55A8FD]"
                    } rounded justify-center items-center gap-2 cursor-pointer`}
                    onClick={() => {
                      editUsers.length === 0
                        ? layer === 0
                          ? navigate(
                              "/maintenance-contractors/add-maintenance-company"
                            )
                          : navigate("/users/add-user")
                        : onOpenDeleteModal();
                    }}
                  >
                    {editUsers.length > 0 ? <TrashIcon /> : <PlusIcon />}
                    <span className="text-xl text-white font-medium">
                      {editUsers.length > 0 ? "Delete" : "Add"}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="w-full flex flex-col items-start sm:flex-row gap-5  sm:items-center justify-start lg:justify-end mt-4">
              {showSF &&
                (layer === 0 ? (
                  <div className="w-full lg:w-[14rem] z-30">
                    <DropdownInput
                      options={[
                        "Increasing Admins",
                        "Decreasing Admins",
                        "Increasing Master Admins",
                        "Decreasing Master Admins",
                        "Increasing Super Admins",
                        "Decreasing Super Admins",
                        // "Increasing Users",
                        // "Decreasing Users",
                      ]}
                      type="text"
                      name="sortCompanies"
                      label=""
                      placeholder="Sort table by"
                      isRequired={false}
                      showLabels={false}
                      search={false}
                    />
                  </div>
                ) : (
                  <div className="w-full flex flex-col sm:flex-row items-center gap-5 z-30">
                    <DropdownInput
                      options={["Accepted", "Pending"]}
                      type="text"
                      name="filterInvite"
                      label=""
                      placeholder="Invite Status"
                      isRequired={false}
                      showLabels={false}
                      search={false}
                    />
                    <DropdownInput
                      options={["Admin", "Master Admin", "Super Admin", "User"]}
                      type="text"
                      name="filterAuthority"
                      label=""
                      placeholder="Authority Level"
                      isRequired={false}
                      showLabels={false}
                      search={false}
                    />
                  </div>
                ))}

              <div
                onClick={() => {
                  setShowSF(!showSF);
                }}
                className={`h-11 w-full  lg:w-[6.5rem] flex flex-row ${
                  showSF ? "bg-[#55A8FD] text-white" : "bg-white text-[#55A8FD]"
                } border border-[#55A8FD] rounded justify-center items-center gap-2 cursor-pointer`}
              >
                {!loading && companyList.length > 0 && layer === 0 ? (
                  <SortIcon
                    className={
                      showSF ? "w-6 h-6 text-white" : "w-6 h-6 text-[#55A8FD]"
                    }
                  />
                ) : (
                  <FilterIcon
                    className={
                      showSF ? "w-6 h-6 text-white" : "w-6 h-6 text-[#55A8FD]"
                    }
                  />
                )}
                <span className="text-base font-medium">
                  {layer === 0
                    ? showSF
                      ? "Reset"
                      : "Sort"
                    : showSF
                    ? "Reset"
                    : "Filters"}
                </span>
              </div>
            </div>
          </chakra.form>
        </FormProvider>
        {layer === 1 &&
          (loading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Loader className="w-40 h-40" type="square" />
            </div>
          ) : companyUserList.length > 0 ? (
            <Table tableData={companyUserList} columns={columnsLayer1} />
          ) : (
            <div className="w-full h-fit flex pl-[4.5rem] justify-start">
              <NoData route="" text="Users" />
            </div>
          ))}
        {layer === 0 &&
          (loading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Loader className="w-40 h-40" type="square" />
            </div>
          ) : companyList.length > 0 ? (
            <Table tableData={companyList} columns={columnsLayer0} />
          ) : (
            <div className="w-full h-fit flex pl-[4.5rem] justify-start">
              <NoData route="" text="Maintenance Contractors" />
            </div>
          ))}
      </div>
      <ConfirmModal
        isOpen={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        title={layer === 0 ? "Delete Maintenance Contractor" : "Delete Users"}
        text={
          layer === 0
            ? "Click on continue to delete the Maintenance Contractor and all its users."
            : "Click on continue to delete selected users."
        }
      />
    </div>
  );
}
