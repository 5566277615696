import { Spinner } from "@chakra-ui/react";
import { Loader } from "./Loader";

interface ButtonProps {
  text: string;
  loading?: boolean;
  disabled?: boolean;
  w?: string;
  colorScheme?: string;
  type?: "submit" | "button" | "reset";
}

export default function CustomButton({
  text,
  loading,
  disabled,
  w,
  colorScheme,
  type="submit",
}: ButtonProps) {
  return (
    <button
      className={` ${
        loading || disabled
          ? "cursor-not-allowed bg-opacity-50"
          : "cursor-pointer"
      }  ${
        (colorScheme ?? "green") === "green"
          ? "bg-green-primary text-white"
          : colorScheme === "blue"
          ? "bg-[#55A8FD] shadow-[2px_2px_40px_1px_#ededede6] text-white"
          : colorScheme === "light-green"
          ? "bg-[#ACE3DF] text-[#1D1D1D]"
          : "bg-white border border-gray-dark text-gray-medium"
      } mt-0  min-h-[3rem] py-2.5 rounded-xl flex items-center justify-center text-xl ${
        w ? w : "w-full"
      }`}
      disabled={disabled || loading}
      type={type}
    >
      {loading ? (
        <div className="flex flex-row items-center gap-2">
          {text}
          <div className="w-6 h-6">
            <Spinner />
          </div>
        </div>
      ) : (
        text
      )}
    </button>
  );
}
