import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { chakra, FormControl, Spinner, useDisclosure } from "@chakra-ui/react";

import { createColumnHelper } from "@tanstack/react-table";
import { Table, Checkbox } from "../../Components/Table";
import PlusIcon from "../../Assets/Icons/PlusIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import FilterIcon from "../../Assets/Icons/FilterIcon";
import { UserContext } from "../../Context/UserContext";
import TrashIcon from "../../Assets/Icons/TrashIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SortIcon from "../../Assets/Icons/SortIcon";
import RedirectIcon from "../../Assets/Icons/RedirectIcon";
import { DropdownInput } from "../../Components/Input";
import {
  DownloadReports,
  GetAssignmentRequests,
  GetAssignments,
  GetAssignmentsProps,
  GetCompaniesProps,
  GetCompanyList,
  GetCompanyUserList,
  GetMaintenanceList,
} from "../../Utils/GET";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import { Loader } from "../../Components/Loader";
import { DeleteCompanyAPI, DeleteUserAPI } from "../../Utils/ADD";
import toast from "react-hot-toast";
import ResendIcon from "../../Assets/Icons/ResendIcon";
import { InviteAPI } from "../../Utils/AUTH";
import NoData from "../../Components/NoData";
import DownloadIcon from "../../Assets/Icons/DownloadIcon";
import { ReportFilter } from "../../Components/ReportFilter";
import { AssignmentData } from "../../Components/AssignmentCard";
import DefaultImage from "../../Assets/dafaultImage.png";
import { formatDate } from "../../Components/AssignmentRequestCard";
import CloseModal from "../../Components/Diary/CloseModal";
import { Carousel } from "react-responsive-carousel";

export default function Reports() {
  const methods = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const [assignments, setAssignments] = useState<AssignmentData[]>([]);
  const [loading, setLoading] = useState(false);

  const { reportFilter, user } = useContext(UserContext);

  const onSubmit = async (data: any) => {
    console.log(data);
  };

  const getAssignments = async () => {
    setLoading(true);
    let info: GetAssignmentsProps = {
      status: "All",
      filter: reportFilter,
    };
    console.log(info);
    try {
      const response =
        user.company === "Maintenance"
          ? await GetAssignmentRequests(info)
          : await GetAssignments(info);
      if (response.status === 200) {
        console.log(response.data.data);
        setAssignments(response.data.data.assignments);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getAssignments();
  }, [reportFilter]);

  const [filter, setFilter] = useState(false);

  return (
    <div className="relative flex flex-1 flex-col p-4 md:p-10 items-center w-full gap-6 mt-16 h-[calc(100%-50px)] bg-[#FCFCFC]">
      <div className="flex w-full flex-1 flex-col max-h-[calc(100%-0px)] overflow-y-auto p-4 items-center gap-6 bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke rounded-[8px]">
        <FormProvider {...methods}>
          <chakra.form
            className="w-full flex flex-col items-start"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {/* header  */}
            <div className="w-full flex flex-col lg:flex-row justify-between space-y-4">
              <span className="lg:max-w-[40%] font-medium text-lg sm:text-2xl md:text-[2rem] mt-2 text-[#001124]">
                Assignment Reports
              </span>
              <div className="flex flex-row items-center gap-5">
                <FormControl isRequired={false}>
                  <div
                    className={`group w-full min-w-[10rem] sm:min-w-[20rem] h-11 pl-3 pr-0.5 bg-transparent border-[1px] border-[#73B8FF33] rounded flex flex-row items-center`}
                  >
                    <input
                      title="Search"
                      {...methods.register("searchQuery")}
                      type={"text"}
                      placeholder="Search"
                      className="flex flex-1 text-sm placeholder:text-sm bg-transparent text-black"
                    />
                    <SearchIcon
                      className="w-11 h-11"
                      onClick={() => methods.handleSubmit(onSubmit)()}
                    />
                  </div>
                </FormControl>
              </div>
            </div>
            <div className="w-full flex flex-row gap-5 mt-5 items-center lg:justify-end">
              <div
                onClick={() => {
                  setFilter(!filter);
                }}
                className="h-9 w-full md:w-[6.5rem]  flex flex-row bg-[#55A8FD] rounded justify-center items-center gap-2 cursor-pointer"
              >
                <FilterIcon className="text-white" />
                <span className="text-base text-white font-medium">
                  Filters
                </span>
              </div>
              {/* <div className="h-9 w-[10rem] flex flex-row bg-white border border-[#55A8FD] rounded justify-center items-center gap-2 cursor-pointer">
                <span className="text-base text-[#55A8FD] font-medium">
                  Download Reports
                </span>
              </div> */}
            </div>
          </chakra.form>
        </FormProvider>

        {filter && <ReportFilter />}

        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader className="w-40 h-40" type="square" />
          </div>
        ) : assignments.length > 0 ? (
          <div className="w-full flex flex-col gap-6">
            {assignments.map((assignment, index) => (
              <div
                // onClick={() => {
                //   navigate(`/assignment/${assignment._id}`);
                // }}
                key={index}
              >
                <ReportCard assignment={assignment} />
              </div>
            ))}{" "}
          </div>
        ) : (
          <div className="w-full h-fit flex pt-20 pl-[4.5rem] justify-start">
            <NoData route="" text="Assignments" />
          </div>
        )}
      </div>
    </div>
  );
}

function ReportCard({ assignment }: { assignment: AssignmentData }) {
  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();
  const [loading,setLoading] = useState(false);
  const downloadReport = async () => {
    await setLoading(true);
    let info = {
      id: assignment._id,
    };
    console.log(info);
    try {
      const response = await DownloadReports(info);
      console.log(response)
      if (response.status === 200 && response.data) {
        console.log('187')
        const blob = new Blob([response.data], { type: "application/pdf" });
        console.log("pdfgenerated", blob);
        const blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, "_blank");
        toast.success("Downloaded Successful")
      } else {
        toast.error(
          "Could not download file due to some error. Please try again"
        );
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
    await setLoading(false)
  };
  return (
   
    <div className="relative w-full bg-[#F0EDED1F] md:pr-5 shadow-[2px_2px_40px_1px_rgba(237,237,237,0.90)] border-[0.4px] border-opacity-40 border-[#DADADA] gap-5 rounded flex items-center lg:flex-row flex-col">
      <div className="lg:w-[16rem] lg:h-[12rem] xs:w-[12rem] xs:h-[12rem] md:w-[25rem] md:h-[20rem]">
      {(assignment.images?.length ===1) ? (
          <img
          src={assignment.images && assignment.images.length > 0 ? assignment.images[0].url : DefaultImage}
          alt=""
          className="object-cover w-full h-[12rem]"
        />
        ):(
          <Carousel autoPlay showThumbs={false} infiniteLoop showArrows={false}>
              {assignment.images?.map((img,i)=>(
                <img
                src={assignment.images && assignment.images.length > 0 ? img.url : DefaultImage}
                alt=""
                key={i}
                className="object-cover w-full h-[12rem]"
              />
              ))}
          </Carousel>
        )}
          
        {/* <img
          src={
            assignment.images && assignment.images.length > 0
              ? assignment.images[0].url
              : DefaultImage
          }
          alt=""
          className=" w-[100%] h-[100%]"
        /> */}
      </div>
      <div className="w-full px-4 flex flex-col py-2  md:gap-8 md:flex-row flex-1 h-full md:py-5 items-start justify-between">
        <div className="flex flex-col items-start gap-5">
          <div className="flex flex-col items-start">
            <span className="text-lg max-w-[15rem]">{assignment.subject}</span>
            <span className="text-sm text-[#909090]">{assignment._id}</span>
          </div>
          <div className="flex flex-col items-start">
            <span className="text-xs text-gray-dark">Opened On</span>
            <span className="text-base text-[#909090]">
              {assignment?.assignmentDates?.openingDate
                ? formatDate(assignment?.assignmentDates?.openingDate)
                : "-"}
            </span>
          </div>
        </div>

        <div className="flex flex-col items-start gap-5">
          <div className="flex flex-col items-start">
            <span className="text-xs text-gray-dark">Assigned to</span>
            <span className="text-base text-[#909090]">
              {assignment.assignees && assignment.assignees.length > 0
                ? assignment.assignees[0].firstName +
                  " " +
                  assignment.assignees[0].lastName
                : "None"}
            </span>
          </div>
          <div className="flex flex-col items-start">
            <span className="text-xs text-gray-dark">Client Community</span>
            <span className="text-base text-[#909090]">Community Name</span>
          </div>
        </div>

        <div className="flex flex-col items-start gap-5 w-full md:w-[8.125rem]">
          <div className="flex flex-col items-start w-full">
            <div className="flex items-center gap-10  w-full justify-between">
              <span className="text-xs text-gray-dark">Current Status</span>
              <div
                onClick={() => {
                   downloadReport();
                }}
                className="cursor-pointer"
              >
                {loading?(
                          <div className="w-6 h-6">
                          <Spinner />
                        </div>
                  ):(
                    <DownloadIcon  />
                  )}
              </div>
            </div>
            <span>
              {assignment?.status === "Open" ? "Pending" : assignment?.status}
            </span>
          </div>

          {assignment?.status === "Completed" && (
            <div
              onClick={() => {
                onOpenAddModal();
              }}
              className={`h-9 px-2 w-full flex flex-row border bg-[#55A8FD] text-base text-white rounded-lg justify-center items-center gap-2 cursor-pointer`}
            >
              Reopen
            </div>
          )}
        </div>
      </div>
      <CloseModal
        isOpen={isOpenAddModal}
        onClose={onCloseAddModal}
        title={
          assignment.status === "Completed"
            ? "Reopen Assignment"
            : "Close Assignment"
        }
        text=""
        id={assignment._id}
      />
      
      
    </div>
  );
}
