import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CloseButton,
} from "@chakra-ui/react";
import CustomButton from "../Button";
import { useContext, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import { useForm, FormProvider } from "react-hook-form";
import { chakra, useDisclosure } from "@chakra-ui/react";
import { CloseAssignment, ReOpenAssignment } from "../../Utils/ADD";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { CustomInput } from "../Input";

interface ModalProps {
  isOpen: any;
  onClose: any;
  title: string;
  text: string;
  id: string;
}

export default function CloseModal({
  isOpen,
  onClose,
  title,
  text,
  id,
}: ModalProps) {
  const methods = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { confirm, setConfirm } = useContext(UserContext);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      let response;
      if (title.includes("Close")) {
        response = await CloseAssignment({
          assignmentId: id,
          closingNotes: data.notes,
        });
      } else {
        response = await ReOpenAssignment({
          assignmentId: id,
        });
      }
      if (response.status === 200) {
        console.log(response.message);
        navigate("/assignment");
        onClose();
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent shadow={"none"} bg={"transparent"}>
        <div className="w-[95%] sm:w-full relative bg-white mx-auto shadow-lg">
          <CloseButton
            position={"absolute"}
            top={5}
            left={5}
            onClick={onClose}
          />
          <ModalHeader
            display="flex"
            justifyContent={"center"}
            className="border-b border-transparent shadow-[0px_0px_2px_0px_#0a285e66]"
          >
            <span className="text-gray-dark text-2xl text-medium">{title}</span>
          </ModalHeader>
          <FormProvider {...methods}>
            <chakra.form
              autoComplete="off"
              className="w-full flex flex-col items-end gap-9"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              {title.includes("Close") && (
                <ModalBody w={"full"} py={6}>
                  <span className="text-gray-dark text-base">{text}</span>
                  <CustomInput
                    type="text"
                    name="notes"
                    label="Closing Note"
                    placeholder="Notes"
                    isRequired={true}
                  />
                </ModalBody>
              )}

              <ModalFooter w={"full"} bg={"#F3F4F4"}>
                <div className="w-full flex justify-center items-center gap-5">
                  <div>
                    <CustomButton
                      w="px-5"
                      colorScheme="blue"
                      loading={loading}
                      text={title}
                    />
                  </div>
                </div>
              </ModalFooter>
            </chakra.form>
          </FormProvider>
        </div>
      </ModalContent>
    </Modal>
  );
}
