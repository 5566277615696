import { useState, useContext, useEffect } from "react";
import {
  CustomInput,
  CustomPhoneInput,
  DropdownInput,
} from "../../Components/Input";
import CustomButton from "../../Components/Button";
import { useForm, FormProvider } from "react-hook-form";
import { chakra, useDisclosure } from "@chakra-ui/react";
import ConfirmModal from "../../Components/Modals/ConfirmModal";
import {
  AddCommunityAPI,
  AddCommunityProps,
  AddUserAPI,
  AddUserProps,
} from "../../Utils/ADD";
import { UserContext } from "../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function AddCommunity() {
  const methods = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [communityData, setCommunityData] = useState<AddCommunityProps>(
    {} as AddCommunityProps
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { confirm, setConfirm } = useContext(UserContext);

  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();

  const onSubmit = async (data: any) => {
    console.log(data);
    // onOpen();
    let info = {
      ...data,
      communityPhoneNo: "+" + data.communityPhoneNo,
    };
    console.log(info);
    setCommunityData(info);
    onOpenAddModal();
  };

  const addCompany = async () => {
    setLoading(true);
    try {
      const response = await AddCommunityAPI(communityData);
      if (response.status === 201) {
        console.log(response);
        setLoading(false);
        setConfirm(false);
        navigate("/communities");
      } else {
        setLoading(false);
        setConfirm(false);
        toast.error(response.message);
        console.error(response);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.message || "Something went wrong");
      console.error(error);
    }
  };

  useEffect(() => {
    if (confirm) addCompany();
  }, [confirm]);

  return (
    <div className="flex flex-1 flex-col p-4 md:p-10 items-center w-full h-40 mt-16 min-h-full bg-[#FCFCFC]">
      <FormProvider {...methods}>
        <chakra.form
          autoComplete="off"
          className="w-full flex flex-col items-end gap-9"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {/* master admin box  */}
          <div className="w-full bg-white shadow-[2px_2px_40px_1px_#ededede6] border-[0.6px] border-card-stroke  rounded-[8px]">
            <div className="w-full py-2.5 gap-8 border-b-[0.4px] border-[#D3D3D3] border-opacity-30 relative flex items-center">
              <div className="w-1 h-9 rounded-full bg-[#061229] shadow-[1px_1.5px_2px_0px_#0A285E]" />
              <span className="font-semibold text-lg sm:text-2xl text-[#061229]">
                Client Community Detail
              </span>
            </div>
            <div className="w-full px-4 md:px-9 pt-6 pb-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-0.5">
              <CustomInput
                type="text"
                name="communityName"
                label="Community Name"
                placeholder="Eg: Merit Management"
                isRequired={true}
              />

              <CustomPhoneInput
                type="number"
                name="communityPhoneNo"
                label="Phone Number"
                placeholder="Phone Number"
                isRequired={true}
              />
              {/* <CustomInput
                type="text"
                name="address"
                label="Address"
                placeholder="Eg. CB-511, Street-11, California"
                isRequired={true}
              /> */}
              <CustomInput
                type="text"
                name="city"
                label="City"
                placeholder="Eg. Los Angeles"
                isRequired={true}
              />
              <CustomInput
                type="text"
                name="state"
                label="State"
                placeholder="Eg. California"
                isRequired={true}
              />
            </div>
          </div>
          <CustomButton
            colorScheme="blue"
            w={"w-40"}
            loading={loading}
            text="Add"
          />
        </chakra.form>
      </FormProvider>
      <ConfirmModal
        isOpen={isOpenAddModal}
        onClose={onCloseAddModal}
        title="Add New Community"
        text="Click on continue to add the user to your dashboard."
      />
    </div>
  );
}
