import React, { useEffect, useState } from "react";
import DropZone from "../partials/DropZone";
import Cross from "../../../../Assets/Icons/Cross";
import LocationPicker from "../../../../Assets/Icons/LocationPicker";
import LocationModal from "../partials/LocationModal";
import NotesBox from "../../../../Components/NotesBox";
import DropzoneButton from "../../../../Components/DropzoneButton";
import AssignmentForm from "./AssignmentForm";
import { useDisclosure } from "@chakra-ui/react";
import { AssignmentData } from "../../../../Components/AssignmentCard";
import { AddAssignmentsAPI, EditAssignmentsAPI } from "../../../../Utils/ADD";
import { useNavigate, useParams } from "react-router-dom";
import { GetAssignmentData } from "../../../../Utils/GET";
import toast from "react-hot-toast";
import mapIcon from "../../../../Assets/mapIcon.png"

const CreateAssignmentForm = ({ editState }: { editState: boolean }) => {
  const [images, setImages] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [notes, setNotes] = useState<string>("");
  const [defaultLocation, setDefaultLocation] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  console.log("formData", attachments, images, locations);
  const id = useParams().id;
  const imageDeleteHandler = (file: File, index: Number) => {
    const filteredList = images.filter((f, i) => {
      return index != i;
    });
    const filteredLocations = locations.filter((f, i) => {
      return index != i;
    });
    setLocations(filteredLocations);
    setImages(filteredList);
  };

  console.log("Notes", notes);

  const navigate = useNavigate();
  const attachmentDeleteHandler = (file: File, index: Number) => {
    const filteredList = attachments.filter((f, i) => {
      return index != i;
    });
    setAttachments(filteredList);
  };

  const createAssignment = async (data: AssignmentData) => {
    console.log(images);
    try {
      setIsLoading(true);
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        console.log(key, value);
        if (key === "contractorType") {
          value.forEach((val: string) => {
            formData.append("contractorType", val);
          });
        } else if (key === "employeePreference") {
          console.log("Employee preference: ", value);
          if (value) {
            value?.forEach((val: string) => {
              formData.append("employeePreference", val);
            });
          }
        } else if (key === "_id") {
          // do nothing
        } else formData.append(key, value);
      });

      images.forEach((image, index) => {
        formData.append(`images`, image);
      });

      formData.append("notes", notes);

      formData.append(`locations`, JSON.stringify(locations));

      attachments.forEach((file, index) => {
        formData.append(`attachments`, file);
      });

      const response = await AddAssignmentsAPI({ data: formData });

      if (response.status === 200) {
        console.log(response);
        toast.success("Assignment added successfully");
        navigate("/assignment");
      } else {
        console.error(response);
        toast.error(response.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const editAssignment = async (assignment: AssignmentData) => {
    setIsLoading(true);
    console.log(images);
    const formData = new FormData();
    Object.entries(assignment).forEach(([key, value]) => {
      if (key === "_id") {
        //skip this key
        return;
      }
      if (key === "contractorType") {
        if (value.length === 1) {
          formData.append("contractorType[]", value[0]);
        } else
          value.forEach((val: string) => {
            formData.append("contractorType", val);
          });
      } else if (key === "employeePreference") {
        if (value.length === 1) {
          formData.append("employeePreference[]", value[0]);
        } else
          value.forEach((val: string) => {
            formData.append("employeePreference", val);
          });
      } else formData.append(key, value);
    });
    formData.append("notes", notes);

    // formData.append("location", locationName!);
    // formData.append("lat", selected.lat.toString());
    // formData.append("lng", selected.lng.toString());
    // let locations: object[] = [];

    // if (images.length === 1) {
    //   formData.append("images[]", images[0]);
    // } else
    let i = -1;
    const prevImages: any = [];

    images.forEach((image, index) => {
      if (image.url) {
        prevImages.push({ ...image, geoLocation: locations[index] });
        i++;
      }
    });

    const newLocations = locations.slice(i + 1);
    const newImages = images.slice(i + 1);

    if (newImages.length === 1) {
      formData.append("images", newImages[0]);
    } else
      newImages.forEach((image, index) => {
        formData.append(`images`, image);
      });

    formData.append("prevImages", JSON.stringify(prevImages));

    console.log("locations", newLocations);
    formData.append(`locations`, JSON.stringify(newLocations));

    const prevAttachments: any = [];
    i = -1;
    attachments.forEach((file, index) => {
      if (file.url) {
        prevAttachments.push(file);
        i++;
      }
    });

    const newAttachments = attachments.slice(i + 1);

    formData.append("prevAttachments", JSON.stringify(prevAttachments));

    if (newAttachments.length === 1) {
      formData.append("attachments", newAttachments[0]);
    } else
      newAttachments.forEach((file, index) => {
        formData.append(`attachments`, file);
      });

    console.log(
      "updateImageData",
      prevImages,
      newImages,
      newLocations,
      prevAttachments,
      newAttachments
    );

    try {
      const response = await EditAssignmentsAPI({
        id: assignment._id,
        data: formData,
      });

      if (response.status === 200) {
        console.log(response);
        setIsLoading(false);
        toast.success("Assignment updated successfully");
        navigate("/assignment");
      } else {
        setIsLoading(false);
        console.error(response);
        toast.error(response.message);
      }
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message || "Something went wrong.");
      console.error(error);
    }
  };

  const {
    isOpen: isOpenLocationModal,
    onOpen: openLocationModal,
    onClose: closeLocationModal,
  } = useDisclosure();

  return (
    <div>
      <p className="font-medium lg:text-3xl text-xl">
        {editState ? "Edit" : "Create"} Assignment
      </p>
      <div className="container-wrapper flex flex-col lg:flex-row justify-between lg:mt-12 mt-4 lg:gap-10 p-1 gap-2 space-y-4 lg:space-y-0">
        {/* left-container */}
        <div className="">
          <span className="text-lg font-normal">Assignment Image</span>
          <DropZone setImages={setImages} setLocations={setLocations} />
          <div className="flex w-full justify-end gap-x-1 mt-4">
            <NotesBox value={notes} setNotes={setNotes} />
            <DropzoneButton setAttachments={setAttachments} />
          </div>
          <div className="scrollbar overflow-y-auto lg:h-44 max-h-32 -32 flex flex-col gap-y-2 mt-2  ">
            {attachments.map((file, index) => {
              const size = file.size / 1000;
              return (
                <div className=" w-full bg-[#EFEFEF] flex justify-between items-center px-2 py-[2px]">
                  <div className="flex">
                    <p className="max-w-[100px] sm:max-w-[200px] text-[#55A8FD] text-sm font-medium truncate">
                      {file.name
                        ? file.name
                        : file.filename
                        ? file.filename
                        : ""}
                    </p>
                    {!Number.isNaN(size) && (
                      <span className="text-[#5C5E5D] text-sm">{` (${size}KB)`}</span>
                    )}
                  </div>

                  <Cross
                    className="cursor-pointer"
                    onClick={() => attachmentDeleteHandler(file, index)}
                  />
                </div>
              );
            })}

            {images.map((file, index) => {
              const size = file.size / 1000;
              return (
                <div className=" w-full bg-[#EFEFEF] flex justify-between items-center px-2 py-[2px]">
                  <div className="flex">
                    <p className="max-w-[100px] sm:max-w-[200px] text-[#55A8FD] text-sm font-medium truncate">
                      {file.name
                        ? file.name
                        : file.filename
                        ? file.filename
                        : ""}
                    </p>
                    {!Number.isNaN(size) && (
                      <span className="text-[#5C5E5D] text-sm">{` (${size}KB)`}</span>
                    )}
                  </div>
                  <div className="flex flex-row items-center gap-1">
                    <img
                      src={mapIcon}
                      className="cursor-pointer"
                      onClick={() => {
                        console.log("opening location");
                        setDefaultLocation({
                          index: index,
                          fileName: file.name,
                          location: locations[index],
                        });
                        openLocationModal();
                      }}
                    />
                    <Cross
                      className="cursor-pointer"
                      onClick={() => {
                        imageDeleteHandler(file, index);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <LocationModal
            isOpen={isOpenLocationModal}
            onClose={closeLocationModal}
            title={"Choose Location"}
            text={`""`}
            setLocation={setLocations}
            defaultLocation={defaultLocation}
          />
        </div>
        {/* right-container */}
        <div className="form-fields lg:w-[50%]  xl:w-full lg:h-full ">
          <AssignmentForm
            createAssignment={createAssignment}
            editAssignment={editAssignment}
            setImages={setImages}
            setAttachments={setAttachments}
            setLocations={setLocations}
            setNotes={setNotes}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateAssignmentForm;
