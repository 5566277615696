import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={26}
    height={27}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.9} clipPath="url(#a)">
      <path
        d="m24.006 13.238-10.33 10.333a6.747 6.747 0 0 1-11.519-4.773c0-1.79.711-3.507 1.977-4.773L14.463 3.693a4.498 4.498 0 0 1 7.68 3.182 4.5 4.5 0 0 1-1.318 3.181L10.484 20.39a2.249 2.249 0 1 1-3.18-3.182l9.542-9.534"
        stroke="#55A8FD"
        strokeWidth={1.464}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(.758 .813)"
          d="M0 0h24.397v26.142H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
