export default function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#5C5E5D"
        d="M7 13.125A6.125 6.125 0 117 .875a6.125 6.125 0 010 12.25zM7 1.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5z"
      ></path>
      <path
        fill="#5C5E5D"
        d="M9.008 9.625L6.563 7.179V3.062h.875v3.754l2.187 2.192-.617.617z"
      ></path>
    </svg>
  );
}
