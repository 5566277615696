import { useDisclosure } from "@chakra-ui/react";
import DiaryModal from "./DiaryModal";
import { AssignmentData } from "../AssignmentCard";
import { formatDate } from "../AssignmentRequestCard";

export default function ActiveDate({
  assignmentId,
  assignment,
  setAssignment,
}: {
  assignmentId: string;
  assignment: AssignmentData;
  setAssignment: any;
}) {
  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();
  return (
    <div className="w-full flex flex-col gap-2">
      <div className="w-full flex flex-row items-center justify-between">
        <span className="text-sm lg:text-base text-gray-medium">
          Active Date
        </span>
        <span
          onClick={() => {
            onOpenAddModal();
          }}
          className="text-sm lg:text-base text-[#55A8FD] underline cursor-pointer"
        >
          Diary
        </span>
      </div>
      <span className="text-sm lg:text-base text-black">
        {assignment?.diaryData?.diaryDate
          ? formatDate(assignment?.diaryData?.diaryDate)
          : "-"}
      </span>

      <DiaryModal
        isOpen={isOpenAddModal}
        onClose={onCloseAddModal}
        assignment={assignment as AssignmentData}
        setAssignment={setAssignment}
      />
    </div>
  );
}
