export default function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#0076B2"
        d="M27.786 0H2.213A2.19 2.19 0 000 2.164v25.677A2.19 2.19 0 002.213 30h25.573A2.196 2.196 0 0030 27.834V2.157A2.196 2.196 0 0027.786 0z"
      ></path>
      <path
        fill="#fff"
        d="M4.44 11.242h4.453v14.329H4.44V11.242zm2.228-7.13a2.582 2.582 0 110 5.163 2.582 2.582 0 010-5.164zM11.687 11.243h4.268v1.967h.06c.594-1.126 2.045-2.314 4.212-2.314 4.51-.01 5.345 2.959 5.345 6.807v7.869H21.12V18.6c0-1.66-.03-3.797-2.314-3.797-2.284 0-2.673 1.81-2.673 3.688v7.08h-4.446V11.243z"
      ></path>
    </svg>
  );
}
