export default function ArrowDown({
  className,
  onClick = () => {},
  id,
}: {
  className: string;
  onClick?: any;
  id?: any;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="8"
      fill="none"
      onClick={onClick}
      id={`${id}`}
      viewBox="0 0 15 8"
      className={className ?? "w-3 h-3 text-gray-dark"}
    >
      <path fill="currentColor" d="M0 0l7.5 8L15 0H0z"></path>
    </svg>
  );
}
