import toast from "react-hot-toast";
import { ReportFilterObj } from "../Context/UserContext";
import axios from "../Helpers/Axios";
/* 
  Get Companies
*/

export interface GetCompaniesProps {
  companyType: string;
  page: number;
  pageSize: number;
  role?: string;
  sortBy?: string;
  query?: string;
}

interface GetCompaniesResponse {
  status: number;
  message: string;
}

export const GetCompanyList = async (
  credentials: GetCompaniesProps
): Promise<any> => {
  let responseData;

  try {
    console.log(credentials.sortBy);
    let sort = "";
    if (credentials.sortBy) {
      sort += "&role=";
      sort += credentials.sortBy.includes("Master")
        ? "masterAdmins"
        : credentials.sortBy.includes("Super")
        ? "superAdmins"
        : credentials.sortBy.includes("Users")
        ? "users"
        : "admins";
      sort += credentials.sortBy.includes("Increasing")
        ? "&sortBy=asc"
        : "&sortBy=desc";
    }
    const response = await axios.get(
      `/api/users/company-list?companyType=${credentials.companyType}&page=${
        credentials.page
      }&pageSize=${credentials.pageSize}${
        credentials.query ? `&query=${credentials.query}` : ""
      }${sort}`
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};



export const GetCompanyFilterList=async():Promise<any>=>{
  try {
    const response = await axios.get(`/api/users/company-filter-list`);
    return response;
  } catch (error: any) {
    console.error(error);
  }
}

/* 
  Get Company Users
*/

interface GetCompanyUserProps {
  companyType: string;
  companyId: string;
  query?: string;
  page: number;
  pageSize: number;
  filter?: any;
}

// interface AddManagementCompanyResponse {
//     status: number;
//     message: string;
// }

export const GetCompanyUserList = async (
  data: GetCompanyUserProps
): Promise<any> => {
  let responseData;

  try {
    const response = await axios.post(
      `/api/users/user-list${data.query ? `?query=${data.query}` : ""}`,
      data
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Individual Managers
*/

interface GetIndividualManagersProps {
  page: number;
  pageSize: number;
  sortBy?: string;
  query?: string;
}

export const GetIndividualManagerList = async (
  credentials: GetIndividualManagersProps
): Promise<any> => {
  let responseData;
  let sort = "";
  if (credentials.sortBy) {
    sort += "&role=";
    sort += credentials.sortBy.includes("Master")
      ? "masterAdmins"
      : credentials.sortBy.includes("Super")
      ? "superAdmins"
      : credentials.sortBy.includes("Users")
      ? "users"
      : "admins";
    sort += credentials.sortBy.includes("Increasing")
      ? "&sortBy=asc"
      : "&sortBy=desc";
  }

  try {
    const response = await axios.get(
      `/api/users/individual-owner-list?page=${credentials.page}&pageSize=${credentials.pageSize}${sort}${credentials.query ? `&query=${credentials.query}` : ''}`
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Company Users
*/

interface GetAllUsersProps {
  page: number;
  pageSize: number;
  query?: string;
  filter?: any;
}

// interface AddManagementCompanyResponse {
//     status: number;
//     message: string;
// }

export const GetAllUsers = async (data: GetAllUsersProps): Promise<any> => {
  let responseData;

  try {
    let filterQuery = "";
    if (data.filter) {
      if (data.filter?.authorityLevel) {
        filterQuery += "&role=";
        filterQuery += data.filter?.authorityLevel.includes("Master")
          ? "masterAdmins"
          : data.filter?.authorityLevel.includes("Super")
          ? "superAdmins"
          : data.filter?.authorityLevel.includes("Users")
          ? "users"
          : "admins";
      }
      if (data.filter?.inviteStatus) {
        filterQuery += "&inviteAccepted=";
        filterQuery +=
          data.filter?.inviteStatus === "accepted" ? "true" : "false";
      }
    }
    const response = await axios.get(
      `/api/users/get-all-users?page=${data.page}&pageSize=${data.pageSize}${
        data.query ? `&query=${data.query}` : ""
      }${filterQuery}`
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Parent Companies
*/

interface GetMaintenanceListProps {
  companyId: string;
  query?: string;
  page: number;
  pageSize: number;
  filter?: string | undefined;
}

// interface AddManagementCompanyResponse {
//     status: number;
//     message: string;
// }

export const GetMaintenanceList = async (
  data: GetMaintenanceListProps
): Promise<any> => {
  let responseData;
  try {
    let filterQuery = "";
    if (data.filter) {
      filterQuery += "&role=";
      filterQuery += data.filter.includes("Master")
        ? "masterAdmins"
        : data.filter.includes("Super")
        ? "superAdmins"
        : data.filter.includes("Users")
        ? "users"
        : "admins";
      filterQuery += data.filter.includes("Increasing")
        ? "&sortBy=asc"
        : "&sortBy=desc";
    }
    const response = await axios.get(
      `/api/users/maintenance-company-list?companyId=${data.companyId}${
        data.query ? `&query=${data.query}` : ""
      }${filterQuery}`
    );
    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Parent Companies
*/

interface GetParentCompanies {
  query?: string;
  page: number;
  pageSize: number;
}

// interface AddManagementCompanyResponse {
//     status: number;
//     message: string;
// }

export const GetParentCompanies = async (
  data: GetParentCompanies
): Promise<any> => {
  let responseData;
  try {
    const response = await axios.get(
      `/api/users/get-management-list?page=${data.page}&pageSize=${
        data.pageSize
      }${data.query ? `&query=${data.query}` : ""}`
    );
    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Communities
*/

export interface GetCommunitiesProps {
  query?: string;
  page: number;
  pageSize: number;
}

interface GetCommunitiesResponse {
  status: number;
  message: string;
}

export const GetCommunities = async (
  credentials: GetCommunitiesProps
): Promise<any> => {
  let responseData;

  try {
    const response = await axios.get(
      `/api/community/community-list?page=${credentials.page}&pageSize=${
        credentials.pageSize
      }${credentials.query ? `&query=${credentials.query}` : ""}`
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Parent Communities
*/

export interface GetParentCommunitiesProps {
  page: number;
  pageSize: number;
}

interface GetParentCommunitiesResponse {
  status: number;
  message: string;
}

export const GetParentCommunities = async (
  credentials: GetParentCommunitiesProps
): Promise<any> => {
  let responseData;

  try {
    const response = await axios.get(
      `/api/community/user/community-list?page=${credentials.page}&pageSize=${credentials.pageSize}`
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Community Users
*/

export interface GetCommunityUsersProps {
  query?: string;
  filter?: any;
  communityId: string;
  page: number;
  pageSize: number;
}

interface GetCommunityUsersResponse {
  status: number;
  message: string;
}

export const GetCommunityUsers = async (
  credentials: GetCommunityUsersProps
): Promise<any> => {
  console.log(credentials);
  let responseData;
  let inviteFilter = "";
  if (credentials.filter?.inviteStatus) {
    inviteFilter += "&inviteAccepted=";
    inviteFilter +=
      credentials.filter?.inviteStatus === "Accepted" ? "true" : "false";
  }

  try {
    const response = await axios.get(
      `/api/community/community-users?communityId=${
        credentials.communityId
      }&page=${credentials.page}&pageSize=${credentials.pageSize}${
        credentials.query ? `&query=${credentials.query}` : ""
      }${inviteFilter}`
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Assignments
*/

export interface GetAssignmentsProps {
  status: string;
  filter?: ReportFilterObj;
  query?: string;
  page?: number;
}

interface GetAssignmentsResponse {
  status: number;
  message: string;
}

export const GetAssignments = async (
  credentials: GetAssignmentsProps
): Promise<any> => {
  let responseData;

  try {
    const response = await axios.post(
      `/api/assignment/management/assignments${
        credentials.query ? `?query=${credentials.query}` : ""
      }`,
      credentials
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Assignment Requests
*/

export interface GetAssignmentRequestsProps {
  status: string;
  filter?: ReportFilterObj;
  query?: string;
}

interface GetAssignmentRequestsResponse {
  status: number;
  message: string;
}

export const GetAssignmentRequests = async (
  credentials: GetAssignmentRequestsProps
): Promise<any> => {
  let responseData;

  try {
    const response = await axios.post(
      `/api/assignment/maintenance/assignments`,
      credentials
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Community Assignments
*/

export interface GetCommunityAssignmentProps {
  id: string;
  status: string;
  filter?: ReportFilterObj;
  query?: string;
}

interface GetCommunityAssignmentResponse {
  status: number;
  message: string;
}

export const GetCommunityAssignment = async (
  credentials: GetCommunityAssignmentProps
): Promise<any> => {
  let responseData;

  try {
    const response = await axios.get(
      `/api/community/assignments?communityId=${credentials.id}${
        credentials.query ? `&query=${credentials.query}` : ""
      }`
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Assignment Form Options
*/

interface GetAssignmentFormResponse {
  status: number;
  message: string;
}

export const GetAssignmentFormAPI = async (): Promise<any> => {
  let responseData;

  try {
    const response = await axios.get(`/api/assignment/assignment-form-data`);

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Assignment Data
*/

export interface GetAssignmentDataProps {
  id: string;
}

interface GetAssignmentDataResponse {
  status: number;
  message: string;
}

export const GetAssignmentData = async (
  credentials: GetAssignmentDataProps
): Promise<any> => {
  let responseData;

  try {
    const response = await axios.get(
      `/api/assignment/assignment-details?assignmentId=${credentials.id}`
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
  Get Comments 
*/

export interface GetCommentsProps {
  id: string;
}

interface GetCommentsResponse {
  status: number;
  message: string;
}

export const GetComments = async (
  credentials: GetCommentsProps
): Promise<any> => {
  let responseData;

  try {
    const response = await axios.get(
      `/api/assignment/get-comments?assignmentId=${credentials.id}`
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};

/* 
   Download Reports
*/

export interface DownloadReportsProps {
  id: string;
}

interface DownloadReportsResponse {
  status: number;
  message: string;
}

export const DownloadReports = async (
  credentials: DownloadReportsProps
): Promise<any> => {
  let responseData;

  try {
    const response = await axios.get(
      `/api/assignment/report?assignmentId=${credentials.id}`,{
        responseType:"blob",
      }
    );

    // responseData = { status: response?.status, message: response?.data?.message };
    return response;
  } catch (error: any) {
    console.error(error);
    toast.error("Could not download Report.")
    // responseData = {
    //     status: error?.response?.status ?? 500,
    //     message: error?.response?.data?.message ?? "Please try again.",
    // };
    // return responseData;
  }
};
