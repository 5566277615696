import logo from "../Assets/logo.png";
import React, { useContext, useEffect, useState } from "react";
import CommunitiesIcon from "../Assets/Icons/Sidebar/Communities";
import CompanyIcon from "../Assets/Icons/Sidebar/Company";
import ContractorIcon from "../Assets/Icons/Sidebar/Contractors";
import FilesIcon from "../Assets/Icons/Sidebar/Files";
import LogoutIcon from "../Assets/Icons/Sidebar/Logout";
import ReportsIcon from "../Assets/Icons/Sidebar/Reports";
import SettingsIcon from "../Assets/Icons/Sidebar/Settings";
import TypesIcon from "../Assets/Icons/Sidebar/Types";
import UsersIcon from "../Assets/Icons/Sidebar/Users";
import AssignmentIcon from "../Assets/Icons/Sidebar/AssignmentIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import Cross from "../Assets/Icons/Cross";
import CloseIcon from "../Assets/Icons/CloseIcon";

interface ComponentProps {
  title: string;
  slug: string;
  Icon: any;
  index: number;
  selected: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  endpoint: string;
}

export default function Sidebar() {
  const navigate = useNavigate();

  const { user, openSidebar, setOpenSidebar } = useContext(UserContext);
  console.log(openSidebar, "open sidebar");

  const [sidebar, setSidebar] = useState<any[]>([]);

  const ownerSidebar = [
    {
      title: "Management Company",
      icon: CompanyIcon,
      endpoint: "/management-companies",
    },
    // {
    //   title: "Client Communities",
    //   icon: CommunitiesIcon,
    // },
    {
      title: "Individual Managers",
      icon: ContractorIcon,
      endpoint: "/individual-managers",
    },
    {
      title: "Maintenance Contractors",
      icon: ContractorIcon,
      endpoint: "/maintenance-contractors",
    },
    {
      title: "Users",
      icon: UsersIcon,
      endpoint: "/users",
    },
    // {
    //   title: "Reports",
    //   icon: ReportsIcon,
    //   endpoint: "/reports",
    // },
    // {
    //   title: "Types",
    //   icon: TypesIcon,
    // },
    // {
    //   title: "Archived Files",
    //   icon: FilesIcon ,
    // },
    // {
    //   title: "Settings",
    //   icon: SettingsIcon,
    //   endpoint: "/settings",
    // },
    {
      title: "Log Out",
      icon: LogoutIcon,
      endpoint: "/login",
    },
  ];
  const managementSidebar = [
    {
      title: "Users",
      icon: UsersIcon,
      endpoint: "/users",
    },
    {
      title: "Maintenance Contractors",
      icon: ContractorIcon,
      endpoint: "/maintenance-contractors",
    },
    {
      title: "Assignments",
      icon: AssignmentIcon,
      endpoint: "/assignment",
      slug: "assignment",
    },
    {
      title: "Client Communities",
      icon: CommunitiesIcon,
      endpoint: "/communities",
    },
    {
      title: "Reports",
      icon: ReportsIcon,
      endpoint: "/reports",
    },
    // {
    //   title: "Settings",
    //   icon: SettingsIcon,
    //   endpoint: "/settings",
    // },
    {
      title: "Log Out",
      icon: LogoutIcon,
      endpoint: "/login",
    },
  ];
  const individualSidebar = [
    // {
    //   title: "Users",
    //   icon: UsersIcon,
    //   endpoint: "/users",
    // },
    {
      title: "Maintenance Contractors",
      icon: ContractorIcon,
      endpoint: "/maintenance-contractors",
    },
    {
      title: "Assignments",
      icon: AssignmentIcon,
      endpoint: "/assignment",
      slug: "assignment",
    },
    {
      title: "Client Communities",
      icon: ReportsIcon,
      endpoint: "/communities",
    },
    {
      title: "Reports",
      icon: ReportsIcon,
      endpoint: "/reports",
    },
    // {
    //   title: "Settings",
    //   icon: SettingsIcon,
    //   endpoint: "/settings",
    // },
    {
      title: "Log Out",
      icon: LogoutIcon,
      endpoint: "/login",
    },
  ];
  const maintenanceSidebar = [
    {
      title: "Users",
      icon: UsersIcon,
      endpoint: "/users",
    },
    {
      title: "Management Companies",
      icon: CompanyIcon,
      endpoint: "/parent-companies",
    },
    {
      title: "Assignments",
      icon: AssignmentIcon,
      endpoint: "/assignment",
      slug: "assignment",
    },
    {
      title: "Reports",
      icon: ReportsIcon,
      endpoint: "/reports",
    },
    // {
    //   title: "Settings",
    //   icon: SettingsIcon,
    //   endpoint: "/settings",
    // },
    {
      title: "Log Out",
      icon: LogoutIcon,
      endpoint: "/login",
    },
  ];

  const communitySidebar = [
    {
      title: "Client Communities",
      icon: ReportsIcon,
      endpoint: "/community",
    },
    {
      title: "Reports",
      icon: ReportsIcon,
      endpoint: "/reports",
    },
    // {
    //   title: "Settings",
    //   icon: SettingsIcon,
    //   endpoint: "/settings",
    // },
    {
      title: "Log Out",
      icon: LogoutIcon,
      endpoint: "/login",
    },
  ];

  useEffect(() => {
    switch (user.company) {
      case "Owner":
        setSidebar(ownerSidebar);
        break;
      case "Management":
        setSidebar(managementSidebar);
        break;
      case "Individual":
        setSidebar(individualSidebar);
        break;
      case "Maintenance":
        setSidebar(maintenanceSidebar);
        break;
      case "Read-Only":
        setSidebar(communitySidebar);
        break;
    }
  }, [user]);

  const [selected, setSelected] = useState<number>(0);

  return (
    <>
      {/* {openSidebar && screenWidth ? ( */}
      {openSidebar && (
        <div
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => {
            setOpenSidebar(false);
          }}
        ></div>
      )}
      {/* ) : (
        <></> 
      )} */}
      <div
        className={`absolute z-20 top-0 ${
          openSidebar ? "left-0" : "-left-[30rem]"
        } transition-all min-w-[20%] min-h-full bg-[#001124] flex flex-col items-center py-4 gap-1.5 z-50 `}
      >
        <div
          className="flex self-end mx-4"
          onClick={() => setOpenSidebar(false)}
        >
          <CloseIcon className="text-white h-5 w-5 lg:hidden" />
        </div>
        <div className="w-full flex justify-start pl-14 ">
          <img
            onClick={() => {
              // setOpenSidebar(false);
              navigate("/");
            }}
            src={logo}
            alt="ProCam Tasks"
            className="cursor-pointer w-40 mt-5 mb-4"
          />
        </div>
        {sidebar.map((item, index) => (
          <div key={index} className="w-full flex flex-col z-50">
            {item.title === "Log Out" && (
              <div className="w-full mb-3 mt-1 px-5">
                <div className="w-full bg-white h-[2px] rounded-full" />
              </div>
            )}
            <SidebarElement
              title={item.title}
              slug={item.slug ?? item.endpoint}
              Icon={item.icon}
              index={index}
              selected={selected}
              setSelected={setSelected}
              endpoint={item.endpoint}
            />
          </div>
        ))}
      </div>
    </>
  );
}

const SidebarElement = ({
  title,
  slug,
  Icon,
  index,
  selected,
  setSelected,
  endpoint,
}: ComponentProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { editUsers, setEditUsers, setOpenSidebar } = useContext(UserContext);

  return (
    <div
      onClick={() => {
        if (title === "Log Out") localStorage.clear();
        setSelected(index);
        navigate(`${endpoint}`);
        setEditUsers([]);
        //setOpenSidebar(false);
      }}
      className={`flex w-full px-5 cursor-pointer`}
    >
      {/* <div
        className={`${
          location.pathname === endpoint ? "opacity-100" : "opacity-0"
        } w-[6px] h-12 rounded-lg bg-[#b3b2b299] transition-all absolute top-0 left-0`}
      ></div> */}
      <div
        className={`w-full h-12 pl-4 flex flex-row rounded ${
          location.pathname.includes(slug) ? "bg-[#b3b2b299]" : "bg-transparent"
        } items-center justify-start gap-3`}
      >
        <Icon
          className={`${
            location.pathname.includes(slug) ? "text-white" : "text-white"
          } transition-all`}
        />
        <span
          className={`${
            location.pathname.includes(slug) ? "text-white" : "text-white"
          } transition-all text-base 3xl:text-2xl`}
        >
          {title}
        </span>
      </div>
    </div>
  );
};
