import React, { useState } from "react";
import CardTag from "./CardTag";
import DefaultImage from "../Assets/dafaultImage.png";
import AssignmentStatusTag from "../Components/AssignmentStatusTag";
import { User } from "../Context/UserContext";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export interface AssignmentData {
  _id: string;
  contractorName: string;
  notificationPreference: string;
  subject: string;
  audit?: any[];
  description?: string;
  images?: any[];
  notes?: string;
  attachments?: any[];
  assignmentDates?: any;
  location: string;
  assigner?: any;
  assignedByUser?: any[];
  contractorType?: string[];
  maintenanceCompanyData?: any[];
  maintenanceCompanies: any;
  managementCompanyData: any;
  employeePreference?: any[];
  status?: string;
  userComments: any[];
  allEmployees: any[];
  assignees: User[];
  diaryData: {
    _id: string;
    assignmentId: string;
    diaryDate: string;
    diaryUser: any;
    diaryNote: string;
  };
  geoLocation?: any;
  communities?: any[];
  communityDetails?: any;
  communityId?: string;
  declineReason?: string;
}
// const CustomDot = ({ onClick, active }) => {
//   return (
//     <div
//       className={`w-1.5 h-1.5 mx-1 my-4 3xl:mx-3 rounded-full cursor-pointer ${
//         active ? "bg-gray-300" : "bg-gray-100"
//       }`}
//       onClick={() => onClick()}
//     ></div>
//   );
// };
export default function AssignmentCard({
  _id,
  subject,
  images,
  assignmentDates,
  location,
  assigner,
  contractorType,
  status,
  maintenanceCompanies,
  communityDetails,
  managementCompanyData,
}: AssignmentData) {
  // console.log("assignmentData",)
  const [hoveredImage, setHoveredImage] = useState(null);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      className={`md:w-[20rem] lg:w-[17rem] xl:w-[20rem] h-full pb-5 border-b-[2.5px] border-blue-400 shadow-xl shadow-gray-300 cursor-pointer`}
    >
      <div className="w-full h-[13rem] relative">
        {status && (
          <div className="absolute mt-[2px] z-20">
            <AssignmentStatusTag text={status}/>
          </div>
        )}
        
        {(images?.length ===1) ? (
          <img
          src={images && images.length > 0 ? images[0].url : DefaultImage}
          alt=""
          className="object-cover w-full h-[13rem]"
        />
        ):(
          <Carousel autoPlay showThumbs={false} infiniteLoop showArrows={false}>
              {images?.map((img,i)=>(
                <img
                src={images && images.length > 0 ? img.url : DefaultImage}
                alt=""
                key={i}
                className="object-cover w-full h-[13rem]"
              />
              ))}
          </Carousel>
        )}
          
      
      </div>
      <div className="px-4 flex flex-col gap-y-2">
        <div className="w-[14rem] mt-4">
          <p className="font-normal text-black font-inter text-sm text-left max-w- ">
            {subject}
          </p>

          <p className="text-[#909090] font-normal text-[11px]">{"Date"}</p>
        </div>
        <div className="w-[11rem]">
          <p className="font-normal text-gray-700 text-xs">{location}</p>
        </div>
        {assigner && (
          <div className="">
            <p className="text-[#909090] font-normal text-[11px]">
              ASSIGNED BY
            </p>
            <p className="font-normal text-gray-700 text-xs">
              {assigner?.firstName + " " + assigner?.lastName}
            </p>
          </div>
        )}
        {communityDetails && (
          <div className="">
            <p className="text-[#909090] font-normal text-[11px]">Community</p>
            <p className="font-normal text-gray-700 text-xs">
              {communityDetails?.communityName}
            </p>
          </div>
        )}
        {managementCompanyData && (
          <div className="">
            <p className="text-[#909090] font-normal text-[11px]">
              Management Company
            </p>
            <p className="font-normal text-gray-700 text-xs">
              {managementCompanyData?.companyName}
            </p>
          </div>
        )}
        {maintenanceCompanies && (
          <div className="">
            <p className="text-[#909090] font-normal text-[11px]">
              ASSIGNED TO
            </p>
            <p className="font-normal text-gray-700 text-xs">
              {maintenanceCompanies?.companyName}
            </p>
          </div>
        )}
        {contractorType && contractorType.length > 0 && (
          <div className="">
            <p className="text-[#909090] font-normal text-[11px]">TYPE</p>
            <div className="flex flex-wrap items-left gap-x-2 gap-y-1">
              {contractorType.map((x, index) => (
                <CardTag key={index} tagText={x} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
