export default function ShowIcon({className}:{className?:string}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="14"
      fill="none"
      viewBox="0 0 21 14"
      className={className ?? "w-6 h-6 text-[#ACACAC]"}
    >
      <path
        fill="currentColor"
        d="M10.001 8.502a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      ></path>
      <path
        fill="currentColor"
        d="M19.868 6.502C19.228 5.392 15.709-.176 9.73.004 4.203.144 1.004 5.003.134 6.502a1 1 0 000 1C.764 8.592 4.133 14 10.02 14h.25c5.528-.14 8.737-4.998 9.597-6.498a1 1 0 000-1zm-9.867 4a3.499 3.499 0 110-6.998 3.499 3.499 0 010 6.997z"
      ></path>
    </svg>
  );
}
