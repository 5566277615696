import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.75 1.115v16.078c0 .34.284.616.635.616h11.423c.35 0 .634-.276.634-.616V1.115A.625.625 0 0 0 12.808.5H1.385a.625.625 0 0 0-.635.615Zm10.154 13.576H8.365a.625.625 0 0 1-.634-.615c0-.34.284-.615.634-.615h2.539c.35 0 .635.275.635.615 0 .34-.284.615-.635.615ZM3.288 3.617h3.808c.35 0 .635.275.635.615 0 .34-.284.616-.635.616H3.288a.625.625 0 0 1-.634-.616c0-.34.284-.615.634-.615Zm0 2.461h7.616c.35 0 .635.275.635.615 0 .34-.284.616-.635.616H3.288a.625.625 0 0 1-.634-.616c0-.34.284-.615.634-.615Zm0 2.461h7.616c.35 0 .635.275.635.615 0 .34-.284.616-.635.616H3.288a.625.625 0 0 1-.634-.616c0-.34.284-.615.634-.615Zm0 2.461h7.616c.35 0 .635.275.635.615 0 .34-.284.615-.635.615H3.288a.625.625 0 0 1-.634-.615c0-.34.284-.615.634-.615Z"
      fill="#fff"
    />
    <path
      d="M5.193 21.502h11.423c.351 0 .635-.275.635-.615V4.85a.625.625 0 0 0-.635-.616h-1.904v12.961c0 1.018-.854 1.846-1.903 1.846h-8.25v1.846c0 .34.283.615.634.615Z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
