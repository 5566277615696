import { AssignmentData } from "../AssignmentCard";

export default function JobNotes({
  assignment,
}: {
  assignment: AssignmentData;
}) {
  return (
    <div className="w-full flex flex-col gap-2 mb-4">
      <span className="text-base text-black">Job Notes</span>
      <span className="text-black text-base">{assignment.notes ?? "-"}</span>
    </div>
  );
}
