import { useContext, useState, useRef, useEffect, useMemo } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { UserContext } from "../Context/UserContext";
import { useLocation, useNavigate } from "react-router-dom";

interface TableProps {
  edit?: string[];
  tableData: any[];
  columns: any[];
}

const random = []

export function Table({ tableData, columns, edit }: TableProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [rowSelection, setRowSelection] = useState({});

  const { editUsers, setEditUsers } = useContext(UserContext);

  const [data, setData] = useState(() => [...tableData]);
  // console.log("table data here", tableData)
  // const data = useMemo(() => tableData, [tableData]);

  const [page, setPage] = useState(0);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    state: {
      rowSelection: rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  });

  useEffect(() => {
    setEditUsers(table.getSelectedRowModel().rows.map((row) => row.original));
    // table.toggleAllRowsSelected()
  }, [table.getSelectedRowModel().rows]);

  //goes into infinite loop
  // useEffect(() => {
  //   // if (initialRenderRef.current) {
  //   //   initialRenderRef.current = false;
  //   //   return;
  //   // }
  //   // setData(tableData);
  //   setData([...tableData].map((row) => {return row}))
  //   console.log("changed");
  // }, [tableData]);

  return (
    <div className="w-full flex flex-1 max-h-full overflow-x-auto flex-col bg-white">
      <div className="rounded-lg overflow-x-auto overflow-y-auto scrollbar w-full h-fit max-h-[calc(100%-80px)]">
        <table className="w-full cursor-pointer relative">
          <thead className="w-full bg-[#001124] sticky top-0 z-10">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="h-14" key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th align="left" className="px-5 whitespace-nowrap" key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                className={`h-14 rounded-[4px] group relative cursor-pointer ${
                  parseInt(row.id) % 2 === 0 ? "bg-[#F9F9F9]" : "bg-[#EFF2F6]"
                } hover:bg-[#C7E4FF]`}
                // onClick={() => {
                //   console.log(row);
                //   let temp = [...editUsers];
                //   console.log(table.getSelectedRowModel());
                //   const index = editUsers.indexOf(row.original.id);
                //   if (index !== -1) {
                //     temp.splice(index, 1);
                //     setEditUsers(temp);
                //   } else {
                //     setEditUsers(temp.concat([row.original.id]));
                //   }
                // }}
                onClick={() => {
                  console.log(row);
                  console.log(table.getSelectedRowModel().rows);
                }}
                key={row.id}
              >
                {/* {editUsers.some((userId) => userId === row.original.id) && (
                  <div className="w-4 h-14 bg-[#55A8FD] absolute top-0 left-0" />
                )} */}
                {row.getVisibleCells().map((cell) => (
                  <td className="pl-5 whitespace-nowrap" key={cell.id}>
                    {table
                      .getSelectedRowModel()
                      .rows.some((user) => user.id === row.id) && (
                      <div className="w-1.5 rounded-l h-14 bg-[#55A8FD] absolute top-0 left-0" />
                    )}
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {/* <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot> */}
        </table>
      </div>
      <div className="w-full  justify-end hidden items-center mt-10 gap-2">
        <button
          onClick={() => {
            table.previousPage();
            setPage(page === 0 ? page : page - 1);
            console.log(page);
          }}
          disabled={!table.getCanPreviousPage()}
        >
          <PaginationButton
            value={"<"}
            tableIndex={table.getState().pagination.pageIndex}
            disabled={!table.getCanPreviousPage()}
          />
        </button>
        <button
          onClick={() => table.setPageIndex(page)}
          disabled={
            table.getState().pagination.pageIndex <= page
              ? !table.getCanNextPage()
              : !table.getCanPreviousPage
          }
        >
          <PaginationButton
            value={page + 1}
            tableIndex={table.getState().pagination.pageIndex}
            disabled={
              table.getState().pagination.pageIndex <= page
                ? !table.getCanNextPage()
                : !table.getCanPreviousPage
            }
          />
        </button>
        <button
          onClick={() => table.setPageIndex(page + 1)}
          disabled={
            table.getState().pagination.pageIndex <= page + 1
              ? !table.getCanNextPage()
              : !table.getCanPreviousPage
          }
        >
          <PaginationButton
            value={page + 2}
            tableIndex={table.getState().pagination.pageIndex}
            disabled={
              table.getState().pagination.pageIndex <= page + 1
                ? !table.getCanNextPage()
                : !table.getCanPreviousPage
            }
          />
        </button>
        {/* <button
          onClick={() => table.setPageIndex(page + 2)}
          disabled={
            table.getState().pagination.pageIndex <= page + 2
              ? !table.getCanNextPage()
              : !table.getCanPreviousPage
          }
        >
          <PaginationButton
            value={page + 3}
            tableIndex={table.getState().pagination.pageIndex}
            disabled={
              table.getState().pagination.pageIndex <= page + 2
                ? !table.getCanNextPage()
                : !table.getCanPreviousPage
            }
          />
        </button> */}
        {table.getPageCount() >= 5 && (
          <div className="flex flex-row items-center gap-2">
            <span className="pl-1">....</span>
            <button
              onClick={() => table.setPageIndex(table.getPageCount() - 2)}
              disabled={
                table.getState().pagination.pageIndex <=
                table.getPageCount() - 2
                  ? !table.getCanNextPage()
                  : !table.getCanPreviousPage
              }
            >
              <PaginationButton
                value={table.getPageCount() - 1}
                tableIndex={table.getState().pagination.pageIndex}
                disabled={
                  table.getState().pagination.pageIndex <=
                  table.getPageCount() - 2
                    ? !table.getCanNextPage()
                    : !table.getCanPreviousPage
                }
              />
            </button>
            <button
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={
                table.getState().pagination.pageIndex <=
                table.getPageCount() - 1
                  ? !table.getCanNextPage()
                  : !table.getCanPreviousPage
              }
            >
              <PaginationButton
                value={table.getPageCount()}
                tableIndex={table.getState().pagination.pageIndex}
                disabled={
                  table.getState().pagination.pageIndex <=
                  table.getPageCount() - 1
                    ? !table.getCanNextPage()
                    : !table.getCanPreviousPage
                }
              />
            </button>
          </div>
        )}
        <button
          onClick={() => {
            table.nextPage();
            setPage(table.getCanNextPage() ? page + 1 : page);
          }}
          disabled={!table.getCanNextPage()}
        >
          <PaginationButton
            value={">"}
            tableIndex={table.getState().pagination.pageIndex}
            disabled={!table.getCanNextPage()}
          />
        </button>

        {/* <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </span> */}
        <span className="flex items-center text-gray-medium gap-4">
          Go to page
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="text-center border border-[#0A2B53] p-1 rounded-lg w-14"
          />
        </span>
        {/* <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[3, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select> */}
      </div>
      {/* <div>{table.getRowModel().rows.length} Rows</div>
      <pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre> */}
    </div>
  );
}

const PaginationButton = ({
  value,
  tableIndex,
  disabled,
}: {
  value: number | string;
  tableIndex: number;
  disabled: boolean;
}) => {
  return (
    <span
      className={`flex justify-center items-center w-9 h-9 rounded-full ${
        value === tableIndex + 1
          ? "bg-[#001124] text-white"
          : "bg-white text-gray-dark"
      } relative shadow-[2px_2px_40px_1px_#ededede6] text-base font-medium`}
    >
      {value}
      {disabled && (
        <div className="w-9 h-9 bg-white bg-opacity-30 absolute top-0 left-0 rounded-full" />
      )}
    </span>
  );
};

export function Checkbox({ indeterminate, ...rest }: any) {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      //@ts-ignore
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      className="cursor-pointer w-4 h-4 border border-[#55A8FD] rounded-lg"
      type="checkbox"
      ref={ref}
      {...rest}
    />
  );
}
