import React, { useState, useEffect, useRef } from "react";
import vectorImg from "../Assets/loginVector.png";
import { useForm, FormProvider } from "react-hook-form";
import { chakra, FormControl } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CustomInput } from "../Components/Input";
import { RequestOtpAPI, ResetPasswordAPI, VerifyOtpAPI } from "../Utils/AUTH";
import CustomButton from "../Components/Button";
import UserIcon from "../Assets/Icons/UserIcon";
import ErrorIcon from "../Assets/Icons/ErrorIcon";
import ClockIcon from "../Assets/Icons/ClockIcon";
import HideIcon from "../Assets/Icons/HideIcon";
import PasswordCheck from "../Components/PasswordCheck";
import toast from "react-hot-toast";
import MailIcon from "../Assets/Icons/MailIcon";

export default function ResetPassword() {
  const navigate = useNavigate();
  const methods = useForm();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [okPass, setOkPass] = useState(false);
  const [stage, setStage] = useState(1);

  const [digits, setDigits] = useState(["", "", "", "", "", "", ""]);
  const [otpErr, setOtpErr] = useState("");
  const inputRefs = useRef(Array(6).fill(null));

  const [timer, setTimer] = useState(30);
  const [active, setActive] = useState(true);

  let password: string = methods.watch("newPassword", "");

  const {
    formState: { errors },
  } = useForm();

  const getOtp = async (mailId: string) => {
    try {
      const response = await RequestOtpAPI({
        email: mailId,
      });
      if (response.status === 200) {
        console.log(response.message);
        setLoading(false);
        if (stage !== 2) setStage(2);
      } else {
        console.error(response.message);
        setLoading(false);
        if (response.status === 404) {
          methods.setError("email", {
            type: "manual",
            message: response.message,
          });
        } else {
          console.error(response.message);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const onEmailSubmit = (data: any) => {
    console.log(data);
    setEmail(data.email);
    setLoading(true);
    getOtp(data.email);
  };

  const handleOTPSubmit = async (e: any) => {
    e.preventDefault();

    const otp = digits.slice(0, 6).join("");
    if (otp.length < 6) {
      setOtpErr("Please enter a valid verification code.");
    } else {
      console.log("OTP submitted:", otp);
      setLoading(true);
      try {
        const response = await VerifyOtpAPI({
          verificationCode: otp,
          email: email,
        });
        if (response.status === 200) {
          console.log(response.message);
          setLoading(false);
          setStage(3);
        } else {
          console.error(response.message);
          setLoading(false);
          toast(response.message);
          if (response.status === 401) {
            setOtpErr(response.message);
          } else {
            console.error(response.message);
          }
        }
      } catch (error: any) {
        setLoading(false);
        toast.error(error?.response?.data?.message || "Something went wrong.");
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const otp = digits.join("");
    if (otp.length === 6) {
      setOtpErr("");
    }
  }, [digits]);

  const onPassSubmit = async (data: any) => {
    console.log(data);
    if (data.newPassword !== data.confirmPassword) {
      methods.setError("newPassword", {
        type: "manual",
        message: "Passwords do not match.",
      });
      methods.setError("confirmPassword", {
        type: "manual",
        message: "Passwords do not match.",
      });
    } else {
      setLoading(true);
      try {
        const response = await ResetPasswordAPI({
          ...data,
          confirmPassword: undefined,
          email: undefined,
        });
        if (response.status === 200) {
          setLoading(false);
          navigate("/dashboard");
        } else {
          setLoading(false);
          console.error(response.message);
          methods.setError("newPassword", {
            type: "manual",
            message: "Please try again.",
          });
          methods.setError("confirmPassword", {
            type: "manual",
            message: "Please try again.",
          });
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  const handleOTPInputChange = (index: number, value: any) => {
    if (!isNaN(value)) {
      const newDigits = [...digits];
      newDigits[index] = value;
      setDigits(newDigits);

      if (value !== "") {
        const nextIndex = index + 1;
        if (nextIndex < inputRefs.current.length) {
          inputRefs.current[nextIndex].focus();
        }
      }
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && digits[index] === "") {
      if (inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setActive(true);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const handleResetTimer = () => {
    setTimer(30);
    setActive(false);
    getOtp(email);
  };

  return (
    <div className="w-full h-[100vh] font-inter bg-white relative flex flex-row items-center justify-center lg:justify-end lg:pr-[15%]">
      <img
        src={vectorImg}
        className="absolute hidden lg:flex -bottom-0 left-0 w-[60%] xl:w-[50%]"
        alt="Welcome"
      />

      {stage === 1 && (
        <div className="flex flex-col w-full mx-4 sm:mx-0 sm:w-[24rem] items-center">
          <span className="text-green-primary w-full text-center mb-3 text-3xl sm:text-[2.6rem] font-bold font-inter">
            Forgot Password?
          </span>
          <div className="w-[20rem] sm:w-[22rem] text-center text-gray-medium flex flex-col mb-10 text-sm font-semibold font-sans">
            Enter your registered email address to change your account password.
          </div>
          <FormProvider {...methods}>
            <chakra.form
              className="w-full flex flex-col gap-5"
              onSubmit={methods.handleSubmit(onEmailSubmit)}
            >
              <CustomInput
                type="email"
                name="email"
                label="Email address"
                placeholder="Email"
                isRequired={true}
                Icon={MailIcon}
                themeColor="green"
              />
              <CustomButton loading={loading} text="Get Verification Code" />
            </chakra.form>
          </FormProvider>
          <div
            onClick={() => {
              navigate("/login");
            }}
            className="flex flex-row w-full justify-center text-md gap-1 mt-5 cursor-pointer"
          >
            <span className="text-gray-dark">Remember your password?</span>
            <span className="text-green-primary underline font-semibold">
              Sign In
            </span>
          </div>
        </div>
      )}

      {stage === 2 && (
        <div className="w-full mx-4 sm:mx-0 sm:w-[21rem] flex flex-col items-center">
          <span className="text-green-primary w-full text-center mb-3 text-3xl sm:text-[2.6rem] font-bold font-inter">
            Enter The Code
          </span>

          <div className="w-full text-gray-medium flex flex-col text-center mb-10 text-md font-semibold font-sans">
            <span className="whitespace-nowrap">
              Enter the code we sent to your email
            </span>
            <span className="text-green-primary">
              {email.slice(0, 3) + "*****" + email.slice(email.indexOf("@"))}
            </span>
          </div>

          <chakra.form onSubmit={handleOTPSubmit}>
            <FormControl isRequired mb="">
              <div className="flex flex-row justify-between w-[18rem] sm:w-[22rem]">
                {digits.map((digit, index) => (
                  <div
                    key={index}
                    className={`${
                      index === 6 ? "hidden" : "flex"
                    } justify-center w-10 h-14 overflow-hidden border border-gray-light focus-within:border-green-primary rounded-lg`}
                  >
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      value={digit}
                      onChange={(e) =>
                        handleOTPInputChange(index, e.target.value)
                      }
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      ref={(el) => (inputRefs.current[index] = el)}
                      className="otp-input text-center"
                    />
                  </div>
                ))}
              </div>
            </FormControl>

            <span
              className={`${
                otpErr !== "" ? "opacity-100" : "opacity-0"
              } flex flex-row items-center gap-1 text-[#D51D14] text-sm m-1`}
            >
              <ErrorIcon />
              {otpErr}
            </span>

            <div className="w-full flex flex-row justify-center items-start text-gray-medium text-center mb-7 text-md font-semibold font-sans">
              <span
                onClick={handleResetTimer}
                className={
                  active
                    ? "text-green-primary pr-1 underline cursor-pointer"
                    : "pr-1 pointer-events-none"
                }
              >
                Resend Verification Code
              </span>
              {!active && (
                <span className="flex">
                  in {timer} secs <ClockIcon />
                </span>
              )}
            </div>

            <CustomButton loading={loading} text="Submit Code" />
          </chakra.form>

          <div
            onClick={() => {
              navigate("/login");
            }}
            className="flex flex-row w-full justify-center text-md gap-1 mt-5 cursor-pointer"
          >
            <span className="text-gray-dark">Remember your password?</span>
            <span className="text-green-primary underline font-semibold">
              Sign In
            </span>
          </div>
        </div>
      )}

      {stage === 3 && (
        <div className="flex flex-col w-full mx-4 sm:mx-0 sm:w-[24rem] items-center">
          <span className="text-green-primary w-full text-center mb-10 text-3xl sm:text-[2.6rem] font-bold font-inter">
            Reset Password
          </span>
          <FormProvider {...methods}>
            <chakra.form
              className="flex flex-col w-full gap-5"
              onSubmit={methods.handleSubmit(onPassSubmit)}
            >
              <CustomInput
                type="password"
                name="newPassword"
                label="New Password"
                placeholder="Password"
                isRequired={true}
                Icon={HideIcon}
                themeColor="green"
              />

              <CustomInput
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Password"
                isRequired={true}
                Icon={HideIcon}
                themeColor="green"
              />

              {/* <FormControl mt="-5" mb="8">
                <Checkbox size="lg" colorScheme="green" borderColor="#ACACAC">
                  <span className="text-gray-dark px-1">Stay signed in</span>
                </Checkbox>
              </FormControl> */}

              <PasswordCheck setOkPass={setOkPass} password={password} />

              <CustomButton disabled={!okPass} loading={loading} text="Login" />
            </chakra.form>
          </FormProvider>
        </div>
      )}
    </div>
  );
}
