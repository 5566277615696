export default function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#D51D14"
        d="M7 14A7 7 0 107 0a7 7 0 000 14zm-1.05-3.85a1.05 1.05 0 112.1 0 1.05 1.05 0 01-2.1 0zm.361-6.776a.7.7 0 011.378 0L7.7 3.5V7l-.011.126a.7.7 0 01-1.378 0L6.3 7V3.5l.011-.126z"
      ></path>
    </svg>
  );
}
