import React, { useState } from "react";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const TimeLineCard = ({ data, isLastCard = false }) => {
  const [expanded, setExpanded] = useState(false);

  function formatDate(timestamp) {
    // Convert timestamp to milliseconds
    try {
      const date = new Date(timestamp);

      // Extract day, month, and year
      const day = date.getDate();
      const monthInWords = months[date.getMonth()]; // Get month in words
      const year = date.getFullYear();

      // Format time
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;

      // Return formatted object
      return {
        day: day,
        month: monthInWords,
        year: year,
        time: formattedTime,
      };
    } catch (error) {
      console.log(error);
      return {
        day: "",
        month: "",
        year: "",
        time: "",
      };
    }
  }

  const { day, month, year, time } = formatDate(data.date);

  return (
    <div className="flex flex-row min-h-[100px] ">
      <div className={`border-r-8 border-[#ffffff] w-[30%] py-2`}>
        <div className="bg-[#55A8FD] h-full  transition-all ease-in-out duration-600 rounded-l-lg ">
          <div className="w-full flex flex-row-reverse">
            <div className="w-6 h-6 relative left-[15px] top-10 bg-[#ffffff] rounded-full flex items-center justify-center">
              <div className="w-3 h-3  bg-[#55A8FD] rounded-full"></div>
            </div>
          </div>
          <div className="h-[100%] sm:h-[60%]">
            <div className="p-2 sm:p-4 font-medium ">
              <div className="text-center text-xs sm:text-sm font-semibold text-[#ffffff]">
                {day} {month} {year}
              </div>
              <div className="mt-2 text-center text-xs text-[#ffffff]">
                {time}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* right side of timeline card (needs to be expandable) */}
      <div className="py-2 w-full ">
        <div className="bg-gradient-to-r from-[#55a9fd7c] to-[#ddeeff7c] h-full rounded-r-lg p-4 ">
          <div className="font-semibold text-[#202020]  text-lg cursor-pointer flex flex-row items-center gap-2 ml-6">
            {data.event}
          </div>

          <div
            className={`${
              expanded ? "max-h-full" : "max-h-0"
            } transition-all ease-in-out duration-600 overflow-hidden ml-6`}
          >
            <div className="mt-2 font-medium text-base flex flex-col sm:flex-row text-[#202020]">
              <div className="w-fit font-semibold text-sm text-[#202020] whitespace-nowrap ">
                • Detail :{" "}
              </div>
              <div className="pl-[5px] w-full md:w-[85%]">{data.message}</div>
            </div>
            {data.reason && (
              <div className="font-medium text-[#202020] flex flex-col sm:flex-row text-sm mt-4">
                {data.event === "Assignment closed" ? (
                  <div className="w-fit font-semibold text-sm text-[#202020] whitespace-nowrap ">
                    • Closing notes :{" "}
                  </div>
                ) : (
                  <div className="w-fit font-semibold text-sm text-[#202020] whitespace-nowrap ">
                    • Decline reason :{" "}
                  </div>
                )}{" "}
                <div className="pl-[5px] w-full md:w-[85%]">{data.reason}</div>
              </div>
            )}
          </div>
          <div
            onClick={() => {
              setExpanded(!expanded);
            }}
            className="w-fit pt-2 pb-6 ml-6 text-sm font-semibold text-[#262626] cursor-pointer decoration-2 underline-offset-2 underline"
          >
            {expanded ? "View less" : "View more"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeLineCard;
