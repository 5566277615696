import { useNavigate } from "react-router-dom";
import NoDataIcon from "../Assets/Icons/NodataIcon";
import CustomButton from "./Button";

export default function NoData({
  route,
  text,
}: {
  route: string;
  text: string;
}) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-start gap-2">
      <NoDataIcon />
      <span className="text-[#55A8FD] text-3xl font-normal">NO DATA FOUND</span>
      <span className="text-[#001124] text-base font-light max-w-[23rem]">
        No {text} has been added yet. Click on Add New to add new {text}.
      </span>
      <div
        className={`h-11 min-w-[10rem] px-2 flex flex-row "bg-[#55A8FD] rounded justify-center items-center gap-2 cursor-pointer`}
        onClick={() => {
          navigate("/management-companies/add-management-company");
        }}
      >
        <span className="text-xl text-white font-medium">Add New</span>
      </div>
    </div>
  );
}
