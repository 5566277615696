import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  CloseButton,
} from "@chakra-ui/react";

import React, { useState } from "react";
import MapLocation from "./MapLocation";
import CustomButton from "../../../../Components/Button";

interface LocationModalProps {
  isOpen: any;
  onClose: any;
  title: string;
  text: string;
  setLocation: React.Dispatch<React.SetStateAction<any>>;
  defaultLocation?: any | undefined | null;
  viewOnly?: boolean;
}

const LocationModal = ({
  isOpen,
  onClose,
  title,
  text,
  setLocation,
  defaultLocation = null,
  viewOnly = false,
}: LocationModalProps) => {
  const [selectedLocation, setSelectedLocation] = useState<any>({});

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"5xl"} isCentered>
      <ModalOverlay />
      <ModalContent shadow={"none"} bg={"transparent"}>
        <div className="lg:w-full rounded-md relative bg-white shadow-lg w-[95%] mx-auto sm:w-4/5">
          <CloseButton
            position={"absolute"}
            top={5}
            left={5}
            onClick={onClose}
          />
          <ModalHeader
            display="flex"
            justifyContent={"center"}
            className="border-b border-transparent shadow-[0px_0px_2px_0px_#0a285e66]"
          >
            <span className="text-gray-dark text-xl sm:text-2xl text-medium">
              {title}
            </span>
          </ModalHeader>
          <ModalBody py={6}>
            <MapLocation
              setLocation={setSelectedLocation}
              location={defaultLocation ? defaultLocation : undefined}
            />
          </ModalBody>

          <ModalFooter rounded={"md"} bg={"#F3F4F4"}>
            <div className="w-full flex justify-center items-center gap-5">
              <div
                onClick={() => {
                  onClose();
                }}
                className=""
              >
                <CustomButton
                  w="w-24 sm:w-36"
                  colorScheme="white"
                  loading={false}
                  text={viewOnly ? "Close" : "Cancel"}
                />
              </div>
              {!viewOnly && (
                <div
                  onClick={() => {
                    if (defaultLocation?.index >= 0) {
                      console.log("new location", selectedLocation);
                      setLocation((prev: any) => {
                        console.log("prev", prev, selectedLocation);
                        prev[defaultLocation.index] = selectedLocation.location;
                        return [...prev];
                      });
                    } else {
                      setLocation(selectedLocation);
                    }

                    //   setLocation(selectedLocation);
                    onClose();
                  }}
                  className=""
                >
                  <CustomButton
                    w="w-24 sm:w-36"
                    colorScheme="blue"
                    loading={false}
                    text="Continue"
                  />
                </div>
              )}
            </div>
          </ModalFooter>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default LocationModal;
